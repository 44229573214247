var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact--profile" },
    [
      _c(
        "div",
        { staticClass: "contact--info" },
        [
          _vm.showAvatar
            ? _c("thumbnail", {
                attrs: {
                  src: _vm.contact.thumbnail,
                  size: "56px",
                  username: _vm.contact.name,
                  status: _vm.contact.availability_status
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contact--details" },
            [
              _vm.showAvatar
                ? _c("div", { staticClass: "contact--name-wrap" }, [
                    _c("h3", { staticClass: "sub-block-title contact--name" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.contact.name) + "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fs-default",
                        attrs: {
                          href: _vm.contactProfileLink,
                          target: "_blank",
                          rel: "noopener nofollow noreferrer"
                        }
                      },
                      [
                        _c("woot-button", {
                          attrs: {
                            size: "tiny",
                            icon: "open",
                            variant: "clear",
                            "color-scheme": "secondary"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.additionalAttributes.description
                ? _c("p", { staticClass: "contact--bio" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.additionalAttributes.description) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("social-icons", {
                attrs: { "social-profiles": _vm.socialProfiles }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "contact--metadata" },
                [
                  _c("contact-info-row", {
                    attrs: {
                      href: _vm.contact.email
                        ? "mailto:" + _vm.contact.email
                        : "",
                      value: _vm.contact.email,
                      icon: "mail",
                      emoji: "✉️",
                      title: _vm.$t("CONTACT_PANEL.EMAIL_ADDRESS"),
                      "show-copy": ""
                    }
                  }),
                  _vm._v(" "),
                  _c("contact-info-row", {
                    attrs: {
                      href: _vm.contact.phone_number
                        ? "tel:" + _vm.contact.phone_number
                        : "",
                      value: _vm.contact.phone_number,
                      icon: "call",
                      emoji: "📞",
                      title: _vm.$t("CONTACT_PANEL.PHONE_NUMBER")
                    }
                  }),
                  _vm._v(" "),
                  _c("contact-info-row", {
                    attrs: {
                      value: _vm.additionalAttributes.company_name,
                      icon: "building-bank",
                      emoji: "🏢",
                      title: _vm.$t("CONTACT_PANEL.COMPANY")
                    }
                  }),
                  _vm._v(" "),
                  _vm.location || _vm.additionalAttributes.location
                    ? _c("contact-info-row", {
                        attrs: {
                          value:
                            _vm.location || _vm.additionalAttributes.location,
                          icon: "map",
                          emoji: "🌍",
                          title: _vm.$t("CONTACT_PANEL.LOCATION")
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("formulate-input", {
                    attrs: {
                      options: _vm.status,
                      "show-value": "true",
                      type: "select",
                      name: "status",
                      placeholder: _vm.$t(
                        "CONTACT_PANEL.LABELS.STATUS.PLACEHOLDER"
                      ),
                      validation: "required",
                      "validation-name": "Status"
                    },
                    on: {
                      change: function($event) {
                        return _vm.setContactStatus($event)
                      }
                    },
                    model: {
                      value: _vm.status_id,
                      callback: function($$v) {
                        _vm.status_id = $$v
                      },
                      expression: "status_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contact-actions" },
            [
              _vm.showNewMessage
                ? _c("woot-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("CONTACT_PANEL.NEW_MESSAGE"),
                        expression: "$t('CONTACT_PANEL.NEW_MESSAGE')"
                      }
                    ],
                    staticClass: "new-message",
                    attrs: {
                      title: "$t('CONTACT_PANEL.NEW_MESSAGE')",
                      icon: "chat",
                      size: "small"
                    },
                    on: { click: _vm.toggleConversationModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("woot-button", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t("EDIT_CONTACT.BUTTON_LABEL"),
                    expression: "$t('EDIT_CONTACT.BUTTON_LABEL')"
                  }
                ],
                staticClass: "edit-contact",
                attrs: {
                  title: "$t('EDIT_CONTACT.BUTTON_LABEL')",
                  icon: "edit",
                  variant: "smooth",
                  size: "small"
                },
                on: { click: _vm.toggleEditModal }
              }),
              _vm._v(" "),
              _vm.isAdmin
                ? _c("woot-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("CONTACT_PANEL.MERGE_CONTACT"),
                        expression: "$t('CONTACT_PANEL.MERGE_CONTACT')"
                      }
                    ],
                    staticClass: "merge-contact",
                    attrs: {
                      title: "$t('CONTACT_PANEL.MERGE_CONTACT')",
                      icon: "merge",
                      variant: "smooth",
                      size: "small",
                      "color-scheme": "secondary",
                      disabled: _vm.uiFlags.isMerging
                    },
                    on: { click: _vm.openMergeModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c("woot-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("DELETE_CONTACT.BUTTON_LABEL"),
                        expression: "$t('DELETE_CONTACT.BUTTON_LABEL')"
                      }
                    ],
                    staticClass: "delete-contact",
                    attrs: {
                      title: "$t('DELETE_CONTACT.BUTTON_LABEL')",
                      icon: "delete",
                      variant: "smooth",
                      size: "small",
                      "color-scheme": "alert",
                      disabled: _vm.uiFlags.isDeleting
                    },
                    on: { click: _vm.toggleDeleteModal }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.showEditModal
            ? _c("edit-contact", {
                attrs: { show: _vm.showEditModal, contact: _vm.contact },
                on: { cancel: _vm.toggleEditModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.contact.id
            ? _c("new-conversation", {
                attrs: {
                  show: _vm.showConversationModal,
                  contact: _vm.contact
                },
                on: { cancel: _vm.toggleConversationModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showMergeModal
            ? _c("contact-merge-modal", {
                attrs: {
                  "primary-contact": _vm.contact,
                  show: _vm.showMergeModal
                },
                on: { close: _vm.toggleMergeModal }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDeleteModal
        ? _c("woot-confirm-delete-modal", {
            attrs: {
              show: _vm.showDeleteModal,
              title: _vm.$t("DELETE_CONTACT.CONFIRM.TITLE"),
              message: _vm.confirmDeleteMessage,
              "confirm-text": _vm.deleteConfirmText,
              "reject-text": _vm.deleteRejectText,
              "confirm-value": _vm.contact.name,
              "confirm-place-holder-text": _vm.confirmPlaceHolderText
            },
            on: {
              "update:show": function($event) {
                _vm.showDeleteModal = $event
              },
              "on-confirm": _vm.confirmDeletion,
              "on-close": _vm.closeDelete
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }