var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.TITLE")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.saveAttchment($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-input", {
                class: { error: _vm.$v.name.$error },
                attrs: {
                  label: _vm.$t(
                    "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.NAME.LABEL"
                  ),
                  type: "text",
                  error: _vm.$v.name.$error
                    ? _vm.$t(
                        "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.NAME.ERROR"
                      )
                    : "",
                  placeholder: _vm.$t(
                    "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.NAME.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.name.$touch },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns display-inline" }, [
                _c("label", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.FILE.LABEL"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "file",
                    attrs: { id: "file", type: "file", accept: "image/*" },
                    on: { change: _vm.handleImageUpload }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.previewImage,
                      expression: "previewImage"
                    }
                  ]
                },
                [
                  _c("img", {
                    attrs: { src: _vm.previewImage, alt: "image-preview" }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "woot-button",
                    { attrs: { disabled: _vm.isButtonDisabled } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isAddingAttchment
                            ? _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.SAVING")
                            : _vm.$t(
                                "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.SAVE_BUTTON"
                              )
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: { variant: "clear" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onClose($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.CANCEL_BUTTON"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }