import { render, staticRenderFns } from "./OrderUsages.vue?vue&type=template&id=72a05b6b&scoped=true&"
import script from "./OrderUsages.vue?vue&type=script&lang=js&"
export * from "./OrderUsages.vue?vue&type=script&lang=js&"
import style0 from "./OrderUsages.vue?vue&type=style&index=0&id=72a05b6b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a05b6b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72a05b6b')) {
      api.createRecord('72a05b6b', component.options)
    } else {
      api.reload('72a05b6b', component.options)
    }
    module.hot.accept("./OrderUsages.vue?vue&type=template&id=72a05b6b&scoped=true&", function () {
      api.rerender('72a05b6b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/reports/billing_useages/OrderUsages.vue"
export default component.exports