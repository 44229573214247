var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context-menu" },
    [
      _c("woot-button", {
        staticClass: "button--delete-message",
        attrs: {
          icon: "more-vertical",
          "color-scheme": "secondary",
          variant: "link"
        },
        on: { click: _vm.handleContextMenuClick }
      }),
      _vm._v(" "),
      _vm.isOpen
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.handleContextMenuClick,
                  expression: "handleContextMenuClick"
                }
              ],
              staticClass: "dropdown-pane dropdown-pane--open",
              class: "dropdown-pane--" + _vm.menuPosition
            },
            [
              _c(
                "woot-dropdown-menu",
                [
                  _vm.showCopy
                    ? _c(
                        "woot-dropdown-item",
                        [
                          _c(
                            "woot-button",
                            {
                              attrs: {
                                variant: "clear",
                                size: "small",
                                icon: "clipboard"
                              },
                              on: { click: _vm.handleCopy }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("CONVERSATION.CONTEXT_MENU.COPY")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "woot-dropdown-item",
                    [
                      _c(
                        "woot-button",
                        {
                          attrs: {
                            variant: "clear",
                            "color-scheme": "alert",
                            size: "small",
                            icon: "delete"
                          },
                          on: { click: _vm.handleDelete }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("CONVERSATION.CONTEXT_MENU.DELETE")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }