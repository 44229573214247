var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "account-selector--modal",
      attrs: {
        show: _vm.showAccountModal,
        "on-close": function() {
          return _vm.$emit("close-account-modal")
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("SIDEBAR_ITEMS.CHANGE_ACCOUNTS"),
          "header-content": _vm.$t("SIDEBAR_ITEMS.SELECTOR_SUBTITLE")
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.currentUser.accounts, function(account) {
        return _c("div", { key: account.id, staticClass: "account-selector" }, [
          _c(
            "a",
            { attrs: { href: "/app/accounts/" + account.id + "/dashboard" } },
            [
              account.id === _vm.accountId
                ? _c("fluent-icon", {
                    staticClass: "selected--account",
                    attrs: {
                      icon: "checkmark-circle",
                      type: "solid",
                      size: "24"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "account--details",
                  attrs: { for: account.name }
                },
                [
                  _c("div", { staticClass: "account--name" }, [
                    _vm._v(_vm._s(account.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "account--role" }, [
                    _vm._v(_vm._s(account.role))
                  ])
                ]
              )
            ],
            1
          )
        ])
      }),
      _vm._v(" "),
      _vm.globalConfig.createNewAccountFromDashboard
        ? _c("div", { staticClass: "modal-footer delete-item" }, [
            _c(
              "button",
              {
                staticClass: "button success large expanded nice",
                on: {
                  click: function($event) {
                    return _vm.$emit("show-create-account-modal")
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("CREATE_ACCOUNT.NEW_ACCOUNT")) +
                    "\n    "
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }