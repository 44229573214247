var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box fill-height" },
    [
      !_vm.currentChat.can_reply && !_vm.isAWhatsappChannel
        ? _c("banner", {
            attrs: {
              "color-scheme": "alert",
              "banner-message": _vm.$t("CONVERSATION.CANNOT_REPLY"),
              "href-link": _vm.facebookReplyPolicy,
              "href-link-text": _vm.$t("CONVERSATION.24_HOURS_WINDOW")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentChat.can_reply && _vm.isAWhatsappChannel
        ? _c("banner", {
            attrs: {
              "color-scheme": "alert",
              "banner-message": _vm.$t(
                "CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY"
              ),
              "href-link": _vm.twilioWhatsAppReplyPolicy,
              "href-link-text": _vm.$t(
                "CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW"
              )
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isATweet
        ? _c("banner", {
            attrs: {
              "color-scheme": "gray",
              "banner-message": _vm.tweetBannerText,
              "has-close-button": _vm.hasSelectedTweetId
            },
            on: { close: _vm.removeTweetSelection }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar-toggle__wrap" },
        [
          _c("woot-button", {
            staticClass: "sidebar-toggle--button",
            attrs: {
              variant: "smooth",
              size: "small",
              "color-scheme": "secondary",
              icon: _vm.isRightOrLeftIcon
            },
            on: { click: _vm.onToggleContactPanel }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "conversation-panel" },
        [
          _c("transition", { attrs: { name: "slide-up" } }, [
            _c("li", { staticClass: "spinner--container" }, [
              _vm.shouldShowSpinner
                ? _c("span", { staticClass: "spinner message" })
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.getReadMessages, function(message) {
            return _c("message", {
              key: message.id,
              staticClass: "message--read",
              attrs: { data: message, "is-a-tweet": _vm.isATweet }
            })
          }),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getUnreadCount != 0,
                  expression: "getUnreadCount != 0"
                }
              ],
              staticClass: "unread--toast"
            },
            [
              _c("span", { staticClass: "text-uppercase" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.getUnreadCount) +
                    "\n        " +
                    _vm._s(
                      _vm.getUnreadCount > 1
                        ? _vm.$t("CONVERSATION.UNREAD_MESSAGES")
                        : _vm.$t("CONVERSATION.UNREAD_MESSAGE")
                    ) +
                    "\n      "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.getUnReadMessages, function(message) {
            return _c("message", {
              key: message.id,
              staticClass: "message--unread",
              attrs: { data: message, "is-a-tweet": _vm.isATweet }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "conversation-footer",
          class: { "modal-mask": _vm.isPopoutReplyBox }
        },
        [
          _vm.isAnyoneTyping
            ? _c("div", { staticClass: "typing-indicator-wrap" }, [
                _c("div", { staticClass: "typing-indicator" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.typingUserNames) + "\n        "
                  ),
                  _c("img", {
                    staticClass: "gif",
                    attrs: {
                      src: require("dashboard/assets/images/typing.gif"),
                      alt: "Someone is typing"
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("reply-box", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.closePopoutReplyBox,
                expression: "closePopoutReplyBox"
              }
            ],
            attrs: {
              "conversation-id": _vm.currentChat.id,
              "is-a-tweet": _vm.isATweet,
              "selected-tweet": _vm.selectedTweet,
              "popout-reply-box": _vm.isPopoutReplyBox
            },
            on: {
              "update:popoutReplyBox": function($event) {
                _vm.isPopoutReplyBox = $event
              },
              "update:popout-reply-box": function($event) {
                _vm.isPopoutReplyBox = $event
              },
              click: _vm.showPopoutReplyBox,
              scrollToMessage: _vm.scrollToBottom
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }