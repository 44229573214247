var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "orderItems_table_wrap" },
    [
      _c("ve-table", {
        attrs: {
          "fixed-header": true,
          "max-height": "100%",
          columns: _vm.columns,
          "table-data": _vm.tableData,
          "border-around": false,
          "sort-option": _vm.sortOption
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "loader_and_empty" },
                [
                  _c("spinner"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s("Order is loading"))])
                ],
                1
              )
            : !_vm.isLoading && !_vm.order_items.length
            ? _c("empty-state", { attrs: { title: "Empty order items" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }