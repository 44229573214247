var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-box", class: _vm.wrapClass },
    [
      _c(
        "div",
        { staticClass: "left-wrap" },
        [
          _c("woot-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-end",
                value: _vm.$t("CONVERSATION.REPLYBOX.TIP_EMOJI_ICON"),
                expression: "$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')",
                modifiers: { "top-end": true }
              }
            ],
            attrs: {
              title: _vm.$t("CONVERSATION.REPLYBOX.TIP_EMOJI_ICON"),
              icon: "emoji",
              emoji: "😊",
              "color-scheme": "secondary",
              variant: "smooth",
              size: "small"
            },
            on: { click: _vm.toggleEmojiPicker }
          }),
          _vm._v(" "),
          _c(
            "file-upload",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top-end",
                  value: _vm.$t("CONVERSATION.REPLYBOX.TIP_ATTACH_ICON"),
                  expression: "$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')",
                  modifiers: { "top-end": true }
                }
              ],
              ref: "upload",
              attrs: {
                size: 4096 * 4096,
                accept: _vm.allowedFileTypes,
                multiple: _vm.enableMultipleFileUpload,
                drop: true,
                "drop-directory": false,
                data: {
                  direct_upload_url: "/rails/active_storage/direct_uploads",
                  direct_upload: true
                }
              },
              on: { "input-file": _vm.onFileUpload }
            },
            [
              _vm.showAttachButton
                ? _c("woot-button", {
                    attrs: {
                      "class-names": "button--upload",
                      title: _vm.$t("CONVERSATION.REPLYBOX.TIP_ATTACH_ICON"),
                      icon: "attach",
                      emoji: "📎",
                      "color-scheme": "secondary",
                      variant: "smooth",
                      size: "small"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAudioRecorderButton
            ? _c("woot-button", {
                attrs: {
                  icon: !_vm.isRecordingAudio ? "microphone" : "microphone-off",
                  emoji: "🎤",
                  "color-scheme": !_vm.isRecordingAudio ? "secondary" : "alert",
                  variant: "smooth",
                  size: "small",
                  title: "Record Audio"
                },
                on: { click: _vm.toggleAudioRecorder }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.enableRichEditor && !_vm.isOnPrivateNote
            ? _c("woot-button", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-end",
                    value: _vm.$t("CONVERSATION.REPLYBOX.TIP_FORMAT_ICON"),
                    expression: "$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')",
                    modifiers: { "top-end": true }
                  }
                ],
                attrs: {
                  icon: "quote",
                  emoji: "🖊️",
                  "color-scheme": "secondary",
                  variant: "smooth",
                  size: "small",
                  title: _vm.$t("CONVERSATION.REPLYBOX.TIP_FORMAT_ICON")
                },
                on: { click: _vm.toggleFormatMode }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showAudioPlayStopButton
            ? _c("woot-button", {
                attrs: {
                  icon: _vm.audioRecorderPlayStopIcon,
                  emoji: "🎤",
                  "color-scheme": "secondary",
                  variant: "smooth",
                  size: "small"
                },
                on: { click: _vm.toggleAudioRecorderPlayPause }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showMessageSignatureButton
            ? _c("woot-button", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-end",
                    value: _vm.signatureToggleTooltip,
                    expression: "signatureToggleTooltip",
                    modifiers: { "top-end": true }
                  }
                ],
                attrs: {
                  icon: "signature",
                  "color-scheme": "secondary",
                  variant: "smooth",
                  size: "small",
                  title: _vm.signatureToggleTooltip
                },
                on: { click: _vm.toggleMessageSignature }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "modal-fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$refs.upload && _vm.$refs.upload.dropActive,
                    expression: "$refs.upload && $refs.upload.dropActive"
                  }
                ],
                staticClass: "modal-mask"
              },
              [
                _c("fluent-icon", { attrs: { icon: "cloud-backup" } }),
                _vm._v(" "),
                _c("h4", { staticClass: "page-sub-title" }, [
                  _vm._v(_vm._s(_vm.$t("CONVERSATION.REPLYBOX.DRAG_DROP")))
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("woot-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-end",
                value: "Show Catalogue",
                expression: "'Show Catalogue'",
                modifiers: { "top-end": true }
              }
            ],
            attrs: {
              icon: "cart",
              emoji: "🛒",
              "color-scheme": !_vm.isRecordingAudio ? "secondary" : "alert",
              variant: "smooth",
              size: "small",
              title: "Show Catalogue"
            },
            on: { click: _vm.openCatalogueModal }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-wrap" },
        [
          _vm.isFormatMode
            ? _c("div", { staticClass: "enter-to-send--checkbox" }, [
                _c("input", {
                  attrs: { type: "checkbox", value: "enterToSend" },
                  domProps: { checked: _vm.enterToSendEnabled },
                  on: { input: _vm.toggleEnterToSend }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "enterToSend" } }, [
                  _vm._v(_vm._s(_vm.$t("CONVERSATION.REPLYBOX.ENTER_TO_SEND")))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.topUpEmpty
            ? _c(
                "p",
                {
                  staticClass:
                    "warning-text text-center text-muted end-of-list-text"
                },
                [_vm._v(_vm._s(_vm.$t("CONVERSATION.REPLYBOX.TOP_UP_EMPTY")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.balanceEmpty
            ? _c(
                "p",
                {
                  staticClass:
                    "warning-text text-center text-muted end-of-list-text"
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("CONVERSATION.REPLYBOX.MESSAGE_LIMIT_EMPTY"))
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              attrs: {
                size: "small",
                "class-names": _vm.buttonClass,
                "is-disabled": _vm.isSendDisabled
              },
              on: { click: _vm.onSend }
            },
            [_vm._v(_vm._s(_vm.sendButtonText))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCatalogueList
        ? _c("all-catalogues", {
            attrs: { inbox: _vm.inbox },
            on: { close: _vm.closeCatalogueModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }