var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column" },
    [
      this.registrationFee
        ? _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("TOP_UP.MODAL.UPDATETITLE"),
              "header-content": _vm.$t("TOP_UP.MODAL.UPDATECONTENT")
            }
          })
        : _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("TOP_UP.MODAL.TITLE"),
              "header-content": _vm.$t("TOP_UP.MODAL.CONTENT")
            }
          }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row modal-content" },
        [
          this.modalDataChange
            ? _c("top-up-amount-container", {
                attrs: {
                  "modal-value": this.modalValue,
                  "usd-value": this.usdAmount,
                  "registration-fee": this.registrationFee,
                  "user-balance": _vm.userBalance,
                  "product-id": this.productId
                },
                on: { "item-click": _vm.submitModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("stripe-card-component", {
            attrs: {
              "modal-data-change": this.modalDataChange,
              "payment-intent-id": this.paymentIntentId,
              elements: this.elements,
              "client-secret": this.clientSecretId,
              "base-amount": this.baseAmount,
              "total-amount": this.totalAmount,
              "percentage-amount": this.percentageAmount,
              currency: this.currency
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }