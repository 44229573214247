var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column" },
    [
      _c(
        "woot-modal-header",
        { attrs: { "header-title": _vm.$t("COLUMN_SELECTOR.TITLE") } },
        [_c("p", [_vm._v(_vm._s(_vm.$t("COLUMN_SELECTOR.SUBTITLE")))])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row modal-content" }, [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          _vm._l(_vm.displayColumns, function(column, i) {
            return _c("div", { key: i }, [
              _c("label", { staticClass: "custom-checkbox-container" }, [
                _vm._v("\n          " + _vm._s(column.name) + " "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: column.visible,
                      expression: "column.visible"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(column.visible)
                      ? _vm._i(column.visible, null) > -1
                      : column.visible
                  },
                  on: {
                    change: function($event) {
                      var $$a = column.visible,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(column, "visible", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              column,
                              "visible",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(column, "visible", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" })
              ])
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }