var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "medium-12 columns" }, [
    _c(
      "form",
      {
        attrs: { id: "payment-form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleSubmit($event)
          }
        }
      },
      [
        !this.modalDataChange
          ? _c("div", [
              _c("div", { staticClass: "flex-container" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "flex-child text-right" }, [
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.currencyUpper()) +
                        "\n            " +
                        _vm._s(this.fetchCalculated(this.baseAmount)) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-container" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "flex-child text-right" }, [
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.currencyUpper()) +
                        "\n            " +
                        _vm._s(this.fetchCalculated(this.percentageAmount)) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-container" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "flex-child text-right" }, [
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.currencyUpper()) +
                        "\n            " +
                        _vm._s(this.fetchCalculated(this.totalAmount)) +
                        "\n          "
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { attrs: { id: "stripe-payment" } }),
        _vm._v(" "),
        !this.modalDataChange
          ? _c("button", { attrs: { id: "submit" } }, [
              _c(
                "div",
                { staticClass: "pay-btn" },
                [
                  _c(
                    "woot-button",
                    {
                      attrs: { "color-scheme": "primary", size: "small" },
                      on: { click: _vm.submit }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("TOP_UP.MODAL.FORM.PAY")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "hidden", attrs: { id: "payment-message" } })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child" }, [
      _c("label", [_vm._v("Base Amount")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child" }, [
      _c("label", [_vm._v("Tax Amount")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child" }, [
      _c("label", [_vm._v("Total Amount")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }