var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.cancel },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", { attrs: { "header-title": _vm.title } }),
          _vm._v(" "),
          _c("div", { staticClass: "row modal-content" }, [
            _c("div", { staticClass: "medium-12 columns" }, [
              _c("p", [
                _vm._v("\n          " + _vm._s(_vm.description) + "\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c("woot-button", { on: { click: _vm.confirm } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.confirmLabel) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "button clear", on: { click: _vm.cancel } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cancelLabel) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }