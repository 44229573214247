var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grant-list-container row" }, [
    _c(
      "div",
      { staticClass: "medium-12" },
      [
        _c("grant-header", {
          attrs: {
            "header-title": "Grant List",
            "search-query": _vm.searchQuery,
            "on-input-search": _vm.onInputSearch,
            "on-search-submit": _vm.onSearchSubmit
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("grant-table", {
              attrs: {
                grants: _vm.records,
                "show-search-empty-state": _vm.showEmptySearchResult,
                "is-loading": _vm.uiFlags.isFetching,
                sortConfig: _vm.sortConfig,
                cols: _vm.displayColumns
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }