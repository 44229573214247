import { render, staticRenderFns } from "./GrantTable.vue?vue&type=template&id=61e4af71&scoped=true&"
import script from "./GrantTable.vue?vue&type=script&lang=js&"
export * from "./GrantTable.vue?vue&type=script&lang=js&"
import style0 from "./GrantTable.vue?vue&type=style&index=0&id=61e4af71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e4af71",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61e4af71')) {
      api.createRecord('61e4af71', component.options)
    } else {
      api.reload('61e4af71', component.options)
    }
    module.hot.accept("./GrantTable.vue?vue&type=template&id=61e4af71&scoped=true&", function () {
      api.rerender('61e4af71', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/credit_balance/GrantList/GrantTable.vue"
export default component.exports