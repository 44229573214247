var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-item__wrap" },
    [
      _vm._l(_vm.attachments, function(attachment, index) {
        return [
          attachment.resource.type !== "audio/ogg"
            ? _c("div", { key: index, staticClass: "preview-item" }, [
                _c("div", { staticClass: "thumb-wrap" }, [
                  _vm.isTypeImage(attachment.resource)
                    ? _c("img", {
                        staticClass: "image-thumb",
                        attrs: { src: attachment.thumb }
                      })
                    : _c("span", { staticClass: "attachment-thumb" }, [
                        _vm._v(" 📄 ")
                      ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "file-name-wrap" }, [
                  _c("span", { staticClass: "item" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.fileName(attachment.resource)) +
                        "\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "file-size-wrap" }, [
                  _c("span", { staticClass: "item text-truncate" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.formatFileSize(attachment.resource)) +
                        "\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "remove-file-wrap" },
                  [
                    !_vm.isTypeAudio(attachment.resource)
                      ? _c("woot-button", {
                          staticClass: "remove--attachment clear secondary",
                          attrs: { icon: "dismiss" },
                          on: {
                            click: function() {
                              return _vm.onRemoveAttachment(index)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }