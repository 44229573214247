var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown-search-wrap" }, [
    _c("h4", { staticClass: "text-block-title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_SELECT.TITLE")) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "search-wrap" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        ref: "searchbar",
        staticClass: "search-input",
        attrs: {
          type: "text",
          autofocus: "true",
          placeholder: _vm.$t(
            "CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_SELECT.PLACEHOLDER"
          )
        },
        domProps: { value: _vm.search },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list-wrap" }, [
      _c(
        "div",
        { staticClass: "list" },
        [
          _c(
            "woot-dropdown-menu",
            _vm._l(_vm.filteredAttributes, function(attribute) {
              return _c("custom-attribute-drop-down-item", {
                key: attribute.attribute_display_name,
                attrs: { title: attribute.attribute_display_name },
                on: {
                  click: function($event) {
                    return _vm.onAddAttribute(attribute)
                  }
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.noResult
            ? _c("div", { staticClass: "no-result" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_SELECT.NO_RESULT"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "add",
              attrs: { icon: "add", size: "small" },
              on: { click: _vm.addNewAttribute }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CUSTOM_ATTRIBUTES.FORM.ADD.TITLE")) +
                  "\n      "
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }