var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings--content" }, [
    _c("div", { staticClass: "prechat--title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("INBOX_MGMT.PRE_CHAT_FORM.DESCRIPTION")) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "medium-6",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.updateInbox($event)
          }
        }
      },
      [
        _c("label", { staticClass: "medium-9 columns" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.PRE_CHAT_FORM.ENABLE.LABEL")) +
              "\n      "
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.preChatFormEnabled,
                  expression: "preChatFormEnabled"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.preChatFormEnabled = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { domProps: { value: true } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.ENABLED")
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: false } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED")
                    ) +
                    "\n        "
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "medium-9" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.LABEL")
              ) +
              "\n      "
          ),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.preChatMessage,
                expression: "preChatMessage",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.preChatMessage },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.preChatMessage = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.preChatFieldOptions,
                expression: "preChatFieldOptions"
              }
            ],
            attrs: { type: "checkbox", value: "requireEmail" },
            domProps: {
              checked: Array.isArray(_vm.preChatFieldOptions)
                ? _vm._i(_vm.preChatFieldOptions, "requireEmail") > -1
                : _vm.preChatFieldOptions
            },
            on: {
              input: _vm.handlePreChatFieldOptions,
              change: function($event) {
                var $$a = _vm.preChatFieldOptions,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "requireEmail",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.preChatFieldOptions = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.preChatFieldOptions = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.preChatFieldOptions = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "requireEmail" } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("INBOX_MGMT.PRE_CHAT_FORM.REQUIRE_EMAIL.LABEL")) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("woot-submit-button", {
          attrs: {
            "button-text": _vm.$t("INBOX_MGMT.SETTINGS_POPUP.UPDATE"),
            loading: _vm.uiFlags.isUpdatingInbox
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }