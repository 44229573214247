var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("report-filter-selector", {
        on: { "date-range-change": _vm.onDateRangeChange }
      }),
      _vm._v(" "),
      _c("csat-metrics"),
      _vm._v(" "),
      _c("csat-table", {
        attrs: { "page-index": _vm.pageIndex },
        on: { "page-change": _vm.onPageNumberChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }