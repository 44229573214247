var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("TEMPLATE_MSG.ADD.TITLE"),
              "header-content": _vm.$t("TEMPLATE_MSG.ADD.DESC")
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.addTemplateMessage()
                }
              }
            },
            [
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.templateName.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.LABEL")
                      ) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.templateName,
                        expression: "templateName",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.templateName },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.templateName = $event.target.value.trim()
                        },
                        _vm.$v.templateName.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns display-inline" }, [
                _c(
                  "div",
                  { staticClass: "medium-4 columns" },
                  [
                    _c("formulate-input", {
                      attrs: {
                        options: this.inboxList(),
                        type: "select",
                        name: "inbox",
                        placeholder: _vm.$t(
                          "TEMPLATE_MSG.ADD.FORM.INBOX.LABEL"
                        ),
                        label: _vm.$t(
                          "TEMPLATE_MSG.ADD.FORM.INBOX.PLACEHOLDER"
                        ),
                        validation: "required",
                        "validation-name": "Inbox"
                      },
                      model: {
                        value: _vm.inbox,
                        callback: function($$v) {
                          _vm.inbox = $$v
                        },
                        expression: "inbox"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "medium-4 columns" },
                  [
                    _c("formulate-input", {
                      attrs: {
                        options: this.languages(),
                        type: "select",
                        name: "language",
                        placeholder: _vm.$t(
                          "TEMPLATE_MSG.ADD.FORM.LANGUAGE.LABEL"
                        ),
                        label: _vm.$t(
                          "TEMPLATE_MSG.ADD.FORM.LANGUAGE.PLACEHOLDER"
                        ),
                        validation: "required",
                        "validation-name": "Language"
                      },
                      model: {
                        value: _vm.language,
                        callback: function($$v) {
                          _vm.language = $$v
                        },
                        expression: "language"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "medium-4 columns" },
                  [
                    _c("formulate-input", {
                      attrs: {
                        options: this.templateTypes(),
                        type: "select",
                        name: "template-type",
                        placeholder: _vm.$t("TEMPLATE_MSG.ADD.FORM.TYPE.LABEL"),
                        label: _vm.$t("TEMPLATE_MSG.ADD.FORM.TYPE.PLACEHOLDER"),
                        validation: "required",
                        "validation-name": "Template Type"
                      },
                      model: {
                        value: _vm.templateType,
                        callback: function($$v) {
                          _vm.templateType = $$v
                        },
                        expression: "templateType"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.content.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("TEMPLATE_MSG.ADD.FORM.CONTENT.LABEL")) +
                      "\n          "
                  ),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.content,
                        expression: "content",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      rows: "5",
                      type: "text",
                      placeholder: _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.CONTENT.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.content },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.content = $event.target.value.trim()
                        },
                        _vm.$v.content.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns display-inline" }, [
                _c("label", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("TEMPLATE_MSG.ADD.FORM.IMAGE.LABEL")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "file",
                    attrs: {
                      id: "file",
                      type: "file",
                      accept: "image/*, .mp4, .pdf"
                    },
                    on: { change: _vm.handleImageUpload }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c(
                  "label",
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("multiselect", {
                      staticClass: "small-12",
                      attrs: {
                        "tag-placeholder": _vm.$t(
                          "TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL"
                        ),
                        placeholder: _vm.$t(
                          "TEMPLATE_MSG.ADD.FORM.BUTTON.PLACEHOLDER"
                        ),
                        label: "name",
                        max: 3,
                        "track-by": "code",
                        options: _vm.options,
                        multiple: true,
                        taggable: true
                      },
                      on: { tag: _vm.addButton },
                      model: {
                        value: _vm.buttonsData,
                        callback: function($$v) {
                          _vm.buttonsData = $$v
                        },
                        expression: "buttonsData"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        disabled: _vm.showLoading,
                        "button-text": _vm.$t("TEMPLATE_MSG.ADD.FORM.SUBMIT"),
                        loading: _vm.showLoading
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }