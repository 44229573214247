var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "wizard-box flex-child-shrink",
      class: _vm.classObject,
      attrs: { name: "wizard-items", tag: "div" }
    },
    _vm._l(_vm.items, function(item) {
      return _c(
        "div",
        {
          key: item.route,
          staticClass: "item",
          class: { active: _vm.isActive(item), over: _vm.isOver(item) }
        },
        [
          _c("h3", [
            _vm._v("\n      " + _vm._s(item.title) + "\n      "),
            _vm.isOver(item)
              ? _c(
                  "span",
                  { staticClass: "completed" },
                  [_c("fluent-icon", { attrs: { icon: "checkmark" } })],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "step" }, [
            _vm._v("\n      " + _vm._s(_vm.items.indexOf(item) + 1) + "\n    ")
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(item.body))])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }