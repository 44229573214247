var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.isOpen,
        "on-close": _vm.onCancel,
        "modal-type": "right-aligned"
      },
      on: {
        "update:show": function($event) {
          _vm.isOpen = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": "Create New Grant",
              "header-content":
                "Add basic information details about the new grant."
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "contact--form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "columns" }, [
                  _c("label", { class: { error: _vm.$v.email.$error } }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL")
                        ) +
                        "\n                        "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.email,
                          expression: "email",
                          modifiers: { trim: true }
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: "Enter the email address of the grant"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value.trim()
                          },
                          _vm.$v.email.$touch
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    { class: { error: _vm.$v.balance_credit.$error } },
                    [
                      _vm._v(
                        "\n                        Balance Credit amount\n                        "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.balance_credit,
                            expression: "balance_credit",
                            modifiers: { trim: true }
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "Enter the credit amount"
                        },
                        domProps: { value: _vm.balance_credit },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.balance_credit = $event.target.value.trim()
                            },
                            _vm.$v.balance_credit.$touch
                          ],
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        loading: _vm.inProgress,
                        "button-text": _vm.$t("CONTACT_FORM.FORM.SUBMIT")
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onCancel($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("CONTACT_FORM.FORM.CANCEL")) +
                            "\n                  "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }