var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top",
            icon: "add-circle"
          },
          on: { click: _vm.openAddPopup }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("LABEL_MGMT.HEADER_BTN_TXT")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "small-8 columns with-right-space " },
          [
            !_vm.uiFlags.isFetching && !_vm.records.length
              ? _c("p", { staticClass: "no-items-error-message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("LABEL_MGMT.LIST.404")) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.uiFlags.isFetching
              ? _c("woot-loading-state", {
                  attrs: { message: _vm.$t("LABEL_MGMT.LOADING") }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.uiFlags.isFetching && _vm.records.length
              ? _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(_vm.$t("LABEL_MGMT.LIST.TABLE_HEADER"), function(
                      thHeader
                    ) {
                      return _c("th", { key: thHeader }, [
                        _vm._v(
                          "\n            " + _vm._s(thHeader) + "\n          "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function(label, index) {
                      return _c("tr", { key: label.title }, [
                        _c("td", [_vm._v(_vm._s(label.title))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(label.description))]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "label-color--container" }, [
                            _c("span", {
                              staticClass: "label-color--display",
                              style: { backgroundColor: label.color }
                            }),
                            _vm._v(
                              "\n                " +
                                _vm._s(label.color) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "button-wrapper btn-width-auto" },
                          [
                            _c("woot-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t("LABEL_MGMT.FORM.EDIT"),
                                  expression: "$t('LABEL_MGMT.FORM.EDIT')",
                                  modifiers: { top: true }
                                }
                              ],
                              attrs: {
                                variant: "smooth",
                                size: "small",
                                "color-scheme": "secondary",
                                "class-names": "grey-btn",
                                "is-loading": _vm.loading[label.id],
                                icon: "edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openEditPopup(label)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("woot-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t("LABEL_MGMT.FORM.DELETE"),
                                  expression: "$t('LABEL_MGMT.FORM.DELETE')",
                                  modifiers: { top: true }
                                }
                              ],
                              attrs: {
                                variant: "smooth",
                                "color-scheme": "alert",
                                size: "small",
                                icon: "dismiss-circle",
                                "class-names": "grey-btn",
                                "is-loading": _vm.loading[label.id]
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openDeletePopup(label, index)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns" }, [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$t("LABEL_MGMT.SIDEBAR_TXT")) }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [_c("add-label", { on: { close: _vm.hideAddPopup } })],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _c("edit-label", {
            attrs: { "selected-response": _vm.selectedResponse },
            on: { close: _vm.hideEditPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": _vm.deleteConfirmText,
          "reject-text": _vm.deleteRejectText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }