var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", { attrs: { "header-title": _vm.pageTitle } }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.editCampaign($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("label", { class: { error: _vm.$v.selectedInbox.$error } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "end"
                    },
                    style: _vm.isEmailMessage ? "margin-bottom: 10px" : ""
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.INBOX.LABEL")) +
                        "\n          "
                    ),
                    _vm.isEmailMessage
                      ? _c(
                          "woot-button",
                          {
                            attrs: { type: "button" },
                            on: { click: _vm.openEmailTemplateListModal }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "CAMPAIGN.ADD.SELECT_TEMPLATE_BUTTON_TEXT"
                                )
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedInbox,
                        expression: "selectedInbox"
                      }
                    ],
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedInbox = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChangeInbox($event)
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.inboxes, function(item) {
                    return _c(
                      "option",
                      { key: item.name, domProps: { value: item } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.$v.selectedInbox.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.FORM.INBOX.ERROR")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("woot-input", {
                class: { error: _vm.$v.title.$error },
                attrs: {
                  label: _vm.$t("CAMPAIGN.ADD.FORM.TITLE.LABEL"),
                  type: "text",
                  error: _vm.$v.title.$error
                    ? _vm.$t("CAMPAIGN.ADD.FORM.TITLE.ERROR")
                    : "",
                  placeholder: _vm.$t("CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER")
                },
                on: { blur: _vm.$v.title.$touch },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "editor-wrap" },
                [
                  _c(
                    "div",
                    {
                      style: _vm.isEmailMessage
                        ? "display: flex; align-items: end; justify-content: space-between; margin-bottom: 10px"
                        : ""
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.LABEL")) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isEmailMessage
                    ? _c("woot-message-editor", {
                        staticClass: "message-editor",
                        class: { editor_warning: _vm.$v.message.$error },
                        attrs: {
                          "is-format-mode": true,
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER"
                          )
                        },
                        on: { input: _vm.$v.message.$touch },
                        model: {
                          value: _vm.message,
                          callback: function($$v) {
                            _vm.message =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "message"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.templateData
                    ? _c("div", {
                        staticStyle: { height: "300px", overflow: "scroll" },
                        domProps: { innerHTML: _vm._s(_vm.templateData) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$v.message.$error
                    ? _c("span", { staticClass: "editor-warning__message" }, [
                        _vm._v(
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.ERROR"))
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("label", { class: { error: _vm.$v.selectedSender.$error } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.SENT_BY.LABEL")) +
                    "\n        "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedSender,
                        expression: "selectedSender"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedSender = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.sendersAndBotList, function(sender) {
                    return _c(
                      "option",
                      { key: sender.name, domProps: { value: sender.id } },
                      [_vm._v(_vm._s(sender.name))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.$v.selectedSender.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.FORM.SENT_BY.ERROR")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("woot-input", {
                class: { error: _vm.$v.endPoint.$error },
                attrs: {
                  label: _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.LABEL"),
                  type: "text",
                  error: _vm.$v.endPoint.$error
                    ? _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.ERROR")
                    : "",
                  placeholder: _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER")
                },
                on: { blur: _vm.$v.endPoint.$touch },
                model: {
                  value: _vm.endPoint,
                  callback: function($$v) {
                    _vm.endPoint = $$v
                  },
                  expression: "endPoint"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                class: { error: _vm.$v.timeOnPage.$error },
                attrs: {
                  label: _vm.$t("CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL"),
                  type: "text",
                  error: _vm.$v.timeOnPage.$error
                    ? _vm.$t("CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR")
                    : "",
                  placeholder: _vm.$t(
                    "CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.$v.timeOnPage.$touch },
                model: {
                  value: _vm.timeOnPage,
                  callback: function($$v) {
                    _vm.timeOnPage = $$v
                  },
                  expression: "timeOnPage"
                }
              }),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.enabled,
                      expression: "enabled"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    value: "enabled",
                    name: "enabled"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.enabled)
                      ? _vm._i(_vm.enabled, "enabled") > -1
                      : _vm.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "enabled",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enabled = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.ENABLED")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.triggerOnlyDuringBusinessHours,
                          expression: "triggerOnlyDuringBusinessHours"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        value: "triggerOnlyDuringBusinessHours",
                        name: "triggerOnlyDuringBusinessHours"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.triggerOnlyDuringBusinessHours
                        )
                          ? _vm._i(
                              _vm.triggerOnlyDuringBusinessHours,
                              "triggerOnlyDuringBusinessHours"
                            ) > -1
                          : _vm.triggerOnlyDuringBusinessHours
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.triggerOnlyDuringBusinessHours,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "triggerOnlyDuringBusinessHours",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.triggerOnlyDuringBusinessHours = $$a.concat(
                                  [$$v]
                                ))
                            } else {
                              $$i > -1 &&
                                (_vm.triggerOnlyDuringBusinessHours = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.triggerOnlyDuringBusinessHours = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS"
                          )
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "woot-button",
                { attrs: { "is-loading": _vm.uiFlags.isCreating } },
                [_vm._v(_vm._s(_vm.$t("CAMPAIGN.EDIT.UPDATE_BUTTON_TEXT")))]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.CANCEL_BUTTON_TEXT")))]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.isOpenEmailTemplateListModal
        ? _c("email-template-list", {
            on: {
              close: _vm.closeEmailTemplateListModal,
              selectTemplate: _vm.selectTemplate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }