var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-text--metadata" },
    [
      _c("span", { staticClass: "time" }, [_vm._v(_vm._s(_vm.readableTime))]),
      _vm._v(" "),
      _vm.showSentIndicator
        ? _c(
            "span",
            { staticClass: "time" },
            [
              _c("fluent-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-start",
                    value: _vm.$t("CHAT_LIST.SENT"),
                    expression: "$t('CHAT_LIST.SENT')",
                    modifiers: { "top-start": true }
                  }
                ],
                attrs: { icon: "checkmark", size: "16" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isEmail
        ? _c("fluent-icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-start",
                value: _vm.$t("CHAT_LIST.RECEIVED_VIA_EMAIL"),
                expression: "$t('CHAT_LIST.RECEIVED_VIA_EMAIL')",
                modifiers: { "top-start": true }
              }
            ],
            staticClass: "action--icon",
            attrs: { icon: "mail", size: "16" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPrivate
        ? _c("fluent-icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-start",
                value: _vm.$t("CONVERSATION.VISIBLE_TO_AGENTS"),
                expression: "$t('CONVERSATION.VISIBLE_TO_AGENTS')",
                modifiers: { "top-start": true }
              }
            ],
            staticClass: "action--icon lock--icon--private",
            attrs: { icon: "lock-closed", size: "16" },
            on: {
              mouseenter: function($event) {
                _vm.isHovered = true
              },
              mouseleave: function($event) {
                _vm.isHovered = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isATweet && (_vm.isIncoming || _vm.isOutgoing) && _vm.sourceId
        ? _c(
            "button",
            { on: { click: _vm.onTweetReply } },
            [
              _c("fluent-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-start",
                    value: _vm.$t("CHAT_LIST.REPLY_TO_TWEET"),
                    expression: "$t('CHAT_LIST.REPLY_TO_TWEET')",
                    modifiers: { "top-start": true }
                  }
                ],
                staticClass: "action--icon cursor-pointer",
                attrs: { icon: "arrow-reply", size: "16" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isATweet && (_vm.isOutgoing || _vm.isIncoming) && _vm.linkToTweet
        ? _c(
            "a",
            {
              attrs: {
                href: _vm.linkToTweet,
                target: "_blank",
                rel: "noopener noreferrer nofollow"
              }
            },
            [
              _c("fluent-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-start",
                    value: _vm.$t("CHAT_LIST.VIEW_TWEET_IN_TWITTER"),
                    expression: "$t('CHAT_LIST.VIEW_TWEET_IN_TWITTER')",
                    modifiers: { "top-start": true }
                  }
                ],
                staticClass: "action--icon cursor-pointer",
                attrs: { icon: "open", size: "16" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }