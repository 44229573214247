var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversations-list-wrap" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "chat-list__top",
          class: { filter__applied: _vm.hasAppliedFiltersOrActiveFolders }
        },
        [
          _c(
            "h1",
            {
              staticClass: "page-title text-truncate",
              attrs: { title: _vm.pageTitle }
            },
            [_vm._v("\n      " + _vm._s(_vm.pageTitle) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter--actions" },
            [
              !_vm.hasAppliedFiltersOrActiveFolders
                ? _c("chat-filter", {
                    on: { statusFilterChange: _vm.updateStatusType }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAppliedFilters && !_vm.hasActiveFolders
                ? _c(
                    "div",
                    [
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t(
                              "FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON"
                            ),
                            expression:
                              "$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        attrs: {
                          size: "small",
                          variant: "smooth",
                          "color-scheme": "secondary",
                          icon: "save"
                        },
                        on: { click: _vm.onClickOpenAddFoldersModal }
                      }),
                      _vm._v(" "),
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t("FILTER.CLEAR_BUTTON_LABEL"),
                            expression: "$t('FILTER.CLEAR_BUTTON_LABEL')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        attrs: {
                          size: "small",
                          variant: "smooth",
                          "color-scheme": "alert",
                          icon: "dismiss-circle"
                        },
                        on: { click: _vm.resetAndFetchData }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasActiveFolders
                ? _c(
                    "div",
                    [
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t(
                              "FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON"
                            ),
                            expression:
                              "$t('FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        staticClass: "delete-custom-view__button",
                        attrs: {
                          size: "small",
                          variant: "smooth",
                          "color-scheme": "alert",
                          icon: "delete"
                        },
                        on: { click: _vm.onClickOpenDeleteFoldersModal }
                      })
                    ],
                    1
                  )
                : _c("woot-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-end",
                        value: _vm.$t("FILTER.TOOLTIP_LABEL"),
                        expression: "$t('FILTER.TOOLTIP_LABEL')",
                        modifiers: { "top-end": true }
                      }
                    ],
                    staticClass: "btn-filter",
                    attrs: {
                      variant: "clear",
                      "color-scheme": "secondary",
                      icon: "filter",
                      size: "small"
                    },
                    on: { click: _vm.onToggleAdvanceFiltersModal }
                  }),
              _vm._v(" "),
              _c("div", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.readStatus,
                        expression: "readStatus"
                      }
                    ],
                    staticClass: "status--filter",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.readStatus = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.readStatusTypes, function(readStatusEl) {
                    return _c(
                      "option",
                      {
                        key: readStatusEl.value,
                        domProps: { value: readStatusEl.value }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "CHAT_LIST.READ_STATUS_FILTERS." +
                                  readStatusEl.label
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showAddFoldersModal
        ? _c("add-custom-views", {
            attrs: {
              "custom-views-query": _vm.foldersQuery,
              "open-last-saved-item": _vm.openLastSavedItemInFolder
            },
            on: { close: _vm.onCloseAddFoldersModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteFoldersModal
        ? _c("delete-custom-views", {
            attrs: {
              "show-delete-popup": _vm.showDeleteFoldersModal,
              "active-custom-view": _vm.activeFolder,
              "custom-views-id": _vm.foldersId,
              "open-last-item-after-delete": _vm.openLastItemAfterDeleteInFolder
            },
            on: {
              "update:showDeletePopup": function($event) {
                _vm.showDeleteFoldersModal = $event
              },
              "update:show-delete-popup": function($event) {
                _vm.showDeleteFoldersModal = $event
              },
              close: _vm.onCloseDeleteFoldersModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAppliedFiltersOrActiveFolders
        ? _c("chat-type-tabs", {
            staticClass: "tab--chat-type",
            attrs: {
              items: _vm.assigneeTabItems,
              "active-tab": _vm.activeAssigneeTab
            },
            on: { chatTabChange: _vm.updateAssigneeTab }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.chatListLoading && !_vm.conversationList.length && !_vm.tabChange
        ? _c("p", { staticClass: "content-box" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("CHAT_LIST.LIST.404")) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { ref: "activeConversation", staticClass: "conversations-list" },
        [
          _vm._l(_vm.conversationList, function(chat) {
            return _c("conversation-card", {
              key: chat.id,
              attrs: {
                "active-label": _vm.label,
                "team-id": _vm.teamId,
                "folders-id": _vm.foldersId,
                chat: chat,
                "conversation-type": _vm.conversationType,
                "show-assignee": _vm.showAssigneeInConversationCard
              }
            })
          }),
          _vm._v(" "),
          _vm.chatListLoading || _vm.tabChange
            ? _c("div", { staticClass: "text-center" }, [
                _c("span", { staticClass: "spinner" })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasCurrentPageEndReached && !_vm.chatListLoading
            ? _c(
                "woot-button",
                {
                  attrs: { variant: "clear", size: "expanded" },
                  on: { click: _vm.loadMoreConversations }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CHAT_LIST.LOAD_MORE_CONVERSATIONS")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.conversationList.length &&
          _vm.hasCurrentPageEndReached &&
          !_vm.chatListLoading
            ? _c(
                "p",
                { staticClass: "text-center text-muted end-of-list-text" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("CHAT_LIST.EOF")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAdvancedFilters,
            "on-close": _vm.onToggleAdvanceFiltersModal,
            size: "medium"
          },
          on: {
            "update:show": function($event) {
              _vm.showAdvancedFilters = $event
            }
          }
        },
        [
          _vm.showAdvancedFilters
            ? _c("conversation-advanced-filter", {
                attrs: {
                  "initial-filter-types": _vm.advancedFilterTypes,
                  "on-close": _vm.onToggleAdvanceFiltersModal
                },
                on: { applyFilter: _vm.onApplyFilter }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }