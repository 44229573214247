var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { size: "medium", show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "woot-modal-header",
        {
          staticClass: "modal-header",
          attrs: { "header-title": _vm.$t("CAMPAIGN.CATALOGUE_LIST.HEADER") }
        },
        [
          _c(
            "woot-button",
            {
              attrs: { disabled: _vm.selectedProducts.length === 0 },
              on: { click: _vm.sendItems }
            },
            [
              _vm._v(
                _vm._s(_vm.$t("CAMPAIGN.CATALOGUE_LIST.ATTCH_BUTTON_TEXT"))
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row header-section" }, [
        _c("div", { staticClass: "columns medium-6 small-12" }, [
          _c(
            "div",
            { staticClass: "search-wrap" },
            [
              _c("fluent-icon", {
                staticClass: "search-icon",
                attrs: { icon: "search" }
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "contact-search",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "CAMPAIGN.EMAIL_TEMPLATE.SEARCH_INPUT_PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.searchQuery },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSearchSubmit($event, "title")
                  },
                  input: _vm.onInputSearch
                }
              }),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  staticClass: "clear",
                  attrs: {
                    "is-loading": false,
                    "class-names": this.searchQuery !== "" ? "show" : ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onSearchSubmit($event, "title")
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("CAMPAIGN.EMAIL_TEMPLATE.SEARCH_BUTTON_TEXT"))
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns medium-4 small-12" }, [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("CAMPAIGN.CATALOGUE_LIST.CATEGORY_SELECT_LABEL"))
            )
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.category,
                  expression: "category"
                }
              ],
              staticClass: "category--filter",
              attrs: { placeholder: "Select category" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.category = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.onSearchSubmit($event, "category")
                  }
                ]
              }
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("All")]),
              _vm._v(" "),
              _vm._l(_vm.categories, function(value, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: value } },
                  [_vm._v(_vm._s(value))]
                )
              })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "invisible-checkboxes" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("CAMPAIGN.CATALOGUE_LIST.SUB_HEADER")))]),
        _vm._v(" "),
        _vm.catalogItems.length > 0
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.catalogItems, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "column medium-4 catalogue-detail"
                  },
                  [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        name: "selectCatalogue",
                        id: "catalogue-item-" + item.retailer_id
                      },
                      domProps: { value: item.retailer_id },
                      on: { change: _vm.changeSelectBox }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "checkbox-alias",
                        attrs: { for: "catalogue-item-" + item.retailer_id }
                      },
                      [
                        _c("img", {
                          staticClass: "image",
                          attrs: { src: item.image_link, alt: item.title }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(item.description))])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }