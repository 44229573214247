var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body columns content-box small-9" },
    [
      _c(
        "empty-state",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.FINISH.TITLE"),
            message: _vm.message,
            "button-text": _vm.$t("INBOX_MGMT.FINISH.BUTTON_TEXT")
          }
        },
        [
          _c("div", { staticClass: "medium-12 columns text-center" }, [
            _c(
              "div",
              { staticClass: "website--code" },
              [
                _vm.currentInbox.web_widget_script
                  ? _c("woot-code", {
                      attrs: { script: _vm.currentInbox.web_widget_script }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "medium-6 small-offset-3" },
              [
                _vm.isATwilioInbox
                  ? _c("woot-code", {
                      attrs: {
                        lang: "html",
                        script: _vm.currentInbox.callback_webhook_url
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "medium-6 small-offset-3" },
              [
                _vm.isALineInbox
                  ? _c("woot-code", {
                      attrs: {
                        lang: "html",
                        script: _vm.currentInbox.callback_webhook_url
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "medium-6 small-offset-3" },
              [
                _vm.isASmsInbox
                  ? _c("woot-code", {
                      attrs: {
                        lang: "html",
                        script: _vm.currentInbox.callback_webhook_url
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "medium-6 small-offset-3" },
              [
                _vm.isAEmailInbox
                  ? _c("woot-code", {
                      attrs: {
                        lang: "html",
                        script: _vm.currentInbox.forward_to_email
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button hollow primary settings-button",
                    attrs: {
                      to: {
                        name: "settings_inbox_show",
                        params: { inboxId: this.$route.params.inbox_id }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("INBOX_MGMT.FINISH.MORE_SETTINGS")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "button success",
                    attrs: {
                      to: {
                        name: "inbox_dashboard",
                        params: { inboxId: this.$route.params.inbox_id }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("INBOX_MGMT.FINISH.BUTTON_TEXT")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }