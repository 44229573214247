var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box onboard_container" },
    [
      _c("section", { staticClass: "row" }, [
        _c("div", { staticClass: "column content-box" }, [
          _c("div", { staticClass: "banner_heading" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("ONBOARDING_HELP.TITLE")))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("ONBOARDING_HELP.BODY_TEXT_PEMANGKIN")))
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ]),
      _vm._v(" "),
      _c("ContestEventPopup")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "100%", overflow: "hidden" } }, [
      _c("div", { staticStyle: { width: "130%" } }, [
        _c("div", { staticClass: "pemangkin_doc" }, [
          _c("iframe", {
            attrs: {
              src: "https://peasy.ai/docs/pemangkinstart/?lang=ms",
              frameborder: "0"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }