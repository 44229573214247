var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          padding: "8px",
          "padding-left": "24px",
          "padding-right": "36px",
          color: "grey",
          "align-items": "center"
        }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.node.data.customName,
              expression: "node.data.customName"
            }
          ],
          staticClass: "inputTitle",
          staticStyle: {
            margin: "0",
            "margin-right": "5px",
            "text-transform": "uppercase"
          },
          attrs: { type: "text", placeholder: _vm.node.title },
          domProps: { value: _vm.node.data.customName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.node.data, "customName", $event.target.value)
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.QUESTION_TITLE")) +
                    "\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.questionTitle,
                      expression: "questionTitle"
                    }
                  ],
                  staticClass: "w3-input",
                  class: {
                    error:
                      _vm.$v.questionTitle.$error ||
                      !_vm.$v.questionTitle.maxLength
                  },
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("FLOW.PLACEHOLDERS.TITLE")
                  },
                  domProps: { value: _vm.questionTitle },
                  on: {
                    change: _vm.onQuestionTitleChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.questionTitle = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.$v.questionTitle.$error
                ? _c("span", { staticClass: "error-msg" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.$v.questionTitle.maxLength
                          ? _vm.$t("FLOW.QUESTION_TITLE_MAXLENGTH_ERROR")
                          : _vm.$t("FLOW.QUESTION_TITLE_ERROR")
                      )
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.QUESTION_SUBTITLE")) +
                    "\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.questionSubTitle,
                      expression: "questionSubTitle"
                    }
                  ],
                  staticClass: "w3-input",
                  class: { error: _vm.$v.questionSubTitle.$error },
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("FLOW.PLACEHOLDERS.SUBTITLE")
                  },
                  domProps: { value: _vm.questionSubTitle },
                  on: {
                    change: _vm.onQuestionSubTitleChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.questionSubTitle = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.$v.questionSubTitle.$error
                ? _c("span", { staticClass: "error-msg" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.QUESTION_SUBTITLE_ERROR")))
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "0em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v("\n          Type\n          "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.node.data.subNodes[
                            "condition-" + _vm.commonPostfix
                          ].data.contents[1].attrType,
                        expression:
                          "node.data.subNodes[`condition-${commonPostfix}`].data.contents[1].attrType"
                      }
                    ],
                    staticClass: "filter__question",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.node.data.subNodes[
                            "condition-" + _vm.commonPostfix
                          ].data.contents[1],
                          "attrType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.attrTypes, function(item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "0em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v("\n          Attribute Name\n          "),
                _vm.node.data.subNodes["condition-" + _vm.commonPostfix].data
                  .contents[1].attrType.id == "standard"
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.node.data.subNodes[
                                "condition-" + _vm.commonPostfix
                              ].data.contents[1].attr,
                            expression:
                              "node.data.subNodes[`condition-${commonPostfix}`].data.contents[1].attr"
                          }
                        ],
                        staticClass: "filter__question",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.node.data.subNodes[
                                "condition-" + _vm.commonPostfix
                              ].data.contents[1],
                              "attr",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.contactAttributes, function(item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.node.data.subNodes["condition-" + _vm.commonPostfix].data
                  .contents[1].attrType.id == "custom"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customAttr,
                          expression: "customAttr"
                        }
                      ],
                      staticClass: "answer--text-input",
                      class: {
                        error:
                          _vm.$v.customAttr.$error ||
                          !_vm.$v.customAttr.maxLength
                      },
                      staticStyle: { margin: "0", "margin-right": "5px" },
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "FLOW.PLACEHOLDERS.CUSTOM_ATTRIBUTE"
                        )
                      },
                      domProps: { value: _vm.customAttr },
                      on: {
                        change: _vm.onCustomAttrChange,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.customAttr = $event.target.value
                        }
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.$v.customAttr.$error
                ? _c("span", { staticClass: "error-msg" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.$v.customAttr.maxLength
                          ? _vm.$t("FLOW.CUSTOM_AATR_MAXLENGTH_ERROR")
                          : _vm.$t("FLOW.CUSTOM_AATR_ERROR")
                      )
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "0em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.ACTION_BUTTONS")) +
                    "\n          "
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#f6f6f6",
                      "min-height": "100px",
                      "border-radius": "10px",
                      "margin-bottom": "12px",
                      padding: "12px"
                    }
                  },
                  [
                    _vm._l(_vm.actionButtons, function(
                      singleActionButton,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "align-content": "center"
                            }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: singleActionButton.buttonTitle,
                                  expression: "singleActionButton.buttonTitle"
                                }
                              ],
                              class: {
                                error:
                                  _vm.$v.actionButtons.$each[index].buttonTitle
                                    .$error ||
                                  !_vm.$v.actionButtons.$each[index].buttonTitle
                                    .maxLength ||
                                  _vm.isTitleUnique
                              },
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t(
                                  "FLOW.PLACEHOLDERS.BUTTON_TITLE"
                                )
                              },
                              domProps: {
                                value: singleActionButton.buttonTitle
                              },
                              on: {
                                blur: _vm.hasDuplicateTitles,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    singleActionButton,
                                    "buttonTitle",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: singleActionButton.selectedAction,
                                    expression:
                                      "singleActionButton.selectedAction"
                                  }
                                ],
                                staticClass: "operator__select",
                                staticStyle: {
                                  "margin-left": "5px",
                                  "margin-right": "5px"
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      singleActionButton,
                                      "selectedAction",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.actionButtonOptions, function(item) {
                                return _c(
                                  "option",
                                  { key: item.id, domProps: { value: item } },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: singleActionButton.buttonValue,
                                  expression: "singleActionButton.buttonValue"
                                }
                              ],
                              class: {
                                error:
                                  _vm.$v.actionButtons.$each[index].buttonValue
                                    .$error ||
                                  !_vm.$v.actionButtons.$each[index].buttonValue
                                    .maxLength ||
                                  _vm.isValueUnique
                              },
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("FLOW.PLACEHOLDERS.VALUE")
                              },
                              domProps: {
                                value: singleActionButton.buttonValue
                              },
                              on: {
                                blur: _vm.hasDuplicateValues,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    singleActionButton,
                                    "buttonValue",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.deleteItem(index)
                                  }
                                }
                              },
                              [
                                _c("FluentIcon", { attrs: { icon: "dismiss" } })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$v.actionButtons.$each[index].buttonTitle.$error ||
                        _vm.$v.actionButtons.$each[index].buttonValue.$error
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-bottom": "1.6em",
                                  display: "flex",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _vm.$v.actionButtons.$each[index].buttonTitle
                                  .$error
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "error-msg",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "start"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$v.actionButtons.$each[index]
                                              .buttonTitle.maxLength
                                              ? _vm.$t(
                                                  "FLOW.BUTTON_TITLE_MAXLENGTH"
                                                )
                                              : _vm.$t(
                                                  "FLOW.BUTTON_TITLE_ERROR"
                                                )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$v.actionButtons.$each[index].buttonValue
                                  .$error
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "error-msg",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "end"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$v.actionButtons.$each[index]
                                              .buttonValue.maxLength
                                              ? _vm.$t(
                                                  "FLOW.BUTTON_TITLE_MAXLENGTH"
                                                )
                                              : _vm.$t(
                                                  "FLOW.BUTTON_TITLE_ERROR"
                                                )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ])
                    }),
                    _vm._v(" "),
                    _vm.isTitleUnique
                      ? _c("span", { staticClass: "error-msg" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("FLOW.DUPLICATE_ACTION_BUTTON_TITLE_ERROR")
                            )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isValueUnique
                      ? _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "end"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "FLOW.DUPLICATE_ACTION_BUTTON_VALUE_ERROR"
                                )
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "5px", "margin-bottom": "10px" } },
            [
              _c("div", { staticClass: "custom-btn-wrapper" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.actionButtons.length < 3,
                        expression: "this.actionButtons.length < 3"
                      }
                    ],
                    staticClass: "btn",
                    on: { click: _vm.addNewActionButton }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("dashboard/assets/images/flow-new/btn-add.png")
                      }
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("FLOW.ACTION_BUTTON")) +
                        "\n          "
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("label", { staticClass: "custom-checkbox-container" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("FLOW.END_FLOW")) + "\n        "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.endFlowAction,
                  expression: "endFlowAction"
                }
              ],
              attrs: { id: "endFlow", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.endFlowAction)
                  ? _vm._i(_vm.endFlowAction, null) > -1
                  : _vm.endFlowAction
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.endFlowAction,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.endFlowAction = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.endFlowAction = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.endFlowAction = $$c
                    }
                  },
                  _vm.onUpdateEndFlow
                ]
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark" })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "woot-button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.checkValidation($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("FLOW.DONE")))]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }