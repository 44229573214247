var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAttachments ||
    _vm.data.content ||
    _vm.isEmailContentType ||
    _vm.data.content_attributes
    ? _c(
        "li",
        { class: _vm.alignBubble },
        [
          _c(
            "div",
            { class: _vm.wrapClass },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top-start",
                      value: _vm.messageToolTip,
                      expression: "messageToolTip",
                      modifiers: { "top-start": true }
                    }
                  ],
                  class: _vm.bubbleClass
                },
                [
                  _c("bubble-mail-head", {
                    attrs: {
                      "email-attributes": _vm.contentAttributes.email,
                      cc: _vm.emailHeadAttributes.cc,
                      bcc: _vm.emailHeadAttributes.bcc,
                      "is-incoming": _vm.isIncoming
                    }
                  }),
                  _vm._v(" "),
                  typeof _vm.catalogueItems === "object"
                    ? _c(
                        "div",
                        { staticClass: "odr-msg row" },
                        [
                          _vm.isOutgoing
                            ? _c("div", [
                                _vm.productItems &&
                                _vm.productItems.product_items &&
                                _vm.productItems.product_items.length !== 0
                                  ? _c("h6", { staticClass: "white-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.productItems.product_items.length
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.productItems.product_items
                                              .length === 1
                                              ? _vm.$t("CONVERSATION.ITEM")
                                              : _vm.$t("CONVERSATION.ITEMS")
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "odr-content row" }, [
                                  _vm.productDetails.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card img-card column small-6"
                                        },
                                        [
                                          _c("bubble-image", {
                                            staticClass: "food-img",
                                            attrs: {
                                              url:
                                                _vm.productDetails[0].image_link
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.productItems &&
                                  _vm.productItems.product_items &&
                                  _vm.productItems.product_items.length !== 0
                                    ? _c(
                                        "div",
                                        { staticClass: "odr-detail small-6" },
                                        [
                                          _c(
                                            "h6",
                                            { staticClass: "white-text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.productItems
                                                    .product_items[0]
                                                    .product_retail_id
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isIncoming
                            ? _c("div", [
                                _vm.productItems
                                  ? _c("h6", [
                                      _vm._v(
                                        _vm._s(_vm.productItems.length) +
                                          " " +
                                          _vm._s(
                                            _vm.productItems.length === 1
                                              ? _vm.$t("CONVERSATION.ITEM")
                                              : _vm.$t("CONVERSATION.ITEMS")
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("CONVERSATION.MYR")) +
                                      " " +
                                      _vm._s(_vm.calculateTotalItems) +
                                      " (" +
                                      _vm._s(_vm.$t("CONVERSATION.ESTIMATED")) +
                                      ")"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "odr-content row" }, [
                                  _vm.productDetails.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card img-card column small-6"
                                        },
                                        [
                                          _c("bubble-image", {
                                            staticClass: "food-img",
                                            attrs: {
                                              url:
                                                _vm.productDetails[0].image_link
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "odr-detail small-6" },
                                    [
                                      _c("h6", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productItems[0]
                                              .product_retailer_id
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "woot-button",
                            {
                              style: !_vm.isIncoming ? "color: white;" : "",
                              attrs: {
                                variant: "clear",
                                "color-scheme": _vm.isIncoming ? "success" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openCartOrItemListModal()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("CONVERSATION.VIEW_ITEMS")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.content
                    ? _c("bubble-text", {
                        attrs: {
                          message: _vm.message,
                          "is-email": _vm.isEmailContentType,
                          "readable-time": _vm.readableTime,
                          "display-quoted-button": _vm.displayQuotedButton
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPending && _vm.hasAttachments
                    ? _c(
                        "span",
                        { staticClass: "chat-bubble has-attachment agent" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("CONVERSATION.UPLOADING_ATTACHMENTS"))
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPending && _vm.hasAttachments
                    ? _c(
                        "div",
                        _vm._l(_vm.data.attachments, function(attachment) {
                          return _c(
                            "div",
                            { key: attachment.id },
                            [
                              attachment.file_type === "image" &&
                              !_vm.hasImageError
                                ? _c("bubble-image", {
                                    attrs: {
                                      url: attachment.data_url,
                                      "readable-time": _vm.readableTime
                                    },
                                    on: { error: _vm.onImageLoadError }
                                  })
                                : attachment.file_type === "audio"
                                ? _c("audio", { attrs: { controls: "" } }, [
                                    _c("source", {
                                      attrs: { src: attachment.data_url }
                                    })
                                  ])
                                : attachment.file_type === "video"
                                ? _c("bubble-video", {
                                    attrs: {
                                      url: attachment.data_url,
                                      "readable-time": _vm.readableTime
                                    }
                                  })
                                : _c("bubble-file", {
                                    attrs: {
                                      url: attachment.data_url,
                                      "readable-time": _vm.readableTime
                                    }
                                  })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("bubble-actions", {
                    attrs: {
                      id: _vm.data.id,
                      sender: _vm.data.sender,
                      "is-a-tweet": _vm.isATweet,
                      "is-email": _vm.isEmailContentType,
                      "is-private": _vm.data.private,
                      "message-type": _vm.data.message_type,
                      "readable-time": _vm.readableTime,
                      "source-id": _vm.data.source_id,
                      "inbox-id": _vm.data.inbox_id
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.isPending
                ? _c("spinner", { attrs: { size: "tiny" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.showAvatar
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.tooltipForSender,
                          expression: "tooltipForSender",
                          modifiers: { top: true }
                        }
                      ],
                      staticClass: "sender--info"
                    },
                    [
                      _c("woot-thumbnail", {
                        attrs: {
                          src: _vm.sender.thumbnail,
                          username: _vm.senderNameForAvatar,
                          size: "16px"
                        }
                      }),
                      _vm._v(" "),
                      _vm.isATweet && _vm.isIncoming
                        ? _c(
                            "a",
                            {
                              staticClass: "sender--available-name",
                              attrs: {
                                href: _vm.twitterProfileLink,
                                target: "_blank",
                                rel: "noopener noreferrer nofollow"
                              }
                            },
                            [_vm._v(_vm._s(_vm.sender.name))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFailed
                ? _c(
                    "div",
                    { staticClass: "message-failed--alert" },
                    [
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t("CONVERSATION.TRY_AGAIN"),
                            expression: "$t('CONVERSATION.TRY_AGAIN')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        attrs: {
                          size: "small",
                          "color-scheme": "alert",
                          variant: "clear",
                          icon: "arrow-clockwise"
                        },
                        on: { click: _vm.retrySendMessage }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.shouldShowContextMenu
            ? _c(
                "div",
                { staticClass: "context-menu-wrap" },
                [
                  _vm.isBubble && !_vm.isMessageDeleted
                    ? _c("context-menu", {
                        attrs: {
                          "is-open": _vm.showContextMenu,
                          "show-copy": _vm.hasText,
                          "menu-position": _vm.contextMenuPosition
                        },
                        on: {
                          toggle: _vm.handleContextMenuClick,
                          delete: _vm.handleDelete,
                          copy: _vm.handleCopy
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowingFoodItemList
            ? _c("item-list", {
                attrs: { data: _vm.data },
                on: { close: _vm.closeCartOrItemListModal }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowingCartItems
            ? _c("cart-items", {
                attrs: {
                  contentAttributes: _vm.contentAttributes,
                  productItems: _vm.productItems,
                  calculateTotalItems: _vm.calculateTotalItems,
                  "inbox-id": _vm.data.inbox_id,
                  "is-in-coming": _vm.isIncoming,
                  "readable-time": _vm.readableTime,
                  "product-details": _vm.productDetails
                },
                on: { close: _vm.closeCartOrItemListModal }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }