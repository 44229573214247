var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [
        _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e()
      ]),
      _vm._v(" "),
      _vm.src
        ? _c("woot-thumbnail", { attrs: { size: "80px", src: _vm.src } })
        : _vm._e(),
      _vm._v(" "),
      _vm.src && _vm.deleteAvatar
        ? _c(
            "div",
            { staticClass: "avatar-delete-btn" },
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    "color-scheme": "alert",
                    variant: "hollow",
                    size: "small"
                  },
                  on: { click: _vm.onAvatarDelete }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        this.$t("INBOX_MGMT.DELETE.AVATAR_DELETE_BUTTON_TEXT")
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        [
          _c("input", {
            ref: "file",
            attrs: {
              id: "file",
              type: "file",
              accept: "image/png, image/jpeg, image/gif"
            },
            on: { change: _vm.handleImageUpload }
          }),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }