var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    attrs: { to: _vm.to, custom: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var href = ref.href
          var isActive = ref.isActive
          var navigate = ref.navigate
          return [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.right",
                    value: _vm.$t("" + _vm.name),
                    expression: "$t(`${name}`)",
                    modifiers: { right: true }
                  }
                ],
                staticClass: "button clear button--only-icon menu-item",
                class: { "is-active": isActive || _vm.isChildMenuActive },
                attrs: {
                  href: _vm.getHref(_vm.to, href),
                  target: _vm.getTarget(_vm.to)
                },
                on: {
                  click: function() {
                    _vm.to.includes("pm-external")
                      ? _vm.linkClickHandler()
                      : navigate()
                  }
                }
              },
              [
                _vm.icon.isIcon
                  ? _c("fluent-icon", { attrs: { icon: _vm.icon.name } })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.icon.isIcon
                  ? _c("img", {
                      attrs: {
                        src: require("../../../assets/images/menu-icons/" +
                          _vm.icon.name),
                        alt: _vm.name + "-menu-icon"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "show-for-sr" }, [
                  _vm._v(_vm._s(_vm.name))
                ]),
                _vm._v(" "),
                _vm.count
                  ? _c("span", { staticClass: "badge warning" }, [
                      _vm._v(_vm._s(_vm.count))
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }