var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-labels-wrap" },
    [
      !_vm.conversationUiFlags.isFetching
        ? _c("div", { staticClass: "contact-conversation--list" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.closeDropdownLabel,
                    expression: "closeDropdownLabel"
                  }
                ],
                staticClass: "label-wrap",
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.closeDropdownLabel($event)
                  }
                }
              },
              [
                _c("add-label", { on: { add: _vm.toggleLabels } }),
                _vm._v(" "),
                _vm._l(_vm.activeLabels, function(label) {
                  return _c("woot-label", {
                    key: label.id,
                    attrs: {
                      title: label.title,
                      description: label.description,
                      "show-close": true,
                      "bg-color": label.color
                    },
                    on: { click: _vm.removeLabelFromConversation }
                  })
                }),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-pane",
                      class: {
                        "dropdown-pane--open": _vm.showSearchDropdownLabel
                      }
                    },
                    [
                      _vm.showSearchDropdownLabel
                        ? _c("label-dropdown", {
                            attrs: {
                              "account-labels": _vm.accountLabels,
                              "selected-labels": _vm.savedLabels
                            },
                            on: {
                              add: _vm.addLabelToConversation,
                              remove: _vm.removeLabelFromConversation
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              2
            )
          ])
        : _c("spinner")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }