var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "diagram-editor__wrapper" },
    [
      _c("DiagramRoot", {
        ref: "diagram",
        attrs: {
          width: _vm.width,
          height: _vm.height,
          "grid-snap": _vm.gridSnap,
          "zoom-enabled": _vm.zoomEnabled,
          "node-color": _vm.nodeColor,
          "node-pulsable": _vm.nodePulsable,
          "node-deletable": _vm.nodeDeletable,
          "node-pulse-color": _vm.nodePulseColor,
          "before-delete-node": _vm.beforeDeleteNode,
          "before-delete-link": _vm.beforeDeleteLink,
          "port-disabled": _vm.portDisabled,
          "port-available": _vm.portAvailable,
          pan: _vm.pan,
          "prevent-mouse-events-default": _vm.preventMouseEventsDefault,
          botid: _vm.botid
        },
        on: {
          "select-node": function($event) {
            return _vm.$emit("select-node", $event)
          },
          "deleted-node": function($event) {
            return _vm.$emit("deleted-node", $event)
          },
          "deleted-link": function($event) {
            return _vm.$emit("deleted-link", $event)
          },
          "updated-node": function($event) {
            return _vm.$emit("updated-node", $event)
          },
          "click-port": function($event) {
            return _vm.$emit("click-port", $event)
          },
          "created-link": function($event) {
            return _vm.$emit("created-link", $event)
          },
          "clicked-node": function($event) {
            return _vm.$emit("clicked-node", $event)
          },
          "add-btn-clicked": function($event) {
            return _vm.$emit("add-btn-clicked", $event)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(scopedParams) {
                return [_vm._t("node", null, null, scopedParams)]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }