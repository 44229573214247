var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "contacts-table-wrap" },
    [
      _c("ve-table", {
        attrs: {
          "fixed-header": true,
          "max-height": "calc(100vh - 11.4rem)",
          "scroll-width": "187rem",
          columns: _vm.columns,
          "table-data": _vm.tableData,
          "border-around": false
        }
      }),
      _vm._v(" "),
      !_vm.isLoading && !_vm.usagesOrderReport
        ? _c("empty-state", {
            attrs: { title: "There are no available records" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "contacts--loader" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("FLOW.LOADING")))])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }