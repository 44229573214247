var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "credit-page row" }, [
    _c(
      "div",
      { staticClass: "medium-12" },
      [
        _c("balance-header", {
          attrs: {
            "header-title": "Credit Balance",
            "search-query": _vm.searchQuery,
            "on-input-search": _vm.onInputSearch,
            "on-search-submit": _vm.onSearchSubmit,
            "on-toggle-column-selector": _vm.onToggleColumnSelector,
            onToggleCreate: _vm.toggleCreate
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("credits-table", {
              attrs: {
                contacts: _vm.records,
                "show-search-empty-state": _vm.showEmptySearchResult,
                "is-loading": _vm.uiFlags.isFetching,
                "on-click-contact": _vm.openContactInfoPanel,
                "active-contact-id": _vm.selectedContactId,
                "sort-config": _vm.sortConfig,
                cols: _vm.displayColumns,
                selectedRowIds: _vm.selectedRowIds
              }
            }),
            _vm._v(" "),
            _c("table-footer", {
              attrs: {
                "on-page-change": _vm.onPageChange,
                "current-page": Number(_vm.meta.currentPage),
                "total-count": _vm.meta.count
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("create-grant-credit", {
          attrs: {
            isOpen: _vm.isCreateModalOpen,
            onSubmit: _vm.createNewCreditHandler
          },
          on: { cancel: _vm.toggleCreate }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }