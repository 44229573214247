var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns profile--settings" },
    [
      !_vm.uiFlags.isFetchingItem
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateAccount($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "small-12 row profile--settings--row" },
                [
                  _c("div", { staticClass: "columns small-3" }, [
                    _c("h4", { staticClass: "block-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE"
                            )
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns small-9 medium-5" }, [
                    _c("label", { class: { error: _vm.$v.name.$error } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("GENERAL_SETTINGS.FORM.NAME.LABEL")) +
                          "\n          "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER"
                          )
                        },
                        domProps: { value: _vm.name },
                        on: {
                          blur: _vm.$v.name.$touch,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.name.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("GENERAL_SETTINGS.FORM.NAME.ERROR")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("label", { class: { error: _vm.$v.locale.$error } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("GENERAL_SETTINGS.FORM.LANGUAGE.LABEL")
                          ) +
                          "\n          "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.locale,
                              expression: "locale"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.locale = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.languagesSortedByCode, function(lang) {
                          return _c(
                            "option",
                            {
                              key: lang.iso_639_1_code,
                              domProps: { value: lang.iso_639_1_code }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(lang.name) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.$v.locale.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("GENERAL_SETTINGS.FORM.LANGUAGE.ERROR")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.featureInboundEmailEnabled
                      ? _c("label", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.featureCustomDomainEmailEnabled
                      ? _c("label", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.featureCustomDomainEmailEnabled
                      ? _c("label", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("GENERAL_SETTINGS.FORM.DOMAIN.LABEL")
                              ) +
                              "\n          "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.domain,
                                expression: "domain"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER"
                              )
                            },
                            domProps: { value: _vm.domain },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.domain = $event.target.value
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.featureCustomDomainEmailEnabled
                      ? _c("label", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL"
                                )
                              ) +
                              "\n          "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.supportEmail,
                                expression: "supportEmail"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER"
                              )
                            },
                            domProps: { value: _vm.supportEmail },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.supportEmail = $event.target.value
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { class: { error: _vm.$v.autoResolveDuration.$error } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL"
                              )
                            ) +
                            "\n          "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.autoResolveDuration,
                              expression: "autoResolveDuration"
                            }
                          ],
                          attrs: {
                            type: "number",
                            placeholder: _vm.$t(
                              "GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER"
                            )
                          },
                          domProps: { value: _vm.autoResolveDuration },
                          on: {
                            blur: _vm.$v.autoResolveDuration.$touch,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.autoResolveDuration = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.autoResolveDuration.$error
                          ? _c("span", { staticClass: "message" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "profile--settings--row row" }, [
                _c("div", { staticClass: "columns small-3" }, [
                  _c("h4", { staticClass: "block-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE")
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns small-9 medium-5" },
                  [_c("woot-code", { attrs: { script: _vm.getAccountId } })],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "current-version" }, [
                _c("div", [_vm._v(_vm._s("v" + _vm.globalConfig.appVersion))]),
                _vm._v(" "),
                _vm.hasAnUpdateAvailable && _vm.globalConfig.displayManifest
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("GENERAL_SETTINGS.UPDATE_CHATWOOT", {
                              latestChatwootVersion: _vm.latestChatwootVersion
                            })
                          ) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("woot-submit-button", {
                staticClass: "button nice success button--fixed-right-top",
                attrs: {
                  "button-text": _vm.$t("GENERAL_SETTINGS.SUBMIT"),
                  loading: _vm.isUpdating
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlags.isFetchingItem ? _c("woot-loading-state") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }