var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications-link" },
    [
      _c("primary-nav-item", {
        attrs: {
          name: "NOTIFICATIONS",
          icon: { name: "alert", isIcon: true },
          to: "/app/accounts/" + _vm.accountId + "/notifications",
          count: _vm.unreadCount
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }