var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          padding: "8px",
          "padding-left": "24px",
          "padding-right": "24px",
          color: "grey",
          "align-items": "center"
        }
      },
      [_c("h5", [_vm._v(_vm._s(_vm.$t("FLOW.EDIT_LIST")))])]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("woot-input", {
              class: { error: _vm.$v.list.title.$error },
              attrs: {
                label: _vm.$t("FLOW.LIST_TITLE"),
                error: _vm.$v.list.title.$error
                  ? _vm.$v.list.title.$model.length < 1
                    ? "Title must be at least 1 character."
                    : "Title must be at most 60 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.TITLE")
              },
              on: { blur: _vm.$v.list.title.$touch },
              model: {
                value: _vm.list.title,
                callback: function($$v) {
                  _vm.$set(_vm.list, "title", $$v)
                },
                expression: "list.title"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.body.$error },
              attrs: {
                label: _vm.$t("FLOW.LIST_BODY"),
                error: _vm.$v.list.body.$error
                  ? _vm.$v.list.body.$model.length < 1
                    ? "Body must be at least 1 character."
                    : "Body must be at most 4096 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.BODY")
              },
              on: { blur: _vm.$v.list.body.$touch },
              model: {
                value: _vm.list.body,
                callback: function($$v) {
                  _vm.$set(_vm.list, "body", $$v)
                },
                expression: "list.body"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.footer.$error },
              attrs: {
                label: _vm.$t("FLOW.LIST_FOOTER"),
                error: _vm.$v.list.footer.$error
                  ? _vm.$v.list.footer.$model.length > 60
                    ? "Footer must be at most 60 characters."
                    : ""
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.FOOTER")
              },
              on: { blur: _vm.$v.list.footer.$touch },
              model: {
                value: _vm.list.footer,
                callback: function($$v) {
                  _vm.$set(_vm.list, "footer", $$v)
                },
                expression: "list.footer"
              }
            }),
            _vm._v(" "),
            _c("woot-input", {
              class: { error: _vm.$v.list.button.$error },
              attrs: {
                label: _vm.$t("FLOW.LIST_BTN_LEVEL"),
                error: _vm.$v.list.button.$error
                  ? _vm.$v.list.button.$model.length < 1
                    ? "Button label must be at least 1 character."
                    : "Button label must be at most 20 characters."
                  : "",
                type: "text",
                placeholder: _vm.$t("FLOW.PLACEHOLDERS.BTN_LEVEL")
              },
              on: { blur: _vm.$v.list.button.$touch },
              model: {
                value: _vm.list.button,
                callback: function($$v) {
                  _vm.$set(_vm.list, "button", $$v)
                },
                expression: "list.button"
              }
            }),
            _vm._v(" "),
            _c("section", [
              _c(
                "ul",
                { staticStyle: { margin: "0", "list-style": "none" } },
                _vm._l(_vm.list.action.sections, function(section, sectionIdx) {
                  return _c(
                    "li",
                    { key: sectionIdx, staticClass: "section_item" },
                    [
                      _c("woot-input", {
                        class: {
                          error:
                            _vm.$v.list.action.sections.$each[sectionIdx].title
                              .$error
                        },
                        attrs: {
                          label: "Section title",
                          error: _vm.$v.list.action.sections.$each[sectionIdx]
                            .title.$error
                            ? "Section title must be between 1 and 24 characters."
                            : "",
                          type: "text",
                          placeholder: "Enter item title"
                        },
                        on: {
                          blur:
                            _vm.$v.list.action.sections.$each[sectionIdx].title
                              .$touch
                        },
                        model: {
                          value: section.title,
                          callback: function($$v) {
                            _vm.$set(section, "title", $$v)
                          },
                          expression: "section.title"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "ul",
                          {
                            staticStyle: { margin: "0", "list-style": "none" }
                          },
                          _vm._l(section.rows, function(row, rowIdx) {
                            return _c(
                              "li",
                              { key: "row" + rowIdx, staticClass: "item" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("woot-input", {
                                      class: {
                                        error:
                                          _vm.$v.list.action.sections.$each[
                                            sectionIdx
                                          ].rows.$each[rowIdx].title.$error
                                      },
                                      attrs: {
                                        label: "Row title",
                                        error: _vm.$v.list.action.sections
                                          .$each[sectionIdx].rows.$each[rowIdx]
                                          .title.$error
                                          ? "Row title must be between 1 and 24 characters."
                                          : "",
                                        type: "text",
                                        placeholder: "Enter row title"
                                      },
                                      on: {
                                        blur:
                                          _vm.$v.list.action.sections.$each[
                                            sectionIdx
                                          ].rows.$each[rowIdx].title.$touch,
                                        input: function($event) {
                                          return _vm.onTitleInput(
                                            sectionIdx,
                                            rowIdx
                                          )
                                        }
                                      },
                                      model: {
                                        value: row.title,
                                        callback: function($$v) {
                                          _vm.$set(row, "title", $$v)
                                        },
                                        expression: "row.title"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("woot-input", {
                                      class: {
                                        error:
                                          _vm.$v.list.action.sections.$each[
                                            sectionIdx
                                          ].rows.$each[rowIdx].description
                                            .$error
                                      },
                                      attrs: {
                                        label: "Row description",
                                        error: _vm.$v.list.action.sections
                                          .$each[sectionIdx].rows.$each[rowIdx]
                                          .description.$error
                                          ? "Row description must be between 1 and 72 characters."
                                          : "",
                                        type: "text",
                                        placeholder: "Enter row description"
                                      },
                                      on: {
                                        blur:
                                          _vm.$v.list.action.sections.$each[
                                            sectionIdx
                                          ].rows.$each[rowIdx].description
                                            .$touch
                                      },
                                      model: {
                                        value: row.description,
                                        callback: function($$v) {
                                          _vm.$set(row, "description", $$v)
                                        },
                                        expression: "row.description"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "action_btn" }, [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteRowItem(
                                            sectionIdx,
                                            rowIdx
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("FluentIcon", {
                                        attrs: { icon: "dismiss" }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.addRowItem(sectionIdx)
                                }
                              }
                            },
                            [_c("DashboardIcon", { attrs: { icon: "add" } })],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "action_btn" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.deleteSectionItem(sectionIdx)
                              }
                            }
                          },
                          [_c("FluentIcon", { attrs: { icon: "dismiss" } })],
                          1
                        )
                      ])
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "1em" }
                },
                [
                  _c("woot-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-end",
                        value: "Add New Section",
                        expression: "'Add New Section'",
                        modifiers: { "top-end": true }
                      }
                    ],
                    attrs: {
                      size: "small",
                      variant: "smooth",
                      "color-scheme": "secondary",
                      icon: "add"
                    },
                    on: { click: _vm.addSection }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "woot-button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.allDone($event)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("FLOW.DONE")) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                attrs: { variant: "clear" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClose($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("FLOW.CANCEL")) + "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }