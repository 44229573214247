var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "small-12 columns integrations-wrap" }, [
        _c(
          "div",
          { staticClass: "row integrations" },
          _vm._l(_vm.integrationsList, function(item) {
            return _c(
              "div",
              { key: item.id, staticClass: "small-12 columns integration" },
              [
                _c("integration", {
                  attrs: {
                    "integration-id": item.id,
                    "integration-logo": item.logo,
                    "integration-name": item.name,
                    "integration-description": item.description,
                    "integration-enabled": item.enabled,
                    "integration-action": item.action
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }