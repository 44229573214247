var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-attribute" }, [
    _c("div", { staticClass: "title-wrap" }, [
      _c("h4", { staticClass: "text-block-title title error" }, [
        _vm.isAttributeTypeCheckbox
          ? _c("div", { staticClass: "checkbox-wrap" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editedValue,
                    expression: "editedValue"
                  }
                ],
                staticClass: "checkbox",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.editedValue)
                    ? _vm._i(_vm.editedValue, null) > -1
                    : _vm.editedValue
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.editedValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.editedValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.editedValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.editedValue = $$c
                      }
                    },
                    _vm.onUpdate
                  ]
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "name-button__wrap" },
          [
            _c(
              "span",
              {
                staticClass: "attribute-name",
                class: { error: _vm.$v.editedValue.$error }
              },
              [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]
            ),
            _vm._v(" "),
            _vm.showActions
              ? _c("woot-button", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.left",
                      value: _vm.$t("CUSTOM_ATTRIBUTES.ACTIONS.DELETE"),
                      expression: "$t('CUSTOM_ATTRIBUTES.ACTIONS.DELETE')",
                      modifiers: { left: true }
                    }
                  ],
                  attrs: {
                    variant: "link",
                    size: "medium",
                    "color-scheme": "secondary",
                    icon: "delete",
                    "class-names": "delete-button"
                  },
                  on: { click: _vm.onDelete }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm.notAttributeTypeCheckboxAndList
      ? _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditing,
                  expression: "isEditing"
                }
              ]
            },
            [
              _c("div", { staticClass: "input-group small" }, [
                _vm.inputType === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedValue,
                          expression: "editedValue"
                        }
                      ],
                      ref: "inputfield",
                      staticClass: "input-group-field",
                      class: { error: _vm.$v.editedValue.$error },
                      attrs: { autofocus: "true", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.editedValue)
                          ? _vm._i(_vm.editedValue, null) > -1
                          : _vm.editedValue
                      },
                      on: {
                        blur: _vm.$v.editedValue.$touch,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onUpdate($event)
                        },
                        change: function($event) {
                          var $$a = _vm.editedValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.editedValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.editedValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.editedValue = $$c
                          }
                        }
                      }
                    })
                  : _vm.inputType === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedValue,
                          expression: "editedValue"
                        }
                      ],
                      ref: "inputfield",
                      staticClass: "input-group-field",
                      class: { error: _vm.$v.editedValue.$error },
                      attrs: { autofocus: "true", type: "radio" },
                      domProps: { checked: _vm._q(_vm.editedValue, null) },
                      on: {
                        blur: _vm.$v.editedValue.$touch,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onUpdate($event)
                        },
                        change: function($event) {
                          _vm.editedValue = null
                        }
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedValue,
                          expression: "editedValue"
                        }
                      ],
                      ref: "inputfield",
                      staticClass: "input-group-field",
                      class: { error: _vm.$v.editedValue.$error },
                      attrs: { autofocus: "true", type: _vm.inputType },
                      domProps: { value: _vm.editedValue },
                      on: {
                        blur: _vm.$v.editedValue.$touch,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onUpdate($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.editedValue = $event.target.value
                        }
                      }
                    }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group-button" },
                  [
                    _c("woot-button", {
                      attrs: { size: "small", icon: "checkmark" },
                      on: { click: _vm.onUpdate }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.shouldShowErrorMessage
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      ")
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEditing,
                  expression: "!isEditing"
                }
              ],
              staticClass: "value--view",
              class: { "is-editable": _vm.showActions }
            },
            [
              _vm.isAttributeTypeLink
                ? _c(
                    "a",
                    {
                      staticClass: "value",
                      attrs: {
                        href: _vm.value,
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }
                    },
                    [_vm._v("\n        " + _vm._s(_vm.urlValue) + "\n      ")]
                  )
                : _c("p", { staticClass: "value" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.displayValue || "---") +
                        "\n      "
                    )
                  ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "action-buttons__wrap" },
                [
                  _vm.showActions
                    ? _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.$t("CUSTOM_ATTRIBUTES.ACTIONS.COPY"),
                            expression: "$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
                          }
                        ],
                        attrs: {
                          variant: "link",
                          size: "small",
                          "color-scheme": "secondary",
                          icon: "clipboard",
                          "class-names": "edit-button"
                        },
                        on: { click: _vm.onCopy }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showActions
                    ? _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.right",
                            value: _vm.$t("CUSTOM_ATTRIBUTES.ACTIONS.EDIT"),
                            expression: "$t('CUSTOM_ATTRIBUTES.ACTIONS.EDIT')",
                            modifiers: { right: true }
                          }
                        ],
                        attrs: {
                          variant: "link",
                          size: "small",
                          "color-scheme": "secondary",
                          icon: "edit",
                          "class-names": "edit-button"
                        },
                        on: { click: _vm.onEdit }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isAttributeTypeList
      ? _c(
          "div",
          [
            _c("multiselect-dropdown", {
              attrs: {
                options: _vm.listOptions,
                "selected-item": _vm.selectedItem,
                "has-thumbnail": false,
                "multiselector-placeholder": _vm.$t(
                  "CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_TYPE.LIST.PLACEHOLDER"
                ),
                "no-search-result": _vm.$t(
                  "CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_TYPE.LIST.NO_RESULT"
                ),
                "input-placeholder": _vm.$t(
                  "CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_TYPE.LIST.SEARCH_INPUT_PLACEHOLDER"
                )
              },
              on: { click: _vm.onUpdateListValue }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isAttributeTypeArray
      ? _c(
          "div",
          [
            _c("multiselect", {
              ref: "tagInput",
              class: { invalid: _vm.isMultiselectInvalid },
              attrs: {
                placeholder: _vm.$t(
                  "ATTRIBUTES_MGMT.ADD.FORM.TYPE.BRAND_PROFILE.PLACEHOLDER"
                ),
                label: "brandProfileId",
                "track-by": "brandProfileId",
                "no-options": "Please enter the brand profile id",
                options: _vm.options,
                multiple: true,
                taggable: true
              },
              on: {
                close: _vm.onTouch,
                tag: _vm.addTagValue,
                remove: _vm.removeValue
              },
              model: {
                value: _vm.brandIds,
                callback: function($$v) {
                  _vm.brandIds = $$v
                },
                expression: "brandIds"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }