var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "image-section",
      attrs: { size: "medium", show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t(
            "CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.LIST.HEADER"
          )
        }
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 small-12 header-section" },
        [
          _c(
            "div",
            { staticClass: "search-wrap" },
            [
              _c("fluent-icon", {
                staticClass: "search-icon",
                attrs: { icon: "search" }
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "contact-search",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "CAMPAIGN.EMAIL_TEMPLATE.SEARCH_INPUT_PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.searchQuery },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSearchSubmit($event)
                  },
                  input: _vm.onInputSearch
                }
              }),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  staticClass: "clear",
                  attrs: {
                    "is-loading": false,
                    "class-names": this.searchQuery !== "" ? "show" : ""
                  },
                  on: { click: _vm.onSearchSubmit }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("CAMPAIGN.EMAIL_TEMPLATE.SEARCH_BUTTON_TEXT"))
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("woot-button", { on: { click: _vm.openAddAttachmentModal } }, [
            _vm._v(
              _vm._s(
                _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.ADD_ATTCHMENT_BUTTON_TEXT")
              )
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row images" },
        [
          _c("div", { staticClass: "columns medium-12" }, [
            _c("h6", [
              _vm._v(
                _vm._s(
                  _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.LIST.SUB_HEADER")
                )
              )
            ])
          ]),
          _vm._v(" "),
          _vm.attchments.length === 0
            ? _c("empty-state", {
                attrs: {
                  title: _vm.$t("CAMPAIGN.EMAIL_ATTCHMENTS.INBOXES_NOT_FOUND")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.attchments, function(attchment) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.attchments.length > 0,
                    expression: "attchments.length > 0"
                  }
                ],
                key: attchment.id,
                staticClass: "columns medium-6 small-12 images-content"
              },
              [
                _c(
                  "div",
                  { staticClass: "detail" },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(attchment.caption ? attchment.caption : "-")
                      )
                    ]),
                    _vm._v(" "),
                    _c("woot-button", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t(
                            "CAMPAIGN.EMAIL_TEMPLATE.DELETE_BUTTON_TEXT"
                          ),
                          expression:
                            "$t('CAMPAIGN.EMAIL_TEMPLATE.DELETE_BUTTON_TEXT')",
                          modifiers: { top: true }
                        }
                      ],
                      attrs: {
                        variant: "smooth",
                        "color-scheme": "alert",
                        size: "small",
                        icon: "dismiss-circle",
                        "class-names": "grey-btn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.openDeletePopup(attchment.id)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "image-wrap",
                    on: {
                      click: function($event) {
                        return _vm.$emit("selectImage", attchment.data_url)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: attchment.data_url,
                        alt: attchment.caption
                          ? attchment.caption
                          : attchment.id
                      }
                    })
                  ]
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.isAddAttchmentModalOpen
        ? _c("add-attchment", { on: { close: _vm.closeAddAttachmentModal } })
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": _vm.deleteConfirmText,
          "reject-text": _vm.deleteRejectText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }