var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper app-root", attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("add-account-modal", {
        attrs: {
          show: _vm.showAddAccountModal,
          "has-accounts": _vm.hasAccounts
        }
      }),
      _vm._v(" "),
      _c("woot-snackbar-box"),
      _vm._v(" "),
      _c("network-notification")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }