var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.$t("FLOW.CREATE_NEW_FLOW") }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-input", {
                attrs: {
                  label: _vm.$t("FLOW.FLOW_NAME"),
                  type: "text",
                  placeholder: _vm.$t("FLOW.PLACEHOLDERS.FLOW_NAME")
                },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              }),
              _vm._v(" "),
              _c("label", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("FLOW.SELECT_TEMPLATE")) +
                    "\n        "
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flow-templates" },
                  _vm._l(_vm.templateOptions, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "flow-template",
                        class:
                          _vm.selectedTemplate.id == item.id
                            ? "selectedTemplate"
                            : "",
                        on: {
                          click: function($event) {
                            return _vm.onTemplateClick(item)
                          }
                        }
                      },
                      [
                        _c(
                          "h2",
                          {
                            class:
                              _vm.selectedTemplate.id == item.id
                                ? "selectedTemplate"
                                : ""
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    disabled: _vm.isCreating,
                    "is-loading": _vm.isCreating
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.allDone($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("FLOW.CREATE")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("FLOW.CANCEL")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }