var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "small-8 columns with-right-space " }, [
      _c("div", { staticClass: "sub_tabs" }, [
        _c("div", [
          _c(
            "ul",
            { staticClass: "tabs" },
            _vm._l(_vm.tabs, function(tab) {
              return _c(
                "li",
                {
                  key: tab.key,
                  staticClass: "tabs-title",
                  class: tab.code === _vm.selectedTab ? "is-active" : "",
                  on: {
                    click: function($event) {
                      return _vm.onSubTabChange(tab.code)
                    }
                  }
                },
                [_c("a", [_vm._v(_vm._s(tab.name))])]
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "row",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.addTemplateMessage()
              }
            }
          },
          [
            _c("div", { staticClass: "medium-12 columns" }, [
              _c("label", { class: { error: _vm.$v.templateName.$error } }, [
                _vm._v(
                  "\n                  " +
                    _vm._s(
                      _vm.$t("TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.LABEL")
                    ) +
                    "\n                  "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.templateName,
                      expression: "templateName",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.templateName },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.templateName = $event.target.value.trim()
                      },
                      _vm.$v.templateName.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.templateName.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(
                        "\n                      Invalid template name\n                  "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "medium-12 columns display-inline" }, [
              _c(
                "div",
                { staticClass: "medium-4 columns" },
                [
                  _c("formulate-input", {
                    attrs: {
                      options: this.inboxList(),
                      type: "select",
                      name: "inbox",
                      placeholder: _vm.$t("TEMPLATE_MSG.ADD.FORM.INBOX.LABEL"),
                      label: _vm.$t("TEMPLATE_MSG.ADD.FORM.INBOX.PLACEHOLDER"),
                      validation: "required",
                      "validation-name": "Inbox"
                    },
                    model: {
                      value: _vm.inbox,
                      callback: function($$v) {
                        _vm.inbox = $$v
                      },
                      expression: "inbox"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-4 columns" },
                [
                  _c("formulate-input", {
                    attrs: {
                      options: this.languages(),
                      type: "select",
                      name: "language",
                      placeholder: _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.LANGUAGE.LABEL"
                      ),
                      label: _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.LANGUAGE.PLACEHOLDER"
                      ),
                      validation: "required",
                      "validation-name": "Language"
                    },
                    model: {
                      value: _vm.language,
                      callback: function($$v) {
                        _vm.language = $$v
                      },
                      expression: "language"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-4 columns" },
                [
                  _c("formulate-input", {
                    attrs: {
                      options: this.templateTypes(),
                      type: "select",
                      name: "template-type",
                      placeholder: _vm.$t("TEMPLATE_MSG.ADD.FORM.TYPE.LABEL"),
                      label: _vm.$t("TEMPLATE_MSG.ADD.FORM.TYPE.PLACEHOLDER"),
                      validation: "required",
                      "validation-name": "Template Type"
                    },
                    model: {
                      value: _vm.templateType,
                      callback: function($$v) {
                        _vm.templateType = $$v
                      },
                      expression: "templateType"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "medium-12 columns" }, [
              _c("label", { class: { error: _vm.$v.content.$error } }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("TEMPLATE_MSG.ADD.FORM.CONTENT.LABEL")) +
                    "\n              "
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.content,
                      expression: "content",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    rows: "5",
                    type: "text",
                    placeholder: _vm.$t(
                      "TEMPLATE_MSG.ADD.FORM.CONTENT.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.content },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.content = $event.target.value.trim()
                      },
                      _vm.$v.content.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "medium-12 columns display-inline" }, [
              _c("label", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("TEMPLATE_MSG.ADD.FORM.IMAGE.LABEL")))
                ]),
                _vm._v(" "),
                _c("input", {
                  ref: "file",
                  attrs: {
                    id: "file",
                    type: "file",
                    accept: "image/*, .mp4, .pdf"
                  },
                  on: { change: _vm.handleImageUpload }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "medium-12 columns" }, [
              _c(
                "label",
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL")))
                  ]),
                  _vm._v(" "),
                  _c("multiselect", {
                    staticClass: "small-12",
                    attrs: {
                      "tag-placeholder": _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.BUTTON.PLACEHOLDER"
                      ),
                      label: "name",
                      max: 3,
                      "track-by": "code",
                      options: _vm.options,
                      multiple: true,
                      taggable: true
                    },
                    on: { tag: _vm.addButton },
                    model: {
                      value: _vm.buttonsData,
                      callback: function($$v) {
                        _vm.buttonsData = $$v
                      },
                      expression: "buttonsData"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c("woot-submit-button", {
                    attrs: {
                      disabled: _vm.showLoading,
                      "button-text": _vm.$t("TEMPLATE_MSG.ADD.FORM.SUBMIT"),
                      loading: _vm.showLoading
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button clear",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onClose($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(
                            _vm.$t("TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT")
                          ) +
                          "\n              "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "small-4 columns" }, [
      _c("div", { staticClass: "col_preview" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "preview_card" }, [
            _c(
              "div",
              { staticClass: "media" },
              [
                _c("div", { staticClass: "arrow_mark" }),
                _vm._v(" "),
                [
                  _vm.fileType.isImage
                    ? _c("div", { staticClass: "file_view_wrapper" }, [
                        _c("img", {
                          attrs: { src: _vm.filePreviewUrl, alt: "" }
                        })
                      ])
                    : _vm.fileType.isVideo
                    ? _c("div", { staticClass: "file_view_wrapper" }, [
                        _c(
                          "video",
                          { attrs: { controls: "", autoplay: "false" } },
                          [
                            _c("source", {
                              attrs: {
                                src: _vm.filePreviewUrl,
                                type: "video/mp4"
                              }
                            }),
                            _vm._v(
                              " \n                                  Your browser does not support the video tag.\n                              "
                            )
                          ]
                        )
                      ])
                    : _vm.fileType.isPDF
                    ? _c("div", { staticClass: "file_view_wrapper" }, [
                        _c(
                          "iframe",
                          {
                            attrs: {
                              src: _vm.filePreviewUrl,
                              frameborder: "0",
                              width: "100%",
                              height: "100%"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                  This browser does not support PDFs. Please download the PDF to view it.\n                              "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("p", [_vm._v(_vm._s(_vm.content))]),
              _vm._v(" "),
              _c("time", [_vm._v(_vm._s(_vm.getCurrentTime()))])
            ]),
            _vm._v(" "),
            _vm.buttonsData.length
              ? _c("div", { staticClass: "buttons" }, [
                  _c(
                    "div",
                    { staticClass: "btn_wrapper" },
                    _vm._l(_vm.buttonsData, function(buttonEl) {
                      return _c("a", { key: buttonEl.code }, [
                        _vm._v(
                          "\n                          " +
                            _vm._s(buttonEl.name) +
                            "\n                          "
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "preview_title" }, [
      _c("p", [_c("b", [_vm._v("Message Preview")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }