var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parent-container" },
    [
      _c("nav", { staticClass: "navbar navbar-light bg-light" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "navbar-right-content" },
            [
              _c("notification-bell"),
              _vm._v(" "),
              _c("agent-details", { on: { "toggle-menu": _vm.toggleOptions } }),
              _vm._v(" "),
              _c("options-menu", {
                attrs: { show: _vm.showOptionsMenu },
                on: {
                  "toggle-accounts": _vm.toggleAccountModal,
                  "show-support-chat-window": _vm.toggleSupportChatWindow,
                  "key-shortcut-modal": _vm.toggleKeyShortcutModal,
                  close: _vm.toggleOptions
                }
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "dropdown-arrow",
                attrs: {
                  role: "button",
                  src: require("dashboard/assets/images/menu-icons/arrow-down.svg"),
                  alt: "dropdown-arrow"
                },
                on: { click: _vm.toggleOptions }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("TopNotificationBar", {
        attrs: { isSideOpen: _vm.isOnDesktop || _vm.isSidebarOpen }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row app-wrapper" },
        [
          _c("sidebar", {
            ref: "sidebar",
            class: _vm.sidebarClassName,
            attrs: { route: _vm.currentRoute }
          }),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "app-content columns", class: _vm.contentClassName },
            [_c("router-view"), _vm._v(" "), _c("command-bar")],
            1
          ),
          _vm._v(" "),
          _c("DemoInstructionPopup")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
      _c("img", {
        attrs: {
          src: require("dashboard/assets/images/menu-icons/header-logo.svg"),
          alt: "header-logo",
          height: "60"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }