var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _vm.displayPopUp
      ? _c("div", { staticClass: "plan-loader" }, [
          _c("div", { staticClass: "pop-up" }, [
            _vm.confirmUpdatePopup
              ? _c("div", [
                  _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          this.$t("AUTOMATION.CONFIRM_SUBSCRIPTION_UPDATE")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center"
                      }
                    },
                    [
                      _vm.promoCodeInput
                        ? _c("woot-input", {
                            staticStyle: { width: "200px" },
                            attrs: { type: "text", placeholder: "Promocode" },
                            model: {
                              value: _vm.promotionCode,
                              callback: function($$v) {
                                _vm.promotionCode = $$v
                              },
                              expression: "promotionCode"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { on: { click: _vm.openPromoCodeInput } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(this.$t("AUTOMATION.APPLY_PROMO_CODE_LABEL")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("button", { on: { click: _vm.closePopUp } }, [
                    _vm._v("Cancel Payment")
                  ]),
                  _vm._v("  \n        "),
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.upgradePlan(_vm.selectedPlan)
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ])
              : _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$t("AUTOMATION.SUBSCRIPTION_POP_UP_HEADING"))
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        this.$t("AUTOMATION.SUBSCRIPTION_POP_UP_SUB_HEADING")
                      )
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("button", { on: { click: _vm.hidePopUp } }, [_vm._v("Ok")])
                ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("stripe-component", {
          attrs: {
            "modal-value": this.topUpModal,
            "registration-fee": _vm.registrationFee,
            "user-balance": _vm.userBalance,
            "product-id": _vm.productId,
            "old-plan-id": _vm.oldPlanId
          },
          on: { "close-modal": _vm.closeModal }
        }),
        _vm._v(" "),
        _vm.isLoadingSpinner
          ? _c(
              "div",
              { staticClass: "plan-spinner" },
              [_c("spinner", { attrs: { size: "large" } })],
              1
            )
          : _c("div", [
              _vm.currentUser.plan_id === 16
                ? _c("div", { staticClass: "expire-msg" }, [
                    _c("div", { staticClass: "size-3 billing text-center" }, [
                      _vm._v(" Subscription Expired.")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.expireMessage.message))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "plans" },
                _vm._l(
                  _vm.plans
                    .filter(_vm.filterVisiablePlans)
                    .filter(_vm.filterSignUpTypePlans),
                  function(plan) {
                    return _c(
                      "div",
                      { key: plan.id, staticClass: "containerDiv" },
                      [
                        _c("div", { staticClass: "outerSection" }, [
                          _c("div", { staticClass: "section-1" }, [
                            _c(
                              "div",
                              { staticClass: "plan size-3 text-center" },
                              [_vm._v(_vm._s(plan.name))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "size-1" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.currentUser.default_currency.toUpperCase()
                                  ) +
                                  "\n                " +
                                  _vm._s(
                                    plan.yearly_pay[
                                      _vm.currentUser.default_currency
                                    ].price
                                  ) +
                                  "\n              "
                              )
                            ]),
                            _vm._v(" "),
                            !_vm.ifAppsumoUser
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "m-2 size-3 billing text-center"
                                  },
                                  [_vm._v("BILLED YEARLY")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "text-center",
                                staticStyle: { "margin-top": "5%" }
                              },
                              [
                                _c("div", { staticClass: "size-3 products" }, [
                                  _vm._v(_vm._s(plan.product))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(plan.features, function(feature, index) {
                                return _c("li", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row",
                                      staticStyle: { "flex-flow": "nowrap" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("fluent-icon", {
                                            staticStyle: { margin: "2px" },
                                            attrs: {
                                              icon: "checkmark-circle",
                                              color: "green",
                                              size: "15"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p", [_vm._v(_vm._s(feature))])
                                    ]
                                  )
                                ])
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm.checkIsAppsumoUser
                              ? _c("div", { staticClass: "section-2" }, [
                                  _vm.getApsumoPlanButton(plan) === "switch"
                                    ? _c(
                                        "button",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAppsumoPlan(plan)
                                            }
                                          }
                                        },
                                        [_vm._v("Switch")]
                                      )
                                    : _vm.getApsumoPlanButton(plan) ===
                                      "unsubscribe"
                                    ? _c(
                                        "button",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.handleAppsumoPlan(plan)
                                            }
                                          }
                                        },
                                        [_vm._v("Unsubscribe")]
                                      )
                                    : _vm._e()
                                ])
                              : _c("div", [
                                  _c("div", { staticClass: "section-2" }, [
                                    plan.id === _vm.userPlan.id
                                      ? _c(
                                          "button",
                                          {
                                            staticStyle: {
                                              "background-color": "#38bf32"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Active\n                  "
                                            )
                                          ]
                                        )
                                      : _vm.userPlan.yearly_pay[
                                          _vm.currentUser.default_currency
                                        ] &&
                                        plan.yearly_pay[
                                          _vm.currentUser.default_currency
                                        ].price <
                                          _vm.userPlan.yearly_pay[
                                            _vm.currentUser.default_currency
                                          ].price
                                      ? _c(
                                          "button",
                                          {
                                            staticStyle: {
                                              "background-color":
                                                "rgba(37,36,36,0.55)"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Disabled\n                  "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.openCheckout(
                                                  plan,
                                                  _vm.userPlan
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Upgrade")]
                                        )
                                  ])
                                ])
                          ])
                        ])
                      ]
                    )
                  }
                ),
                0
              )
            ]),
        _vm._v(" "),
        _vm.isStripeSessionLoading
          ? _c(
              "div",
              { staticClass: "full_loader" },
              [
                _c("div", { staticClass: "size-1" }, [
                  _vm._v("Please wait...")
                ]),
                _vm._v(" "),
                _c("Spinner", { attrs: { size: "medium" } })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }