var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c(
      "div",
      {
        staticClass: "filter",
        class: {
          error:
            (_vm.v.values.$dirty && _vm.v.values.$error) ||
            (_vm.attributeKey === "custom_event" &&
              ((_vm.v.days.$dirty && _vm.v.days.$error) ||
                (_vm.v.custom_event_name.$dirty &&
                  _vm.v.custom_event_name.$error)))
        }
      },
      [
        _c("div", { staticClass: "filter-inputs" }, [
          _vm.groupedFilters
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attributeKey,
                      expression: "attributeKey"
                    }
                  ],
                  staticClass: "filter__question",
                  class: _vm.reOrderLabels == "true" ? "order4" : "",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.attributeKey = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.resetFilter()
                      }
                    ]
                  }
                },
                _vm._l(_vm.filterGroups, function(group, i) {
                  return _c(
                    "optgroup",
                    { key: i, attrs: { label: group.name } },
                    _vm._l(group.attributes, function(attribute) {
                      return _c(
                        "option",
                        {
                          key: attribute.key,
                          domProps: { value: attribute.key }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(attribute.name) +
                              "\n          "
                          )
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              )
            : _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attributeKey,
                      expression: "attributeKey"
                    }
                  ],
                  staticClass: "filter__question",
                  class: _vm.reOrderLabels == "true" ? "order4" : "",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.attributeKey = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.resetFilter()
                      }
                    ]
                  }
                },
                _vm._l(_vm.filterAttributes, function(attribute) {
                  return _c(
                    "option",
                    { key: attribute.key, domProps: { value: attribute.key } },
                    [
                      _vm._v(
                        "\n          " + _vm._s(attribute.name) + "\n        "
                      )
                    ]
                  )
                }),
                0
              ),
          _vm._v(" "),
          _vm.reOrderLabels == "true"
            ? _c(
                "div",
                {
                  staticClass: "days",
                  class: _vm.reOrderLabels == "true" ? "order2" : ""
                },
                [
                  _c("label", { staticClass: "days_label" }, [
                    _vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.CUSTOM.DAYS")))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.attributeKey === "custom_event"
            ? _c("div", { staticClass: "filter__answer--wrap" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.custom_event_name,
                      expression: "custom_event_name"
                    }
                  ],
                  staticClass: "answer--text-input",
                  attrs: { type: "text", placeholder: "Enter event name" },
                  domProps: { value: _vm.custom_event_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.custom_event_name = $event.target.value
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterOperator,
                  expression: "filterOperator"
                }
              ],
              staticClass: "filter__operator",
              class: _vm.reOrderLabels == "true" ? "order3" : "",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.filterOperator = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.operators, function(operator, o) {
              return _c(
                "option",
                { key: o, domProps: { value: operator.value } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("FILTER.OPERATOR_LABELS." + operator.value)
                      ) +
                      "\n        "
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.showUserInput
            ? _c("div", { staticClass: "filter__answer--wrap" }, [
                _vm.inputType === "multi_select"
                  ? _c(
                      "div",
                      { staticClass: "multiselect-wrap--small" },
                      [
                        _c("multiselect", {
                          attrs: {
                            "track-by": "id",
                            label: "name",
                            placeholder: "Select",
                            multiple: true,
                            "selected-label": "",
                            "select-label": _vm.$t(
                              "FORMS.MULTISELECT.ENTER_TO_SELECT"
                            ),
                            "deselect-label": "",
                            "max-height": 160,
                            options: _vm.dropdownValues,
                            "allow-empty": false
                          },
                          model: {
                            value: _vm.values,
                            callback: function($$v) {
                              _vm.values = $$v
                            },
                            expression: "values"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.inputType === "search_select"
                  ? _c(
                      "div",
                      { staticClass: "multiselect-wrap--small" },
                      [
                        _c("multiselect", {
                          attrs: {
                            "track-by": "id",
                            label: "name",
                            placeholder: "Select",
                            "selected-label": "",
                            "select-label": _vm.$t(
                              "FORMS.MULTISELECT.ENTER_TO_SELECT"
                            ),
                            "deselect-label": "",
                            "max-height": 160,
                            options: _vm.dropdownValues,
                            "allow-empty": false,
                            "option-height": 104
                          },
                          model: {
                            value: _vm.values,
                            callback: function($$v) {
                              _vm.values = $$v
                            },
                            expression: "values"
                          }
                        })
                      ],
                      1
                    )
                  : _vm.inputType === "date"
                  ? _c("div", { staticClass: "multiselect-wrap--small" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.values,
                            expression: "values"
                          }
                        ],
                        staticClass: "answer--text-input datepicker",
                        attrs: { type: "date", editable: false },
                        domProps: { value: _vm.values },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.values = $event.target.value
                          }
                        }
                      })
                    ])
                  : _vm.attributeKey === "custom_event"
                  ? _c("div", { staticClass: "multiselect-wrap--small" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.values,
                            expression: "values"
                          }
                        ],
                        staticClass: "answer--text-input",
                        attrs: {
                          type: "number",
                          min: "1",
                          placeholder: "Number of times"
                        },
                        domProps: { value: _vm.values },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.values = $event.target.value
                          }
                        }
                      })
                    ])
                  : _vm.reOrderLabels == "true" &&
                    _vm.value.filter_operator != "equal_to"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.values,
                          expression: "values"
                        }
                      ],
                      staticClass: "answer--text-input",
                      attrs: { type: "number", placeholder: "Enter value" },
                      domProps: { value: _vm.values },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.values = $event.target.value
                          },
                          _vm.handleDayNumberChange
                        ]
                      }
                    })
                  : _vm.reOrderLabels == "true" &&
                    _vm.value.filter_operator == "equal_to"
                  ? _c("span")
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.values,
                          expression: "values"
                        }
                      ],
                      staticClass: "answer--text-input",
                      attrs: { type: "text", placeholder: "Enter value" },
                      domProps: { value: _vm.values },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.values = $event.target.value
                        }
                      }
                    })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { class: _vm.reOrderLabels == "true" ? "d-none" : "" },
            [
              _c("woot-button", {
                attrs: {
                  icon: "dismiss",
                  variant: "clear",
                  "color-scheme": "secondary"
                },
                on: { click: _vm.removeFilter }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.attributeKey === "custom_event"
          ? _c(
              "div",
              { staticClass: "filter-inputs date-range " },
              [
                _c("multiselect", {
                  attrs: {
                    "track-by": "id",
                    label: "name",
                    placeholder: "Select",
                    "selected-label": "",
                    "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                    "deselect-label": "",
                    "max-height": 160,
                    options: _vm.customDropDown,
                    value: _vm.customDropDown[0],
                    "allow-empty": false,
                    "option-height": 104
                  },
                  model: {
                    value: _vm.customDropDownValue,
                    callback: function($$v) {
                      _vm.customDropDownValue = $$v
                    },
                    expression: "customDropDownValue"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "filter__answer--wrap" },
                  [
                    _vm.customDropDownValue.id === "in_between"
                      ? _c("woot-date-range-picker", {
                          attrs: {
                            "show-range": "",
                            value: _vm.customDateRange,
                            "confirm-text": _vm.$t(
                              "REPORT.CUSTOM_DATE_RANGE.CONFIRM"
                            ),
                            placeholder: _vm.$t(
                              "REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER"
                            )
                          },
                          on: { change: _vm.onChange }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.customDropDownValue.id === "in_the_past"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.days,
                              expression: "days"
                            }
                          ],
                          staticClass: "answer--text-input",
                          attrs: {
                            type: "number",
                            min: "1",
                            placeholder: "Number of days"
                          },
                          domProps: { value: _vm.days },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.days = $event.target.value
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.v.values.$dirty &&
        _vm.v.values.$error &&
        _vm.attributeKey !== "custom_event"
          ? _c("p", { staticClass: "filter-error" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("FILTER.EMPTY_VALUE_ERROR")) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.attributeKey === "custom_event" &&
        ((_vm.v.days.$dirty && _vm.v.days.$error) ||
          (_vm.v.custom_event_name.$dirty && _vm.v.custom_event_name.$error))
          ? _c("p", { staticClass: "filter-error" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("FILTER.ALL_VALUE_ERROR")) + "\n    "
              )
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.showQueryOperator
      ? _c("div", { staticClass: "filter__join-operator" }, [
          _c("hr", { staticClass: "operator__line" }),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.query_operator,
                  expression: "query_operator"
                }
              ],
              staticClass: "operator__select",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.query_operator = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { attrs: { value: "and" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("FILTER.QUERY_DROPDOWN_LABELS.AND")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "or" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("FILTER.QUERY_DROPDOWN_LABELS.OR")) +
                    "\n      "
                )
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }