var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t(
                "CATALOGUE.SETTING.LOCATION_MAP.MODAL_HEADER"
              ),
              "header-content": _vm.$t(
                "CATALOGUE.SETTING.LOCATION_MAP.MODAL_CONTENT"
              )
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "map_container" }, [
        _c("section", { staticClass: "place_container" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              attrs: { type: "search", placeholder: "Search location" },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "ul",
              { staticClass: "places" },
              _vm._l(_vm.places, function(place) {
                return _c(
                  "li",
                  {
                    key: place.place_id,
                    staticClass: "place",
                    on: {
                      click: function($event) {
                        return _vm.selectPlace(place)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "icon_wrap" }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: place.icon, alt: "" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("h6", [_vm._v(_vm._s(place.name))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(place.formatted_address))])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _c("section", [
          _c("div", { staticStyle: { height: "400px" }, attrs: { id: "map" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-submit-button", {
                attrs: {
                  "button-text": _vm.$t(
                    "CATALOGUE.SETTING.LOCATION_MAP.ADD_BTN"
                  )
                },
                on: { click: _vm.addLocationHandler }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }