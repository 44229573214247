var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSuperAdmin
        ? _c(
            "label",
            {
              staticClass: "custom-checkbox-container",
              staticStyle: {
                position: "absolute",
                right: "12rem",
                top: "7.5rem"
              }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("FLOW.PLACEHOLDERS.TEMPLATE")) +
                  "\n    "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.useAsTemplate,
                    expression: "useAsTemplate"
                  }
                ],
                attrs: { id: "waitForUserMessage", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.useAsTemplate)
                    ? _vm._i(_vm.useAsTemplate, null) > -1
                    : _vm.useAsTemplate
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.useAsTemplate,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.useAsTemplate = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.useAsTemplate = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.useAsTemplate = $$c
                      }
                    },
                    _vm.onUpdateTemplateCheckbox
                  ]
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "checkmark" })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top save-button",
            icon: "arrow-download",
            disabled: _vm.isExporting,
            "is-loading": _vm.isExporting
          },
          on: { click: _vm.exportIntoBotpress }
        },
        [
          _vm._v(
            _vm._s(
              _vm.isExporting ? _vm.$t("FLOW.SAVING") : _vm.$t("FLOW.SAVE")
            )
          )
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("woot-loading-state", { attrs: { message: "Fetching bot" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDiagram || true,
              expression: "showDiagram || true"
            }
          ]
        },
        [
          _c("VueDiagramEditor", {
            ref: "diagram",
            attrs: {
              "node-color": _vm.nodeColor,
              "node-pulsable": _vm.nodePulsable,
              "node-deletable": _vm.nodeDeletable,
              botid: this.$route.params.flowid
            },
            on: {
              "clicked-node": _vm.clickedNode,
              "add-btn-clicked": _vm.addClicked
            }
          }),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showAddPopup,
                "on-close":
                  _vm.currentNode && _vm.currentNode.data.type == "question"
                    ? _vm.closeQuestionModal
                    : _vm.hideAddPopup,
                size: "medium",
                "custom-class": "width"
              },
              on: {
                "update:show": function($event) {
                  _vm.showAddPopup = $event
                }
              }
            },
            [
              _vm.currentNode && _vm.currentNode.data.type == "question"
                ? _c(
                    "div",
                    [
                      _c("question-node", {
                        ref: "questionNodeAddModal",
                        attrs: {
                          node: _vm.currentNode,
                          dref: this.$refs.diagram,
                          botid: this.$route.params.flowid,
                          profiles: _vm.profiles
                        },
                        on: {
                          "on-close": _vm.hideAddPopup,
                          "all-done": _vm.editDone
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentNode && _vm.currentNode.data.type == "stripe"
                ? _c(
                    "div",
                    [
                      _c("stripe-node", {
                        attrs: {
                          node: _vm.currentNode,
                          dref: this.$refs.diagram,
                          botid: this.$route.params.flowid,
                          profiles: _vm.profiles
                        },
                        on: {
                          "on-close": _vm.hideAddPopup,
                          "all-done": _vm.editDone
                        }
                      })
                    ],
                    1
                  )
                : _c("edit-node", {
                    attrs: {
                      node: _vm.currentNode,
                      dref: this.$refs.diagram,
                      botid: this.$route.params.flowid,
                      profiles: _vm.profiles
                    }
                  }),
              _vm._v(" "),
              _vm.currentNode &&
              _vm.currentNode.data.type != "question" &&
              _vm.currentNode.data.type != "stripe"
                ? _c(
                    "div",
                    {
                      staticClass: "modal-footer",
                      staticStyle: {
                        "padding-left": "32px",
                        "padding-right": "32px"
                      }
                    },
                    [
                      _c(
                        "woot-button",
                        {
                          attrs: { disabled: !_vm.allGood },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.editDone($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("FLOW.DONE")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-button",
                        {
                          attrs: { variant: "clear" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.hideAddPopup($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("FLOW.CANCEL")))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "float",
              attrs: { href: "#" },
              on: { click: _vm.toggleAddMenu }
            },
            [
              _c("img", {
                class: [
                  _vm.showAddMenu == true
                    ? "image-float-variant"
                    : "image-float"
                ],
                attrs: {
                  src: require("dashboard/assets/images/flow-new/add-icon.png"),
                  width: "32"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAddMenu,
                  expression: "showAddMenu"
                }
              ],
              staticClass: "float-box"
            },
            [
              _c("h4", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("FLOW.ADD_NODE")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  staticStyle: { "background-color": "white" },
                  on: {
                    click: function($event) {
                      return _vm.addContentNode()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/menu-icon-content.png")
                    }
                  }),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.NODE_ADD_CONTENT")))
                  ]),
                  _vm._v(" "),
                  _c("span")
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  staticStyle: { "background-color": "#EDFFC0" },
                  on: {
                    click: function($event) {
                      return _vm.addConditionNode()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/menu-icon-condition.png")
                    }
                  }),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.NODE_ADD_CONDITION")))
                  ]),
                  _vm._v(" "),
                  _c("span")
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  staticStyle: { "background-color": "#B2DBFF" },
                  on: {
                    click: function($event) {
                      return _vm.addActionNode()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/menu-icon-action.png")
                    }
                  }),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.NODE_ADD_ACTION")))
                  ]),
                  _vm._v(" "),
                  _c("span")
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  staticStyle: { "background-color": "#FFD68A" },
                  on: {
                    click: function($event) {
                      return _vm.addCallAPINode()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/api-call.svg"),
                      width: "16px"
                    }
                  }),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.NODE_ADD_CALL_API")))
                  ]),
                  _vm._v(" "),
                  _c("span")
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  staticStyle: { "background-color": "#E8A7D5" },
                  on: {
                    click: function($event) {
                      return _vm.addQuestionNode()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/menu-icon-content.png")
                    }
                  }),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.QUESTION")))
                  ]),
                  _vm._v(" "),
                  _c("span")
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  staticStyle: { "background-color": "#BFE3B4" },
                  on: {
                    click: function($event) {
                      return _vm.addStripeNode()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/menu-icon-content.png")
                    }
                  }),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("FLOW.STRIPE")))
                  ]),
                  _vm._v(" "),
                  _c("span")
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }