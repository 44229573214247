var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.isInComing
            ? _vm.$t("CONVERSATION.ORDER")
            : _vm.$t("CONVERSATION.YOUR_SENT_CART")
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "odr-msg" },
        [
          _c("h6", [
            _vm._v(
              _vm._s(_vm.productRetailIds.length) +
                " " +
                _vm._s(
                  _vm.productRetailIds.length === 1
                    ? _vm.$t("CONVERSATION.ITEM")
                    : _vm.$t("CONVERSATION.ITEMS")
                )
            )
          ]),
          _vm._v(" "),
          _vm.isInComing
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("CONVERSATION.MYR")) +
                    " " +
                    _vm._s(_vm.calculateTotalItems) +
                    " (" +
                    _vm._s(_vm.$t("CONVERSATION.ESTIMATED")) +
                    ")"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.productDetailWithPriceQuantity, function(item, itemIndex) {
            return _c(
              "div",
              { key: itemIndex, staticClass: "row odr-content" },
              [
                _c(
                  "div",
                  { staticClass: "column small-2 img-card card" },
                  [
                    _c("bubble-image", {
                      staticClass: "food-img",
                      attrs: { url: item.image_link }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "odr-detail column small-9" }, [
                  _c("h6", [_vm._v(_vm._s(item.retailer_id))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(item.description))]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(item.order_item_price) +
                        " " +
                        _vm._s(item.order_currency)
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.order_quantity))]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(item.total_order_price) +
                        " " +
                        _vm._s(item.order_currency)
                    )
                  ])
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm.isInComing
            ? _c("h6", [
                _vm._v(
                  _vm._s(_vm.$t("CONVERSATION.TOTAL_PRICE")) +
                    ": " +
                    _vm._s(_vm.calculateTotalItems)
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.readableTime))])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }