<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px;
    padding-left: 24px;
    padding-right: 36px;
    color:grey;
    align-items: center;

    "
    >
      <input
        v-model="node.data.customName"
        type="text"
        style="margin:0; margin-right:5px;text-transform: uppercase;"
        :placeholder="node.title"
        class="inputTitle"
      />
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <label
          v-if="this.node.data.type == 'content'"
          class="custom-checkbox-container"
        >
          {{ $t('FLOW.WAIT_FOR_USER_MESSAGE') }}
          <input
            id="waitForUserMessage"
            v-model="waitForUserMessage"
            type="checkbox"
            @change="onUpdate"
          />
          <span class="checkmark"></span>
        </label>
        <call-api-view
          v-if="node.data.type == 'call-api'"
          :skill-data="node.data.skillData"
        >
        </call-api-view>
        <div
          v-else
          style="background-color:#f8fafc; min-height: 100px;border-radius: 10px;margin-bottom: 12px; padding: 12px;"
        >
          <div v-for="(content, index) in contents" :key="index">
            <!-- file contents start -->
            <div v-if="content.type == 'file'" class="content-image-input">
              <div
                v-if="content.value == ''"
                style="    display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;"
              >
                <woot-loading-state
                  v-if="content.isUploading"
                  :message="$t('FLOW.UPLOADING')"
                />

                <div
                  v-if="!content.isUploading"
                  style="display:flex; flex-direction:column; align-items:center; justify-content:center
                "
                >
                  <div class="upload-btn-wrapper">
                    <button class="btn">
                      {{ $t('FLOW.UPLOAD_FILE') }}
                    </button>

                    <input
                      type="file"
                      name="myfile"
                      accept="application/pdf"
                      @change="handleFileUpload($event, index)"
                    />
                  </div>

                  <div>
                    {{ $t('FLOW.OR') }}
                  </div>

                  <input
                    v-model="content.url"
                    class="w3-input"
                    type="text"
                    style="margin:0"
                    :placeholder="$t('FLOW.PLACEHOLDERS.PASTE_FILE_URL')"
                  />
                </div>

                <div v-if="!content.isUploading" style="margin-top: 12px;">
                  <woot-button
                    :disabled="content.url.trim().length == 0"
                    @click.prevent="uploadFromURL(index, content.url)"
                  >
                    {{ $t('FLOW.UPLOAD') }}
                  </woot-button>
                </div>
              </div>

              <div
                v-else
                style="padding: 5px;margin:5px;border-radius: 5px;display: flex; justify-content: center; align-items: center; flex-direction: column;width:100%"
              >
                <a :href="getFileUrl(content.value)" target="_blank">
                  🔗 {{ $t('FLOW.FILE') }}
                </a>

                <br />

                <!-- <input
                  v-model="content.title"
                  class="w3-input"
                  type="text"
                  style="margin:0"
                  :placeholder="$t('FLOW.PLACEHOLDERS.FILE_TITLE')"
                /> -->
              </div>
              <label style="width:100%" class="new-label-text">
                {{ $t('FLOW.PLACEHOLDERS.FILE_LABEL') }}

                <input
                  v-model="content.title"
                  class="new-input"
                  type="text"
                  style="margin:0"
                  :placeholder="$t('FLOW.PLACEHOLDERS.THIS_IS')"
                />
              </label>

              <div class="delete" @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <!-- file contents start -->

            <!-- catalog start -->
            <div v-if="content.type == 'catalog'" class="content-catalog">
              <div class="form">
                <label style="width:100%" class="catalog-label-text">
                  {{ $t('FLOW.CATALOG_HEADER') }}
                  <input
                    v-model="content.catalog_header"
                    class="new-input"
                    type="text"
                    style="margin:0"
                    :placeholder="$t('FLOW.PLACEHOLDERS.VALUE')"
                  />
                </label>

                <label style="width:100%" class="catalog-label-text">
                  {{ $t('FLOW.CATALOG_BODY') }}
                  <input
                    v-model="content.catalog_body"
                    class="new-input"
                    type="text"
                    style="margin:0"
                    :placeholder="$t('FLOW.PLACEHOLDERS.VALUE')"
                  />
                </label>

                <label style="width:100%" class="catalog-label-text">
                  {{ $t('FLOW.CATALOG_FOOTER') }}
                  <input
                    v-model="content.catalog_footer"
                    class="new-input"
                    type="text"
                    style="margin:0"
                    :placeholder="$t('FLOW.PLACEHOLDERS.VALUE')"
                  />
                </label>

                <label style="width:100%" class="catalog-label-text">
                  {{ $t('FLOW.CATALOG_TYPE_LABEL') }}
                  <select v-model="content.catalog_type">
                    <option
                      v-for="item in catalogTypes"
                      :key="item.id"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
                <div v-if="brandProfiles.length > 0" style="width:100%">
                  <label class="catalog-label-text">
                    {{ $t('FLOW.SELECT_BRAND_PROFILE') }}
                    <select v-model="content.brand_profile">
                      <option
                        v-for="item in brandProfiles"
                        :key="item.api_key"
                        :value="item"
                      >
                        {{ item.brand_name }}
                      </option>
                    </select>
                  </label>
                </div>
                <div v-else>{{ $t('FLOW.NO_BRAND_PROFILE') }}</div>
              </div>
              <div class="delete" @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>
            <!-- catalog end -->

            <!-- image contents start -->
            <div v-if="content.type == 'image'" class="content-image-input">
              <div
                v-if="content.value == ''"
                style="    display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;"
              >
                <woot-loading-state
                  v-if="content.isUploading"
                  :message="$t('FLOW.UPLOADING')"
                />
                <div
                  v-if="!content.isUploading"
                  style="display: flex;flex-direction: column;align-items: center;justify-content: center;width:100%"
                >
                  <div class="upload-btn-wrapper">
                    <button class="btn">
                      {{ $t('FLOW.UPLOAD_IMAGE') }}
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      accept="image/png, image/jpeg, image/gif"
                      @change="handleFileUpload($event, index)"
                    />
                  </div>

                  <div>
                    {{ $t('FLOW.OR') }}
                  </div>

                  <input
                    v-model="content.url"
                    class="w3-input"
                    type="text"
                    style="margin:0"
                    :placeholder="$t('FLOW.PLACEHOLDERS.PASTE_IMAGE_URL')"
                  />
                </div>

                <div v-if="!content.isUploading" style="margin-top: 12px;">
                  <woot-button
                    :disabled="content.url.trim().length == 0"
                    @click.prevent="uploadFromURL(index, content.url)"
                  >
                    {{ $t('FLOW.UPLOAD') }}
                  </woot-button>
                </div>
              </div>

              <div
                v-else
                style="padding: 5px;margin:5px;border-radius: 5px;display: flex; justify-content: center; align-items: center; flex-direction: column;width:100%"
              >
                <img
                  :src="getFileUrl(content.value)"
                  style="object-fit:contain; max-height:120px ;"
                />

                <br />
              </div>
              <label style="width:100%" class="new-label-text">
                {{ $t('FLOW.PLACEHOLDERS.IMAGE_LABEL') }}

                <input
                  v-model="content.title"
                  class="new-input"
                  type="text"
                  style="margin:0"
                  :placeholder="$t('FLOW.PLACEHOLDERS.THIS_IS')"
                />
              </label>

              <div class="delete" @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <!-- image contents start -->

            <!-- video contents start -->
            <div v-if="content.type == 'video'" class="content-image-input">
              <div
                v-if="content.value == ''"
                style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;"
              >
                <woot-loading-state
                  v-if="content.isUploading"
                  :message="$t('FLOW.UPLOADING')"
                />

                <div
                  v-if="!content.isUploading"
                  style="display:flex; flex-direction:column; align-items:center; justify-content:center
                "
                >
                  <div class="upload-btn-wrapper">
                    <button class="btn">
                      {{ $t('FLOW.UPLOAD_VIDEO') }}
                    </button>

                    <input
                      type="file"
                      name="myfile"
                      accept="video/mp4,video/x-m4v,video/*"
                      @change="handleFileUpload($event, index)"
                    />
                  </div>

                  <div>
                    {{ $t('FLOW.OR') }}
                  </div>

                  <input
                    v-model="content.url"
                    class="w3-input"
                    type="text"
                    style="margin:0"
                    :placeholder="$t('FLOW.PLACEHOLDERS.PASTE_VIDEO_URL')"
                  />
                </div>

                <div v-if="!content.isUploading" style="margin-top: 12px;">
                  <woot-button
                    :disabled="content.url.trim().length == 0"
                    @click.prevent="uploadFromURL(index, content.url)"
                  >
                    {{ $t('FLOW.UPLOAD') }}
                  </woot-button>
                </div>
              </div>

              <div
                v-else
                style="padding: 5px;margin:5px;border-radius: 5px;display: flex; justify-content: center; align-items: center; flex-direction: column;width:100%"
              >
                <video width="400" controls style="border-radius:15px">
                  <source :src="getFileUrl(content.value)" type="video/mp4" />
                </video>

                <br />
              </div>

              <label style="width:100%" class="new-label-text">
                {{ $t('FLOW.PLACEHOLDERS.VIDEO_LABEL') }}

                <input
                  v-model="content.title"
                  class="new-input"
                  type="text"
                  style="margin:0"
                  :placeholder="$t('FLOW.PLACEHOLDERS.THIS_IS')"
                />
              </label>
              <div class="delete" @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <!-- video contents start -->
            <!-- text contents start -->

            <div
              v-if="content.type == 'text'"
              style="display:flex;margin-bottom: 1.6em;
              padding:5px;background-color: white;
              "
            >
              <div></div>

              <textarea
                v-model="content.value"
                class="w3-input"
                type="text"
                style="margin:0;background-color: transparent;border:none;overflow: hidden;"
                :placeholder="$t('FLOW.PLACEHOLDERS.ENTER_MESSAGE_HERE')"
                @input="resizeTextArea"
                @focus="resizeTextArea"
              >
              </textarea>

              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <!-- text contents end -->

            <!-- CAROUSEL START -->

            <div
              v-if="content.type == 'carousel'"
              style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.6em;
                    place-content: center;
                    background-color: white;
                    min-height: 100px;
                    position: relative;
                    border-radius: 10px;
                    padding: 12px;
                    border: 1px dashed lightgrey;
                    justify-content: center;
                    flex-direction: column;
              "
            >
              <div v-for="(subCard, subIndex) in content.cards" :key="subIndex">
                <div
                  v-if="subCard.type == 'card'"
                  style=" display: flex;
                          margin-bottom: 1.6em;
                          background-color:white;
                          font-size: 14px;
                          padding: 12px;
                          border-radius: 12px;
                          color: black;
                          font-weight: 400;
                          border: solid 1px #f1f5f8;
                          align-items: center;
                          justify-content: space-between;
                          width:400px
              "
                >
                  <span v-if="subCard.title" style="color:black">
                    {{ 'Card | ' + subCard.title }}
                  </span>

                  <span v-else style="color:grey">
                    {{ $t('FLOW.CARD_EDIT_CLICK_GUIDE_TEXT') }}
                  </span>

                  <div style="display:flex">
                    <woot-button
                      size="small"
                      variant="smooth"
                      color-scheme="secondary"
                      icon="edit"
                      style="margin-right: 5px;"
                      @click="editCard(subCard)"
                    />
                    <woot-button
                      size="small"
                      variant="smooth"
                      color-scheme="alert"
                      icon="dismiss"
                      @click="deleteCarouselItem(content.cards, subIndex)"
                    />
                  </div>
                </div>
              </div>

              <div
                style="margin-right: 5px; display: flex; align-items: center;justify-content: center;"
              >
                <div class="custom-btn-wrapper">
                  <button class="btn" @click="addNewCard(content.cards)">
                    <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                    {{ $t('FLOW.CARD') }}
                  </button>
                </div>
              </div>

              <div @click="deleteItem(index)">
                <FluentIcon
                  icon="dismiss"
                  style="position: absolute; right:5px; top: 5px;"
                />
              </div>
            </div>

            <!-- CAROUSEL END -->

            <!-- CARD START -->

            <div
              v-if="content.type == 'card'"
              style=" display: flex;
              margin-bottom: 1.6em;
              background-color: white;
              font-size: 14px;
              padding: 12px;
              border-radius: 12px;
              color: black;
              font-weight: 400;
              border: solid 1px #f1f5f8;
              align-items: center;
              justify-content: space-between;"
            >
              <span v-if="content.title" style="color:black">
                {{ 'Card | ' + content.title }}
              </span>

              <span v-else style="color:grey">
                {{ $t('FLOW.CARD_EDIT_CLICK_GUIDE_TEXT') }}
              </span>

              <div style="display:flex">
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary"
                  icon="edit"
                  style="margin-right: 5px;"
                  @click="editCard(content)"
                />
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="dismiss"
                  @click="deleteItem(index)"
                />
              </div>
            </div>

            <!-- CARD END -->

            <!-- LIST START -->
            <div v-if="content.type == 'list'" class="card_node_item">
              <span v-if="content.title" style="color:black">
                {{ 'List | ' + content.title }}
              </span>
              <span v-else style="color:grey">
                {{ $t('FLOW.LIST_EDIT_CLICK_GUIDE_TEXT') }}
              </span>
              <div style="display:flex">
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary"
                  icon="edit"
                  style="margin-right: 5px;"
                  @click="editList(content)"
                />
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="dismiss"
                  @click="deleteItem(index)"
                />
              </div>
            </div>
            <!-- LIST END -->

            <!-- CATEGORY LIST START -->
            <div v-if="content.type == 'category_list'" class="card_node_item">
              <span v-if="content.title" style="color:black">
                {{ 'Category List | ' + content.title }}
              </span>
              <span v-else style="color:grey">
                {{ $t('FLOW.LIST_EDIT_CLICK_GUIDE_TEXT') }}
              </span>
              <div style="display:flex">
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary"
                  icon="edit"
                  style="margin-right: 5px;"
                  @click="editCategoryList(content)"
                />
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="dismiss"
                  @click="deleteItem(index)"
                />
              </div>
            </div>
            <!-- CATEGORY LIST END -->

            <div
              v-if="content.type == 'condition-group'"
              style="
                display: flex;
                align-items: center;
                margin-bottom: 1.6em;
                place-content: center;
                background-color: #f8fafc;
                min-height: 100px;
                position: relative;
                border-radius: 10px;
                padding: 12px;
                border: 1px dashed lightgrey;
                justify-content: center;
              "
            >
              <div>
                <div
                  v-for="(subContent, subIndex) in content.contents"
                  :key="subIndex"
                >
                  <div
                    v-if="subContent.type == 'condition-op-logical'"
                    class="filter__join-operator"
                  >
                    <hr class="operator__line" />

                    <select
                      v-model="subContent.logicalOperator"
                      class="operator__select"
                    >
                      <option
                        v-for="item in logicalOperators"
                        :key="item.id"
                        :value="item"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>

                  <condition-view
                    v-else-if="subContent.type.startsWith('condition')"
                    :content="subContent"
                    :index="subIndex"
                    :group="content.contents"
                    @delete-condition="deleteGeneral"
                  >
                  </condition-view>
                </div>

                <div
                  style="display:flex; align-items: center;justify-content: center;"
                >
                  <div style="margin-right: 5px; display: flex; ">
                    <div class="custom-btn-wrapper">
                      <button
                        class="btn"
                        @click="addNewCondition(content.contents)"
                      >
                        <img
                          src="~dashboard/assets/images/flow-new/btn-add.png"
                        />

                        {{ $t('FLOW.SUB_CONDITION') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div @click="deleteConditionItem(index, content.condId)">
                <FluentIcon
                  icon="dismiss"
                  style="position: absolute; right:5px; top: 5px;"
                />
              </div>
            </div>

            <condition-view
              v-else-if="
                content.type ? content.type.startsWith('condition') : false
              "
              :content="content"
              :index="index"
              :cond-id="content.condId"
              @delete-condition="deleteGeneral"
            >
            </condition-view>

            <!----------------------------------------------------------------------------CONDITIONS END----------------------------------------------------------------------------------------->

            <!-- action-default contents start -->

            <span
              v-if="content.type == 'action-setVariable'"
              style="color:grey;font-size:14px;font-weight:300;"
            >
              {{ $t('FLOW.SET_VARIABLE_DASHED') }}
            </span>

            <span
              v-if="content.type == 'action-setAPIVariable'"
              style="color:grey;font-size:14px;font-weight:300;"
            >
              {{ $t('FLOW.SET_API_VARIABLE_DASHED') }}
            </span>

            <div
              v-if="content.type == 'action-setAPIVariable'"
              style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
            >
              <select v-model="content.valueType" class="filter__question">
                <option
                  v-for="item in variableValues"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <input
                v-if="content.valueType.id == 'custom'"
                v-model="content.value"
                class="answer--text-input"
                type="text"
                style="margin:0"
                :placeholder="$t('FLOW.PLACEHOLDERS.VARIABLE_VALUE')"
              />

              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>
            <div
              v-if="content.type == 'action-setVariable'"
              style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
            >
              <input
                v-model="content.name"
                class="answer--text-input"
                type="text"
                style="margin:0; margin-right:5px"
                :placeholder="$t('FLOW.PLACEHOLDERS.VARIABLE_NAME')"
              />

              <select v-model="content.valueType" class="filter__question">
                <option
                  v-for="item in variableValues"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <input
                v-if="content.valueType.id == 'custom'"
                v-model="content.value"
                class="answer--text-input"
                type="text"
                style="margin:0"
                :placeholder="$t('FLOW.PLACEHOLDERS.VARIABLE_VALUE')"
              />

              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <span
              v-if="content.type == 'action-label'"
              style="color:grey;font-size:14px;font-weight:300;"
            >
              {{ $t('FLOW.LABEL_ACTION') }}
            </span>

            <div
              v-if="content.type == 'action-label'"
              style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
            >
              <select v-model="content.action" class="filter__question">
                <option
                  v-for="item in labelActions"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <input
                v-model="content.name"
                class="answer--text-input"
                type="text"
                style="margin:0; margin-right:5px"
                :placeholder="$t('FLOW.PLACEHOLDERS.LABEL_NAME')"
              />
              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <span
              v-if="content.type == 'action-contact'"
              style="color:grey;font-size:14px;font-weight:300;"
            >
              {{ $t('FLOW.SET_CONTACT_ATTRIBUTE') }}
            </span>

            <div
              v-if="content.type == 'action-contact'"
              style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
            >
              <select v-model="content.attrType" class="filter__question">
                <option v-for="item in attrTypes" :key="item.id" :value="item">
                  {{ item.name }}
                </option>
              </select>

              <select
                v-if="content.attrType.id == 'standard'"
                v-model="content.attr"
                class="filter__question"
              >
                <option
                  v-for="item in contactAttributes"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <input
                v-if="content.attrType.id == 'custom'"
                v-model="content.customAttr"
                class="answer--text-input"
                type="text"
                style="margin:0;margin-right: 5px;"
                :placeholder="$t('FLOW.PLACEHOLDERS.CUSTOM_ATTRIBUTE')"
              />

              <select v-model="content.value" class="filter__question">
                <option
                  v-for="item in contactAttributesValues"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <input
                v-if="content.value.id == 'custom'"
                v-model="content.custom"
                class="answer--text-input"
                type="text"
                style="margin:0; margin-right:5px"
                :placeholder="$t('FLOW.PLACEHOLDERS.CUSTOM_VALUE')"
              />

              <input
                v-if="content.value.id == 'variable'"
                v-model="content.name"
                class="answer--text-input"
                type="text"
                style="margin:0; margin-right:5px"
                :placeholder="$t('FLOW.PLACEHOLDERS.VARIABLE_NAME')"
              />
              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <span
              v-if="content.type == 'action-status'"
              style="color:grey;font-size:14px;font-weight:300;"
            >
              {{ $t('FLOW.TOGGLE_CONVERSATION_STATUS') }}
            </span>

            <div
              v-if="content.type == 'action-status'"
              style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
            >
              <select v-model="content.value" class="filter__question">
                <option
                  v-for="item in conversationStatuses"
                  :key="item.id"
                  :value="item"
                >
                  {{ item.name }}
                </option>
              </select>

              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <div
              v-if="content.type == 'action-jsEval'"
              style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
            >
              <input
                v-model="content.value"
                class="answer--text-input"
                type="text"
                style="margin:0; margin-right:5px"
                :placeholder="$t('FLOW.PLACEHOLDERS.JSEVAL_VALUE')"
              />

              <div @click="deleteItem(index)">
                <FluentIcon icon="dismiss" />
              </div>
            </div>

            <!-- action-default contents end -->
          </div>
        </div>

        <div
          v-if="node.data.type == 'content'"
          style="display: flex;margin-bottom: 24px;margin-top:15px"
        >
          <div>
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewText">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.TEXT') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewImage">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.IMAGE') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewFile">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.FILE') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewVideo">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.VIDEO') }}
              </button>
            </div>
          </div>
          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewCard(false)">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.CARD') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewCarousel">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.CAROUSEL') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewList">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.LIST') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewCategories">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.CATEGORIES') }}
              </button>
            </div>
          </div>

          <div style="margin-left:5px" v-if="brandProfiles.length > 0">
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewCatalog">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.CATALOG') }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="node.data.type == 'condition'"
          style="display: flex;margin-bottom: 24px;"
        >
          <div style="display:flex">
            <div style="margin-right: 3px;">
              <div style="margin-right: 5px; display: flex; ">
                <div class="custom-btn-wrapper">
                  <button class="btn" @click="addNewCondition()">
                    <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                    {{ $t('FLOW.CONDITION') }}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div class="custom-btn-wrapper">
                <button class="btn" @click="addNewGroupCondition()">
                  <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                  {{ $t('FLOW.GROUP') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="node.data.type == 'action'"
          style="display: flex;margin-bottom: 24px;margin-top: 15px;"
        >
          <div>
            <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewActionSetVariable">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.SET_VARIABLE') }}
              </button>
            </div>

            <div class="custom-btn-wrapper" style="margin-left:5px">
              <button class="btn" @click="addNewActionContact">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.CONTACT') }}
              </button>
            </div>

            <div class="custom-btn-wrapper" style="margin-left:5px">
              <button class="btn" @click="addNewActionLabel">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.LABEL') }}
              </button>
            </div>

            <div class="custom-btn-wrapper" style="margin-left:5px">
              <button class="btn" @click="addNewActionStatus">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.STATUS') }}
              </button>
            </div>
            <div class="custom-btn-wrapper" style="margin-left:5px">
              <button class="btn" @click="addNewActionjsEval">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.JSEVAL') }}
              </button>
            </div>
            <!-- <div class="custom-btn-wrapper">
              <button class="btn" @click="addNewActionSetAPIVariable">
                <img src="~dashboard/assets/images/flow-new/btn-add.png" />

                {{ $t('FLOW.SET_API_VARIABLE') }}
              </button>
            </div> -->
          </div>
        </div>

        <label
          v-if="this.node.data.type == 'action'"
          class="custom-checkbox-container"
        >
          {{ $t('FLOW.END_FLOW') }}
          <input
            id="endFlow"
            v-model="endFlowAction"
            type="checkbox"
            @change="onUpdateEndFlow"
          />
          <span class="checkmark"></span>
        </label>
      </div>

      <!-- <div class="modal-footer">
        <woot-button :disabled="!allGood" @click.prevent="allDone">
          {{ $t('FLOW.DONE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div> -->
    </form>
    <woot-modal
      :show.sync="showAddPopup"
      :on-close="hideAddPopup"
      custom-class="height"
    >
      <edit-card
        :card="this.currentCard"
        :dref="this.dref"
        :botid="this.botid"
        @on-close="hideAddPopup"
        @card-edit-done="cardEditDone"
      />
    </woot-modal>
    <woot-modal
      :show.sync="showEditListPopup"
      :on-close="hideEditListPopup"
      custom-class="height"
    >
      <EditList
        :list="this.currentList"
        :dref="this.dref"
        :botid="this.botid"
        @on-close="hideEditListPopup"
        @list-edit-done="listEditDone"
      />
    </woot-modal>
    <woot-modal
      :show.sync="showEditCategoryListPopup"
      :on-close="hideEditCategoryListPopup"
      custom-class="height"
    >
      <EditListCategory
        :list="this.currentCategoryList"
        :dref="this.dref"
        :botid="this.botid"
        @on-close="hideEditCategoryListPopup"
        @list-edit-done="categorylistEditDone"
      />
    </woot-modal>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';
import FlowApi from '../../FlowApi';
import Helper from '../../Helper';
import { Node } from '../../vue-diagram-editor/src';
import EditCard from './EditCard.vue';
import EditList from './EditList.vue';
import EditListCategory from './EditListCategory.vue';
import ConditionView from '../components/ConditionView.vue';
import CallApiView from '../components/CallAPIView.vue';
import PMConnectAPI from '../../PMConnectAPI';

export default {
  components: {
    FluentIcon,
    EditCard,
    EditList,
    EditListCategory,
    ConditionView,
    CallApiView,
  },
  mixins: [alertMixin],
  props: {
    node: {
      type: Node,
      required: true,
    },
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    profiles: {
      required: true,
    },
    isCombine: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      title: '',
      show: true,
      enabled: true,
      contents: this.node,
      tempText: '',
      tempText2: '',
      outPorts: this.node.portsOut,
      waitForUserMessage: this.node?.data?.waitForUserMessage,
      currentSelection: 'user',
      showAddPopup: false,
      currentCard: null,
      showEditListPopup: false,
      currentList: null,
      showEditCategoryListPopup: false,
      currentCategoryList: null,
      endFlowAction: this.node.data.endFlowAction,
      allGood: true,
    };
  },

  computed: {
    attrTypes() {
      return [
        { id: 'standard', name: 'Standard' },
        { id: 'custom', name: 'Custom' },
      ];
    },

    contactAttributes() {
      return [
        { id: 'name', name: 'Name' },
        { id: 'email', name: 'Email' },
        { id: 'phone_number', name: 'Phone Number' },
        { id: 'identifier', name: 'Identifier' },
        { id: 'status_id', name: 'Status ID' },
      ];
    },

    logicalOperators() {
      return [
        { id: 'or', name: 'OR' },
        { id: 'and', name: 'AND' },
      ];
    },

    contactAttributesValues() {
      return [
        { id: 'response', name: 'Response' },
        { id: 'variable', name: 'Variable' },
        { id: 'custom', name: 'Custom' },
      ];
    },
    apiPropertyOptions() {
      return [
        { id: 'pty-body', name: 'Body' },
        { id: 'pty-headers', name: 'Headers' },
      ];
    },

    variableValues() {
      return [
        { id: 'response', name: 'Response' },
        { id: 'custom', name: 'Custom' },
      ];
    },

    conditionTypes() {
      return [
        { id: 'condition-response', name: 'Response' },
        { id: 'condition-label', name: 'Label' },
        { id: 'condition-variable', name: 'Variable' },
        { id: 'condition-contact', name: 'Contact' },
      ];
    },
    responseOperatorOptions() {
      return [
        { id: 'op-contains', name: 'Contains' },
        { id: 'op-does-not-contain', name: 'Does not contain' },
        { id: 'op-equal', name: 'Equal' },
        { id: 'op-not-equal', name: 'Not Equal' },
        { id: 'op-gt', name: 'Greater than >' },
        { id: 'op-lt', name: 'Less than <' },
        { id: 'op-gte', name: 'Greater than or Equal >=' },
        { id: 'op-lte', name: 'Less than or Equal <=' },
      ];
    },

    operatorOptionsVariant() {
      return [
        { id: 'op-equal', name: 'Equal' },
        { id: 'op-not-equal', name: 'Not Equal' },
        { id: 'op-contains', name: 'Contains' },
        { id: 'op-does-not-contain', name: 'Does not contain' },
        { id: 'op-gt', name: 'Greater than>' },
        { id: 'op-lt', name: 'Less than <' },
        { id: 'op-gte', name: 'Greater than or Equal >=' },
        { id: 'op-lte', name: 'Less than or Equal <=' },
      ];
    },

    labelActions() {
      return [
        { id: 'label-add', name: 'Add Label' },
        { id: 'label-remove', name: 'Remove Label' },
      ];
    },

    conversationStatuses() {
      return [
        { id: 'open', name: 'Open' },
        { id: 'resolved', name: 'Resolved' },
        { id: 'pending', name: 'Pending' },
      ];
    },

    operandBOptions() {
      return [
        { id: 'op-text', name: 'Text' },
        { id: 'op-variable', name: 'Variable' },
      ];
    },

    operandBOptionsAlt() {
      return [
        { id: 'op-text', name: 'Text' },
        { id: 'op-custom', name: 'Custom' },
      ];
    },

    conditionOperands() {
      return [
        { id: 'op-response', name: '👤 Response' },
        { id: 'op-label', name: 'Label' },
      ];
    },
    catalogTypes() {
      return [
        { id: 'catalog-search', name: 'Search' },
        { id: 'catalog-recommendation', name: 'Recommendation' },
        { id: 'catalog-past-order', name: 'Past Order' },
      ];
    },

    brandProfiles() {
      console.log('props profiles', this.profiles);
      return this.profiles;
    },
  },
  mounted() {
    this.contents = this.node.data.contents;
    console.log(this.contents,"MOunt contents");

    this.$nextTick(() => {
      [...document.querySelectorAll('textarea')].forEach(textarea => {
        this.resizeTextArea({ target: textarea });
      });
    });
  },

  methods: {
    resizeTextArea(e) {
      let element = e.target;
      element.style.height = '18px';
      element.style.height = element.scrollHeight + 'px';
    },

    editCard(card) {
      this.currentCard = card;
      this.showAddPopup = true;
    },
    editList(list) {
      console.log(list);
      this.currentList = list;
      this.showEditListPopup = true;
    },
    editCategoryList(categoryList) {
      console.log(categoryList);
      this.currentCategoryList = categoryList;
      this.showEditCategoryListPopup = true;
    },
    onUpdate() {
      let prevData = {};

      prevData = Object.assign(prevData, this.node.data);

      prevData.waitForUserMessage = this.waitForUserMessage;
      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'data',
          value: prevData,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'data',
          value: prevData,
        });
      }
    },

    onUpdateEndFlow() {
      let prevData = {};

      prevData = Object.assign(prevData, this.node.data);

      prevData.endFlowAction = this.endFlowAction;

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'data',
          value: prevData,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'data',
          value: prevData,
        });
      }

    },

    onUpdateListValue(value, item, type) {
      item[type] = value;
    },

    getFileUrl(imageName) {
      return FlowApi.getImgUrl(imageName, this.botid);
    },
    handleInputChange(event) {
      this.tempText = event.target.value;
    },

    async handleFileUpload(event, index) {
      const file = event.target.files[0];

      this.contents[index].isUploading = true;

      let uploadRes = await FlowApi.uploadFile(this.botid, file);

      if (uploadRes.status === 'ok') {
        this.contents[index].value = uploadRes.data.name;
      } else {
        this.showAlert(uploadRes.message);
      }

      this.contents[index].isUploading = false;
    },
    uploadFile(index) {
      this.contents[index].value = this.tempText;
      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },
    async uploadFromURL(index, url) {
      if (typeof url === 'string' && url.trim().length === 0) {
        return;
      }
      this.contents[index].isUploading = true;

      let uploadRes = await FlowApi.copyUpload(this.botid, url);

      if (uploadRes.status === 'ok') {
        this.contents[index].value = uploadRes.data.name;
      } else {
        this.showAlert(uploadRes.message);
      }

      this.contents[index].isUploading = false;
    },

    deleteCarouselItem(carousel, index) {
      carousel.splice(index, 1);
    },
    deleteGroupContent(group, index) {
      if (index === 0) {
        if (group.length > 1) {
          group.splice(index, 2);
        } else {
          group.splice(index, 1);
        }
      } else {
        index -= 1;
        group.splice(index, 2);
      }
    },
    deleteItem(index) {
      this.contents.splice(index, 1);
    },
    deleteGeneral(group, index, condId) {
      if (group) {
        this.deleteGroupContent(group, index);
      } else {
        this.deleteConditionItem(index, condId);
      }
    },
    deleteConditionItem(index, condId) {
      console.log('delete condtion content', index, condId);
      this.contents.splice(index, 1);

      this.dref.linkCleanUp(this.node.id, condId);
      delete this.outPorts[condId];

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'portsOut',
          value: this.outPorts,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'portsOut',
          value: this.outPorts,
        });
      }
    },
    addNewText() {
      this.contents.push({
        value: '',
        type: 'text',
      });
      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }

    },

    addNewCarousel() {
      this.contents.push({
        type: 'carousel',
        cards: [],
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    async addNewCatalog() {
      this.contents.push({
        type: 'catalog',
        catalog_type: this.catalogTypes[0],
        catalog_header: '',
        catalog_body: '',
        catalog_footer: '',
        brand_profile: null,
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    addNewCard(carousel = false) {
      if (carousel) {
        carousel.push({
          value: '',
          type: 'card',
          title: '',
          subtitle: '',
          file: '',
          actionButtons: [],
        });
        if (this.isCombine) {
          this.dref.updateCombineNode({
            id: 'combine-' + this.node.id.split('-')[1],
            subNodeId: this.node.id,
            name: 'contents',
            value: this.contents,
          });
        } else {
          this.dref.updateNode({
            id: this.node.id,
            name: 'contents',
            value: this.contents,
          });
        }
      } else {
        this.contents.push({
          value: '',
          type: 'card',
          title: '',
          subtitle: '',
          file: '',
          actionButtons: [],
        });

        if (this.isCombine) {
          this.dref.updateCombineNode({
            id: 'combine-' + this.node.id.split('-')[1],
            subNodeId: this.node.id,
            name: 'contents',
            value: this.contents,
          });
        } else {
          this.dref.updateNode({
            id: this.node.id,
            name: 'contents',
            value: this.contents,
          });
        }
      }
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    cardEditDone() {
      console.log('CARD EDIT DONE', this.currentCard);
      this.showAddPopup = false;
    },
    listEditDone() {
      console.log('List EDIT DONE', this.currentList);
      this.showEditListPopup = false;
    },
    hideEditListPopup() {
      this.showEditListPopup = false;
    },
    hideEditCategoryListPopup() {
      this.showEditCategoryListPopup = false;
    },
    categorylistEditDone() {
      console.log('Category List EDIT DONE', this.currentCategoryList);
      this.hideEditCategoryListPopup();
    },

    addNewVideo() {
      this.contents.push({
        value: '',
        type: 'video',
        isUploading: false,
        url: '',
        title: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },
    addNewImage() {
      this.contents.push({
        value: '',
        type: 'image',
        title: '',
        isUploading: false,
        url: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    addNewFile() {
      this.contents.push({
        value: '',
        type: 'file',
        title: '',
        isUploading: false,
        url: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },
    addNewList() {
      this.contents.push({
        // value: '',
        type: 'list',
        title: '',
        body: '',
        footer: '',
        button: '',
        action:{
          sections:[
            {
              title:"",
              rows: [
                {id:"",title:"list 1",description:"description 1"}
              ]
            },
          ],
        },
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }

      console.log(this.contents);
    },
    addNewCategories() {
      console.log("clicked addNewCategories");

      this.contents.push({
        // value: '',
        type: 'category_list',
        title: '',
        body: '',
        footer: '',
        button: '',
        action:{
          sections:[
            {
              title:"",
              rows: [
                // {id:"",title:"list 1",description:"description 1"}
              ]
            },
          ],
        },
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }

    },

    addNewGroupCondition() {
      var result = Helper.generateRandomString();
      console.log('check group ', this.outPorts);

      let temp = 'condition-outport-' + result;

      this.contents.push({
        type: 'condition-group',
        contents: [],
        condId: temp,
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
      let allPorts = Object.keys(this.outPorts);

      let otherwisePortId = null;
      allPorts.some(function(element) {
        if (element.includes('condition-otherwise')) {
          otherwisePortId = element;
          return element;
        }
        return false;
      });

      delete this.outPorts[otherwisePortId];

      this.outPorts[temp] = '';

      console.log('temp', this.outPorts);

      this.outPorts[otherwisePortId] = '';

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'portsOut',
          value: this.outPorts,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'portsOut',
          value: this.outPorts,
        });
      }
    },
    endFlow() {
      this.contents.push({
        value: '',
        type: 'action-endFlow',
        name: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },
    addNewActionSetVariable() {
      this.contents.push({
        value: '',
        type: 'action-setVariable',
        name: '',
        valueType: this.variableValues[0],
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },
    addNewActionSetAPIVariable() {
      this.contents.push({
        value: '',
        type: 'action-setAPIVariable',
        name: '',
        valueType: this.variableValues[0],
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    addNewActionLabel() {
      this.contents.push({
        type: 'action-label',
        action: this.labelActions[0],
        name: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    addNewActionContact() {
      this.contents.push({
        type: 'action-contact',
        attr: this.contactAttributes[0],
        value: this.contactAttributesValues[0],
        attrType: this.attrTypes[0],
        name: '',
        customAttr: '',
        custom: '',
        paramName: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    addNewActionStatus() {
      this.contents.push({
        type: 'action-status',
        value: this.conversationStatuses[0],
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },
    addNewActionjsEval() {
      this.contents.push({
        type: 'action-jsEval',
        value: '',
      });

      if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }
    },

    addNewLogicalOperator() {
      var result = Helper.generateRandomString();

      let temp = 'condition-outport-' + result;

      this.contents.push({
        value: '',
        type: 'condition-op-logical',
        logicalOperator: { id: 'and', name: 'AND' },

        condId: temp,
      });
    },

    addNewCondition(group = false) {
      let holder = this.contents;
      if (group) {
        holder = group;
        if (holder.length > 0) {
          holder.push({
            value: '',
            type: 'condition-op-logical',
            logicalOperator: { id: 'and', name: 'AND' },
          });
        }
      }
      let result = Helper.generateRandomString();
      let temp = 'condition-outport-' + result;

      holder.push({
        type: this.conditionTypes[0].id,
        operator: this.responseOperatorOptions[0],
        operandB: this.operandBOptions[0],
        attrType: this.attrTypes[0],
        attr: this.contactAttributes[0],
        value: '',
        name: '',
        condId: temp,
        customAttr: '',
        paramName: '',
        propertyName: '',
      });

      if (!group) {
        if (this.isCombine) {
        this.dref.updateCombineNode({
          id: 'combine-' + this.node.id.split('-')[1],
          subNodeId: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      } else {
        this.dref.updateNode({
          id: this.node.id,
          name: 'contents',
          value: this.contents,
        });
      }

        let allPorts = Object.keys(this.outPorts);
        let otherwisePortId = null;
        allPorts.some(function(element) {
          if (element.includes('condition-otherwise')) {
            otherwisePortId = element;
            return element;
          }
          return false;
        });

        delete this.outPorts[otherwisePortId];

        this.outPorts[temp] = '';

        this.outPorts[otherwisePortId] = '';

        if (this.isCombine) {
          this.dref.updateCombineNode({
            id: 'combine-' + this.node.id.split('-')[1],
            subNodeId: this.node.id,
            name: 'contents',
            value: this.contents,
          });
        } else {
          this.dref.updateNode({
            id: this.node.id,
            name: 'contents',
            value: this.contents,
          });
        }

        // this.addOtherwise();
      }
    },

    allDone() {
      this.$emit('all-done', this.node);
    },
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.inputTitle {
  border: none;
  font-size: 24px;
}
.inputTitle:focus {
  outline: none;
  border: 1px solid #995eea;
  background-color: #fff;
  box-shadow: 0;
  transition: border-color 0.25s ease-in-out;
  font-size: 16px;
}
.bottom-margin {
  margin-bottom: var(--space-small) !important;
}
</style>

<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  margin-bottom: 15px;
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 2px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }
}

.card_node_item{
  display: flex;
  margin-bottom: 1.6em;
  background-color: white;
  font-size: 14px;
  padding: 12px;
  border-radius: 12px;
  color: black;
  font-weight: 400;
  border: solid 1px #f1f5f8;
  align-items: center;
  justify-content: space-between;
}
</style>

