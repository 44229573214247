var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t(
                "ORDER.DETAILS.ADD_ITEM_MODAL.HEADER.TITLE"
              ),
              "header-content": _vm.$t(
                "ORDER.DETAILS.ADD_ITEM_MODAL.HEADER.CONTENT"
              )
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "modal_body" }, [
        _c("div", [
          _c("label", [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("ORDER.DETAILS.ADD_ITEM_MODAL.SEARCH_LABEL"))
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchText,
                  expression: "searchText"
                }
              ],
              attrs: { type: "search", placeholder: "eg, Biscuit" },
              domProps: { value: _vm.searchText },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchVariantHandler($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchText = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("label", [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("ORDER.DETAILS.ADD_ITEM_MODAL.CATEGORY_LABEL"))
                )
              ])
            ]),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                options: _vm.categories,
                "track-by": "id",
                label: "title",
                multiple: true,
                "close-on-select": false,
                "clear-on-select": false,
                "hide-selected": true,
                "tag-placeholder": _vm.$t(
                  "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES"
                ),
                taggable: false,
                placeholder: _vm.$t(
                  "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER"
                ),
                "selected-label": "",
                "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
              },
              on: {
                select: function($event) {
                  return _vm.addFilterByCategories($event, _vm.option)
                },
                remove: function($event) {
                  return _vm.removeFilterByCategories($event)
                }
              },
              model: {
                value: _vm.selectedCategories,
                callback: function($$v) {
                  _vm.selectedCategories = $$v
                },
                expression: "selectedCategories"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "cards" },
              _vm._l(_vm.filteredVariants, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "card",
                    class: { active_card: _vm.activeCardClass(item) },
                    on: {
                      click: function($event) {
                        return _vm.onClickCard(item)
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c("div", [
                        _c("img", {
                          staticClass: "card_img",
                          attrs: { src: item.image_link, alt: "" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card_info" }, [
                        _c("p", [_c("b", [_vm._v(_vm._s(item.item_name))])]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Price: " +
                              _vm._s(item.price) +
                              " " +
                              _vm._s(item.currency)
                          )
                        ])
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.isVariantLoading
              ? _c(
                  "div",
                  { staticClass: "loader_and_empty" },
                  [
                    _c("spinner"),
                    _vm._v(" "),
                    _c("span", [_vm._v("Loading items")])
                  ],
                  1
                )
              : !_vm.isVariantLoading && !_vm.filteredVariants.length
              ? _c("empty-state", { attrs: { title: "No item found!" } })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-submit-button", {
                attrs: { "button-text": "Add items" },
                on: { click: _vm.addItemsHandler }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT")) +
                      "\n          "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }