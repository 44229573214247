var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "switch", class: _vm.classObject }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value"
        }
      ],
      staticClass: "switch-input",
      attrs: {
        id: _vm.id,
        name: _vm.name,
        disabled: _vm.disabled,
        type: "checkbox"
      },
      domProps: {
        checked: Array.isArray(_vm.value)
          ? _vm._i(_vm.value, null) > -1
          : _vm.value
      },
      on: {
        change: function($event) {
          var $$a = _vm.value,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.value = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.value = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.value = $$c
          }
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "switch-paddle", attrs: { for: _vm.name } }, [
      _c("span", { staticClass: "show-for-sr" }, [_vm._v("on off")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }