import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=dbbf55c4&"
import script from "./Icon.vue?vue&type=script&lang=js&"
export * from "./Icon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dbbf55c4')) {
      api.createRecord('dbbf55c4', component.options)
    } else {
      api.reload('dbbf55c4', component.options)
    }
    module.hot.accept("./Icon.vue?vue&type=template&id=dbbf55c4&", function () {
      api.rerender('dbbf55c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/shared/components/FluentIcon/Icon.vue"
export default component.exports