<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px; padding-left: 24px; padding-right: 24px; color:grey; align-items: center;"
    >
      <h5>{{ $t('FLOW.EDIT_CATEGORY_LIST') }}</h5>
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">

         <woot-input
            v-model="list.title"
            :label="$t('FLOW.LIST_TITLE')"
            :error="$v.list.title.$error ? ($v.list.title.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.list.title.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            @blur="$v.list.title.$touch"
          />

         <woot-input
            v-model="list.body"
            :label="$t('FLOW.LIST_BODY')"
            :error="$v.list.body.$error ? ($v.list.body.$model.length < 1 ? 'Body must be at least 1 character.' : 'Body must be at most 4096 characters.') : ''"
            :class="{ error: $v.list.body.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BODY')"
            @blur="$v.list.body.$touch"
          />

        <woot-input
            v-model="list.footer"
            :label="$t('FLOW.LIST_FOOTER')"
            :error="$v.list.footer.$error ? ($v.list.footer.$model.length > 60 ? 'Footer must be at most 60 characters.' : '') : ''"
            :class="{ error: $v.list.footer.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.FOOTER')"
            @blur="$v.list.footer.$touch"
          />

          <woot-input
            v-model="list.button"
            :label="$t('FLOW.LIST_BTN_LEVEL')"
            :error="$v.list.button.$error ? ($v.list.button.$model.length < 1 ? 'Button label must be at least 1 character.' : 'Button label must be at most 20 characters.') : ''"
            :class="{ error: $v.list.button.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BTN_LEVEL')"
            @blur="$v.list.button.$touch"
          />

        <section>
          <ul style="margin:0; list-style:none;">
            <li v-for="(section,sectionIdx) in list.action.sections" class="section_item" :key="sectionIdx">
                <woot-input
                    v-model="section.title"
                    :label="'Section title'"
                    :error="$v.list.action.sections.$each[sectionIdx].title.$error ? 'Section title must be between 1 and 24 characters.' : ''"
                    :class="{ error: $v.list.action.sections.$each[sectionIdx].title.$error }"
                    type="text"
                    :placeholder="'Enter item title'"
                    @blur="$v.list.action.sections.$each[sectionIdx].title.$touch"
                />

                <div class="field_item">
                    <label style="width:100%">
                        Select Category
                        <multiselect
                            v-model="section.rows"
                            :options="validCategoriesOptions"
                            track-by="id"
                            label="title"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :hide-selected="true"
                            @tag="function(){}"
                            :tag-placeholder="$t('CATALOGUE.ADD_EDIT_FORM.CREATE_CTG_PLACEHOLDER')"
                            :taggable="false"
                            @remove="function(){}"
                            :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
                            selected-label
                            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                        />
                    </label>
                </div>

                <div class="action_btn">
                    <div @click="deleteSectionItem(sectionIdx)">
                    <FluentIcon icon="dismiss" />
                    </div>
                </div>
            </li>
          </ul>
          <div style="text-align:center; margin-top: 1em;">
            <woot-button
              v-tooltip.top-end="'Add New Section'"
              size="small"
              variant="smooth"
              color-scheme="secondary"
              icon="add"
              @click="addSection"
            />
          </div>
        </section>
      </div>


      <div class="modal-footer">
        <woot-button @click.prevent="allDone">
          {{ $t('FLOW.DONE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>

    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';
import DashboardIcon from '../../../../../../../shared/components/FluentIcon/DashboardIcon.vue';
import FlowApi from '../../FlowApi';
import { required, minValue, maxValue,  minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';


export default {
  components: {
    FluentIcon,
    DashboardIcon,
  },

  mixins: [alertMixin],
  props: {
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    list: {
      required: true,
    }
  },
  data() {
    return {
        selectedCategories: [],
        validCategoriesOptions: [],
    };
  },
  computed:{
    ...mapGetters({
    //   currentUser: 'getCurrentUser',
    //   uiFlags: "catalog/getUIFlags",
    //   meta: "catalog/getMeta",
      categories: "catalog/getCatagories",
      catalogueItems: "catalog/getCatalogItems",
    }),
    // categoriesOptions(){
    //     // await to get items and check valid categories
    //     // filter the llist which have at lease 1 item valid
    //     // in_stock, available_inventory > 0 from item
    //     console.log(this.categories,"44 5 44");
    //     return this.categories.map(el=>({id:el.id,title:el.title}));
    // },
  },
  mounted(){
    console.log("list props",this.list);
    this.$store.dispatch("catalog/getCatagories");
    this.$store.dispatch("catalog/getCatalogProductItems");
  },
  watch: {
    categories: {
        handler(newCategories) {
            this.formatCategories(newCategories);
        },
        immediate: true,
    },
    catalogueItems: {
        handler(newCatalogueItems) {
            this.formatCategories(this.categories);
        },
        immediate: true,
    },
},

  validations: {
    botid: {
      required,
    },
    list: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(60),
      },
      body: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(4096),
      },
      footer: {
        required: false,
        maxLength: maxLength(60),
      },
      button: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      },
      action: {
        sections: {
          $each: {
            title: {
              required,
              minLength: minLength(1),
              maxLength: maxLength(24)
            },
            rows: {
              $each: {
                title: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(24),
                },
                // description: {
                //   required,
                //   minLength: minLength(1),
                //   maxLength: maxLength(72),
                // },
                id: {
                  required,
                },
              },
            },
          },
        },
      },
    },
  },
  methods: {
    deleteSectionItem(sectionIdx) {
      this.list.action.sections.splice(sectionIdx, 1);
    },
    addSection() {
      if (!this.list.action.sections) {
        this.list.action.sections = [];
      }
      this.list.action.sections.push({
        title: '',
        rows: [
            // { id: '', title: 'New Item', description: 'New Description' }
        ]
      });
    },
    async formatCategories(categories=[]){
        console.log({
            categories: this.categories,
            catalogueItems: this.catalogueItems,
        });
        const validCategories = [];
        for(const category of categories){
            const validItems = this.catalogueItems.filter(item => {
                const belongsToCategory = item.categories.some(cat => cat.id === category.id);
                const itemInStock = item.availability === "in stock" && item.available_inventory > 0;
                const variantsInStock = item.variants.some(variant =>
                    variant.availability === "in stock" && variant.available_inventory > 0
                );
                console.log({belongsToCategory,itemInStock,variantsInStock,category,item});
                return belongsToCategory && (itemInStock || variantsInStock);
            });

            if (validItems.length > 0) {
                validCategories.push(category);
            }
        }
        this.validCategoriesOptions = validCategories.map(el=>({id:el.id,title:el.title}));
        console.log({validCategories,v:this.validCategoriesOptions});
    },
    async allDone() {
      console.log(this.list);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert("Please fill in all required fields correctly.");
        return;
      }
      this.$emit('list-edit-done', this.list);
    },

    onClose() {
      this.$emit('on-close');
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.bottom-margin {
  margin-bottom: var(--space-small) !important;
}

.field_item {
  display: flex;
  align-items: center;
  margin-bottom: 1.6em;
}

.section_item{
  position: relative;
  border: 1px solid var(--s-75);
  border-radius: var(--border-radius-normal);
  padding: 20px 10px;
  margin: 40px 0;
}
.item {
  position: relative;
  border: 1px solid var(--s-75);
  border-radius: var(--border-radius-normal);
  padding: 12px;
  margin-top: 8px;
}

.action_btn {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>

