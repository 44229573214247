var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "small-2 report-card",
      class: { active: _vm.selected },
      on: {
        click: function($event) {
          return _vm.onClick(_vm.index)
        }
      }
    },
    [
      _c("h3", { staticClass: "heading" }, [
        _vm._v("\n    " + _vm._s(_vm.heading) + "\n  ")
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "metric" }, [
        _vm._v("\n    " + _vm._s(_vm.point) + "\n  ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "desc" }, [
        _vm._v("\n    " + _vm._s(_vm.desc) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }