var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column" }, [
    _c("h2", { staticClass: "page-sub-title" }, [
      _vm._v("\n    " + _vm._s(_vm.headerTitle) + "\n  ")
    ]),
    _vm._v(" "),
    _c("p", {
      staticClass: "small-12 column",
      domProps: { innerHTML: _vm._s(_vm.headerContent) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }