var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.childContactName
    ? _c("div", { staticClass: "merge-summary callout" }, [
        _c("h5", { staticClass: "text-block-title" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("MERGE_CONTACTS.SUMMARY.TITLE")) + "\n  "
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "summary-items" }, [
          _c("li", [
            _c("span", { staticClass: "bullet" }, [_vm._v("❌")]),
            _vm._v(" "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("MERGE_CONTACTS.SUMMARY.DELETE_WARNING", {
                    childContactName: _vm.childContactName
                  })
                )
              }
            })
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", { staticClass: "bullet" }, [_vm._v("✅")]),
            _vm._v(" "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("MERGE_CONTACTS.SUMMARY.ATTRIBUTE_WARNING", {
                    childContactName: _vm.childContactName,
                    primaryContactName: _vm.primaryContactName
                  })
                )
              }
            })
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }