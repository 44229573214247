var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "medium-10 column signup" }, [
    _c("div", { staticClass: "text-center medium-12 signup--hero" }, [
      _c("img", {
        staticClass: "hero--logo",
        attrs: {
          src: _vm.globalConfig.logo,
          alt: _vm.globalConfig.installationName
        }
      }),
      _vm._v(" "),
      _vm.isAppsumoUser
        ? _c("h2", { staticClass: "hero--title" }, [
            _vm._v("\n      Welcome Sumo-lings!\n    ")
          ])
        : _c("h2", { staticClass: "hero--title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("REGISTER.TRY_WOOT")) + "\n    ")
          ])
    ]),
    _vm._v(" "),
    !_vm.isApsumoChecking
      ? _c("div", { staticClass: "row align-center" }, [
          _c("div", { staticClass: "small-12 medium-6 large-5 column" }, [
            _c(
              "form",
              {
                staticClass: "signup--box login-box",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  }
                }
              },
              [
                _c("woot-input", {
                  class: { error: _vm.$v.credentials.fullName.$error },
                  attrs: {
                    label: _vm.$t("REGISTER.FULL_NAME.LABEL"),
                    placeholder: _vm.$t("REGISTER.FULL_NAME.PLACEHOLDER"),
                    error: _vm.$v.credentials.fullName.$error
                      ? _vm.$t("REGISTER.FULL_NAME.ERROR")
                      : ""
                  },
                  on: { blur: _vm.$v.credentials.fullName.$touch },
                  model: {
                    value: _vm.credentials.fullName,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "fullName", $$v)
                    },
                    expression: "credentials.fullName"
                  }
                }),
                _vm._v(" "),
                _c("woot-input", {
                  class: { error: _vm.$v.credentials.email.$error },
                  attrs: {
                    type: "email",
                    label: _vm.$t("REGISTER.EMAIL.LABEL"),
                    placeholder: _vm.$t("REGISTER.EMAIL.PLACEHOLDER"),
                    readonly: _vm.readOnlyFields.email,
                    error: _vm.$v.credentials.email.$error
                      ? _vm.$t("REGISTER.EMAIL.ERROR")
                      : ""
                  },
                  on: { blur: _vm.$v.credentials.email.$touch },
                  model: {
                    value: _vm.credentials.email,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.credentials,
                        "email",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "credentials.email"
                  }
                }),
                _vm._v(" "),
                !_vm.hideFields.phone_number
                  ? _c("div", [
                      _c("div", [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("REGISTER.COUNTRY_CODE.LABEL")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selected_country_code,
                                expression: "selected_country_code"
                              }
                            ],
                            attrs: {
                              disabled:
                                _vm.readOnlyFields.phone_number ||
                                _vm.readOnlyFields.selected_country_code
                            },
                            on: {
                              input: _vm.onCountryCodeChange,
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selected_country_code = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.phone_code_list, function(code) {
                            return _c(
                              "option",
                              {
                                key: code.name,
                                domProps: { value: code.dial_code }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(code.emoji) +
                                    " " +
                                    _vm._s(code.dial_code) +
                                    "\n              "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm.readOnlyFields.phone_number
                            ? _c("woot-input", {
                                class: {
                                  error: _vm.$v.credentials.phone_number.$error
                                },
                                attrs: {
                                  label: _vm.$t("REGISTER.PHONE_NUMBER.LABEL"),
                                  placeholder: _vm.$t(
                                    "REGISTER.PHONE_NUMBER.PLACEHOLDER"
                                  ),
                                  error: _vm.$v.credentials.accountName.$error
                                    ? _vm.$t("REGISTER.PHONE_NUMBER.ERROR")
                                    : "",
                                  readonly: _vm.readOnlyFields.phone_number
                                },
                                on: {
                                  input: _vm.onPhoneNumberChange,
                                  blur: _vm.$v.credentials.phone_number.$touch
                                },
                                model: {
                                  value: _vm.input_phone_number,
                                  callback: function($$v) {
                                    _vm.input_phone_number = $$v
                                  },
                                  expression: "input_phone_number"
                                }
                              })
                            : _c("woot-input", {
                                class: {
                                  error: _vm.$v.credentials.phone_number.$error
                                },
                                attrs: {
                                  label: _vm.$t("REGISTER.PHONE_NUMBER.LABEL"),
                                  placeholder: _vm.$t(
                                    "REGISTER.PHONE_NUMBER.PLACEHOLDER"
                                  ),
                                  error: _vm.$v.credentials.accountName.$error
                                    ? _vm.$t("REGISTER.PHONE_NUMBER.ERROR")
                                    : ""
                                },
                                on: {
                                  input: _vm.onPhoneNumberChange,
                                  blur: _vm.$v.credentials.phone_number.$touch
                                }
                              })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("woot-input", {
                  class: { error: _vm.$v.credentials.accountName.$error },
                  attrs: {
                    label: _vm.$t("REGISTER.ACCOUNT_NAME.LABEL"),
                    placeholder: _vm.$t("REGISTER.ACCOUNT_NAME.PLACEHOLDER"),
                    readonly: _vm.readOnlyFields.accountName,
                    error: _vm.$v.credentials.accountName.$error
                      ? _vm.$t("REGISTER.ACCOUNT_NAME.ERROR")
                      : ""
                  },
                  on: { blur: _vm.$v.credentials.accountName.$touch },
                  model: {
                    value: _vm.credentials.accountName,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "accountName", $$v)
                    },
                    expression: "credentials.accountName"
                  }
                }),
                _vm._v(" "),
                _c("div", [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("REGISTER.COUNTRY_NAME.LABEL")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.credentials.country,
                          expression: "credentials.country"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.credentials,
                            "country",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.phone_code_list, function(countryEl) {
                      return _c(
                        "option",
                        {
                          key: countryEl.name,
                          domProps: { value: countryEl.name }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(countryEl.emoji) +
                              " " +
                              _vm._s(countryEl.name) +
                              "\n            "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _vm.credentials.country === "Malaysia"
                  ? _c("woot-input", {
                      class: { error: _vm.$v.credentials.tax_number.$error },
                      attrs: {
                        label: _vm.$t("REGISTER.TAX_NUMBER.LABEL"),
                        placeholder: _vm.$t("REGISTER.TAX_NUMBER.PLACEHOLDER"),
                        error: _vm.$v.credentials.tax_number.$error
                          ? _vm.$t("REGISTER.TAX_NUMBER.ERROR")
                          : ""
                      },
                      on: { blur: _vm.$v.credentials.tax_number.$touch },
                      model: {
                        value: _vm.credentials.tax_number,
                        callback: function($$v) {
                          _vm.$set(_vm.credentials, "tax_number", $$v)
                        },
                        expression: "credentials.tax_number"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hideFields.currency
                  ? _c("div", [
                      _c("label", [_vm._v("Default Currency")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.credentials.currency,
                              expression: "credentials.currency"
                            }
                          ],
                          attrs: {
                            disabled: _vm.readOnlyFields.currency,
                            required: "required"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.credentials,
                                "currency",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.currencies, function(option) {
                          return _c(
                            "option",
                            {
                              key: option.key,
                              domProps: { value: option.value }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(option.key) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("woot-input", {
                  class: { error: _vm.$v.credentials.password.$error },
                  attrs: {
                    type: "password",
                    label: _vm.$t("LOGIN.PASSWORD.LABEL"),
                    placeholder: _vm.$t(
                      "SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER"
                    ),
                    error: _vm.$v.credentials.password.$error
                      ? _vm.$t("SET_NEW_PASSWORD.PASSWORD.ERROR")
                      : ""
                  },
                  on: { blur: _vm.$v.credentials.password.$touch },
                  model: {
                    value: _vm.credentials.password,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.credentials,
                        "password",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "credentials.password"
                  }
                }),
                _vm._v(" "),
                _c("woot-input", {
                  class: { error: _vm.$v.credentials.confirmPassword.$error },
                  attrs: {
                    type: "password",
                    label: _vm.$t("SET_NEW_PASSWORD.CONFIRM_PASSWORD.LABEL"),
                    placeholder: _vm.$t(
                      "SET_NEW_PASSWORD.CONFIRM_PASSWORD.PLACEHOLDER"
                    ),
                    error: _vm.$v.credentials.confirmPassword.$error
                      ? _vm.$t("SET_NEW_PASSWORD.CONFIRM_PASSWORD.ERROR")
                      : ""
                  },
                  on: { blur: _vm.$v.credentials.confirmPassword.$touch },
                  model: {
                    value: _vm.credentials.confirmPassword,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.credentials,
                        "confirmPassword",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "credentials.confirmPassword"
                  }
                }),
                _vm._v(" "),
                _vm.globalConfig.hCaptchaSiteKey
                  ? _c(
                      "div",
                      { staticClass: "h-captcha--box" },
                      [
                        _c("vue-hcaptcha", {
                          attrs: { sitekey: _vm.globalConfig.hCaptchaSiteKey },
                          on: { verify: _vm.onRecaptchaVerified }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("woot-submit-button", {
                  attrs: {
                    disabled: _vm.isSignupInProgress || !_vm.hasAValidCaptcha,
                    "button-text": _vm.$t("REGISTER.SUBMIT"),
                    loading: _vm.isSignupInProgress,
                    "button-class": "large expanded"
                  }
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass: "accept--terms",
                  domProps: { innerHTML: _vm._s(_vm.termsLink) }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column text-center sigin--footer" },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("REGISTER.HAVE_AN_ACCOUNT")))
                ]),
                _vm._v(" "),
                _c("router-link", { attrs: { to: _vm.externalPath } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.useInstallationName(
                          _vm.$t("LOGIN.TITLE"),
                          _vm.globalConfig.installationName
                        )
                      ) +
                      "\n        "
                  )
                ])
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }