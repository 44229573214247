var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "primary--sidebar" }, [
    _c(
      "nav",
      { staticClass: "menu vertical" },
      _vm._l(_vm.menuItems, function(menuItem) {
        return _c("primary-nav-item", {
          key: menuItem.toState,
          attrs: {
            icon: menuItem.icon,
            name: menuItem.label,
            to: menuItem.toState,
            "is-child-menu-active": menuItem.key === _vm.activeMenuItem
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }