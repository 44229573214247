var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("nav", [
        _c("ul", { staticClass: "campaignNav" }, [
          _c(
            "li",
            {
              staticClass: "campaignNav_item",
              class:
                _vm.campaignActiveNav == "one_off"
                  ? "campaignNav_item campaignNav_itemActive"
                  : "",
              on: {
                click: function($event) {
                  return _vm.campaignNavToggle("one_off")
                }
              }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("SIDEBAR.ONE_OFF")) + "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "campaignNav_item",
              class:
                _vm.campaignActiveNav == "running"
                  ? "campaignNav_item campaignNav_itemActive"
                  : "",
              on: {
                click: function($event) {
                  return _vm.campaignNavToggle("running")
                }
              }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("SIDEBAR.ONGOING")) + "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("campaigns-table", {
            attrs: {
              campaigns: _vm.campaigns,
              "show-empty-result": _vm.showEmptyResult,
              "is-loading": _vm.uiFlags.isFetching,
              "campaign-type": _vm.type,
              isCampaignType: _vm.campaignActiveNav
            },
            on: {
              "on-edit-click": _vm.openEditPopup,
              "on-delete-click": _vm.openDeletePopup
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("table-footer", {
            attrs: {
              "on-page-change": _vm.onPageChange,
              "current-page": Number(_vm.meta.currentPage),
              "total-count": _vm.meta.count
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _c("edit-campaign", {
            attrs: { "selected-campaign": _vm.selectedCampaign },
            on: { "on-close": _vm.hideEditPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("CAMPAIGN.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("CAMPAIGN.DELETE.CONFIRM.MESSAGE"),
          "confirm-text": _vm.$t("CAMPAIGN.DELETE.CONFIRM.YES"),
          "reject-text": _vm.$t("CAMPAIGN.DELETE.CONFIRM.NO")
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }