var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { on: { click: _vm.onMenuItemClick } },
    [
      _c("fluent-icon", {
        staticClass: "hamburger--menu",
        attrs: { icon: "list" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }