var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "menu-slide" } }, [
    _vm.show
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.onClickAway,
                expression: "onClickAway"
              }
            ],
            staticClass: "dropdown-pane",
            class: { "dropdown-pane--open": _vm.show }
          },
          [
            _c("availability-status"),
            _vm._v(" "),
            _c("li", { staticClass: "divider" }),
            _vm._v(" "),
            _c(
              "woot-dropdown-menu",
              [
                _vm.showChangeAccountOption
                  ? _c(
                      "woot-dropdown-item",
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              variant: "clear",
                              "color-scheme": "secondary",
                              size: "small",
                              icon: "arrow-swap"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("toggle-accounts")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("SIDEBAR_ITEMS.CHANGE_ACCOUNTS")
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.globalConfig.chatwootInboxToken
                  ? _c(
                      "woot-dropdown-item",
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              variant: "clear",
                              "color-scheme": "secondary",
                              size: "small",
                              icon: "chat-help"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("show-support-chat-window")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("SIDEBAR_ITEMS.CONTACT_SUPPORT")
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "woot-dropdown-item",
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          variant: "clear",
                          "color-scheme": "secondary",
                          size: "small",
                          icon: "keyboard"
                        },
                        on: { click: _vm.handleKeyboardHelpClick }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "woot-dropdown-item",
                  [
                    _c("router-link", {
                      attrs: {
                        to:
                          "/app/accounts/" +
                          _vm.accountId +
                          "/profile/settings",
                        custom: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var href = ref.href
                              var isActive = ref.isActive
                              var navigate = ref.navigate
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button small clear secondary",
                                    class: { "is-active": isActive },
                                    attrs: { href: href },
                                    on: {
                                      click: function(e) {
                                        return _vm.handleProfileSettingClick(
                                          e,
                                          navigate
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("fluent-icon", {
                                      staticClass: "icon icon--font",
                                      attrs: { icon: "person", size: "14" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "button__content" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "SIDEBAR_ITEMS.PROFILE_SETTINGS"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3614846643
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "woot-dropdown-item",
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          variant: "clear",
                          "color-scheme": "secondary",
                          size: "small",
                          icon: "power"
                        },
                        on: { click: _vm.logout }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("SIDEBAR_ITEMS.LOGOUT")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }