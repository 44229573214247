var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "align-content": "center"
        }
      },
      [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.skillData.method,
                expression: "skillData.method"
              }
            ],
            staticClass: "filter__question",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.skillData,
                  "method",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          _vm._l(_vm.requestMethodTypes, function(item) {
            return _c(
              "option",
              { key: item.id, domProps: { value: item.id } },
              [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.skillData.url,
              expression: "skillData.url"
            }
          ],
          staticClass: "answer--text-input",
          staticStyle: { margin: "0", "margin-right": "5px" },
          attrs: {
            type: "text",
            placeholder: _vm.$t("FLOW.PLACEHOLDERS.REQUEST_URL")
          },
          domProps: { value: _vm.skillData.url },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.skillData, "url", $event.target.value)
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column content-box" },
      [
        _c(
          "woot-tabs",
          {
            attrs: { index: _vm.selectedTabIndex },
            on: { change: _vm.onClickTabChange }
          },
          _vm._l(_vm.tabs, function(tab) {
            return _c("woot-tabs-item", {
              key: tab.key,
              attrs: { name: tab.name, "show-badge": false }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _vm.selectedTabIndex == 0
            ? _c("div", { staticClass: "column padding-top" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.skillData.body,
                      expression: "skillData.body"
                    }
                  ],
                  staticClass: "action-message",
                  attrs: { rows: "4", placeholder: "Enter Body JSON Here..." },
                  domProps: { value: _vm.skillData.body },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.skillData, "body", $event.target.value)
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedTabIndex == 1
            ? _c("div", { staticClass: "column padding-top" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.skillData.headers,
                      expression: "skillData.headers"
                    }
                  ],
                  staticClass: "action-message",
                  attrs: {
                    rows: "4",
                    placeholder: "Enter Headers JSON Here..."
                  },
                  domProps: { value: _vm.skillData.headers },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.skillData, "headers", $event.target.value)
                    }
                  }
                })
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }