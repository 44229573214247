var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "profile--settings--row row" }, [
      _c("div", { staticClass: "columns small-3 " }, [
        _c("h4", { staticClass: "block-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t(
                  "PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.TITLE"
                )
              ) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t("PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.NOTE")
              ) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "columns small-9" }, [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.enableAudioAlerts,
                expression: "enableAudioAlerts"
              }
            ],
            staticClass: "notification--checkbox",
            attrs: {
              id: "audio_enable_alert_none",
              type: "radio",
              value: "none"
            },
            domProps: { checked: _vm._q(_vm.enableAudioAlerts, "none") },
            on: {
              input: _vm.handleAudioInput,
              change: function($event) {
                _vm.enableAudioAlerts = "none"
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "audio_enable_alert_none" } }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t(
                    "PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.NONE"
                  )
                ) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.enableAudioAlerts,
                expression: "enableAudioAlerts"
              }
            ],
            staticClass: "notification--checkbox",
            attrs: {
              id: "audio_enable_alert_mine",
              type: "radio",
              value: "mine"
            },
            domProps: { checked: _vm._q(_vm.enableAudioAlerts, "mine") },
            on: {
              input: _vm.handleAudioInput,
              change: function($event) {
                _vm.enableAudioAlerts = "mine"
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "audio_enable_alert_mine" } }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t(
                    "PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ASSIGNED"
                  )
                ) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.enableAudioAlerts,
                expression: "enableAudioAlerts"
              }
            ],
            staticClass: "notification--checkbox",
            attrs: {
              id: "audio_enable_alert_all",
              type: "radio",
              value: "all"
            },
            domProps: { checked: _vm._q(_vm.enableAudioAlerts, "all") },
            on: {
              input: _vm.handleAudioInput,
              change: function($event) {
                _vm.enableAudioAlerts = "all"
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "audio_enable_alert_all" } }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t(
                    "PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALL_CONVERSATIONS"
                  )
                ) +
                "\n        "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    !_vm.hideSubscriptionIdList.includes(_vm.currentUser.plan_id)
      ? _c("div", { staticClass: "profile--settings--row row" }, [
          _c("div", { staticClass: "columns small-3 " }, [
            _c("h4", { staticClass: "block-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.TITLE"
                    )
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.NOTE"
                    )
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns small-9" }, [
            _vm.checkAdmin
              ? _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedEmailFlags,
                        expression: "selectedEmailFlags"
                      }
                    ],
                    staticClass: "notification--checkbox",
                    attrs: {
                      type: "checkbox",
                      value: "email_conversation_creation"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.selectedEmailFlags)
                        ? _vm._i(
                            _vm.selectedEmailFlags,
                            "email_conversation_creation"
                          ) > -1
                        : _vm.selectedEmailFlags
                    },
                    on: {
                      input: _vm.handleEmailInput,
                      change: function($event) {
                        var $$a = _vm.selectedEmailFlags,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "email_conversation_creation",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.selectedEmailFlags = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selectedEmailFlags = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selectedEmailFlags = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "conversation_creation" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_CREATION"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedEmailFlags,
                    expression: "selectedEmailFlags"
                  }
                ],
                staticClass: "notification--checkbox",
                attrs: {
                  type: "checkbox",
                  value: "email_conversation_assignment"
                },
                domProps: {
                  checked: Array.isArray(_vm.selectedEmailFlags)
                    ? _vm._i(
                        _vm.selectedEmailFlags,
                        "email_conversation_assignment"
                      ) > -1
                    : _vm.selectedEmailFlags
                },
                on: {
                  input: _vm.handleEmailInput,
                  change: function($event) {
                    var $$a = _vm.selectedEmailFlags,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "email_conversation_assignment",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedEmailFlags = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedEmailFlags = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedEmailFlags = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "conversation_assignment" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT"
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedEmailFlags,
                    expression: "selectedEmailFlags"
                  }
                ],
                staticClass: "notification--checkbox",
                attrs: {
                  type: "checkbox",
                  value: "email_conversation_mention"
                },
                domProps: {
                  checked: Array.isArray(_vm.selectedEmailFlags)
                    ? _vm._i(
                        _vm.selectedEmailFlags,
                        "email_conversation_mention"
                      ) > -1
                    : _vm.selectedEmailFlags
                },
                on: {
                  input: _vm.handleEmailInput,
                  change: function($event) {
                    var $$a = _vm.selectedEmailFlags,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "email_conversation_mention",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedEmailFlags = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedEmailFlags = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedEmailFlags = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "conversation_mention" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_MENTION"
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedEmailFlags,
                    expression: "selectedEmailFlags"
                  }
                ],
                staticClass: "notification--checkbox",
                attrs: {
                  type: "checkbox",
                  value: "email_assigned_conversation_new_message"
                },
                domProps: {
                  checked: Array.isArray(_vm.selectedEmailFlags)
                    ? _vm._i(
                        _vm.selectedEmailFlags,
                        "email_assigned_conversation_new_message"
                      ) > -1
                    : _vm.selectedEmailFlags
                },
                on: {
                  input: _vm.handleEmailInput,
                  change: function($event) {
                    var $$a = _vm.selectedEmailFlags,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "email_assigned_conversation_new_message",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedEmailFlags = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedEmailFlags = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedEmailFlags = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { attrs: { for: "assigned_conversation_new_message" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE"
                        )
                      ) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.vapidPublicKey &&
    !_vm.isBrowserSafari &&
    !_vm.hideSubscriptionIdList.includes(_vm.currentUser.plan_id)
      ? _c("div", { staticClass: "profile--settings--row row push-row" }, [
          _c("div", { staticClass: "columns small-3 " }, [
            _c("h4", { staticClass: "block-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.TITLE"
                    )
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.NOTE"
                  )
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns small-9" }, [
            _vm.hasEnabledPushPermissions
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.HAS_ENABLED_PUSH"
                        )
                      ) +
                      "\n      "
                  )
                ])
              : _c(
                  "div",
                  [
                    _c("woot-submit-button", {
                      staticClass: "button nice small",
                      attrs: {
                        "button-text": _vm.$t(
                          "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH"
                        ),
                        type: "button"
                      },
                      on: { click: _vm.onRequestPermissions }
                    })
                  ],
                  1
                ),
            _vm._v(" "),
            _vm.checkAdmin
              ? _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPushFlags,
                        expression: "selectedPushFlags"
                      }
                    ],
                    staticClass: "notification--checkbox",
                    attrs: {
                      type: "checkbox",
                      value: "push_conversation_creation"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.selectedPushFlags)
                        ? _vm._i(
                            _vm.selectedPushFlags,
                            "push_conversation_creation"
                          ) > -1
                        : _vm.selectedPushFlags
                    },
                    on: {
                      input: _vm.handlePushInput,
                      change: function($event) {
                        var $$a = _vm.selectedPushFlags,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "push_conversation_creation",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.selectedPushFlags = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selectedPushFlags = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selectedPushFlags = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "conversation_creation" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_CREATION"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPushFlags,
                    expression: "selectedPushFlags"
                  }
                ],
                staticClass: "notification--checkbox",
                attrs: {
                  type: "checkbox",
                  value: "push_conversation_assignment"
                },
                domProps: {
                  checked: Array.isArray(_vm.selectedPushFlags)
                    ? _vm._i(
                        _vm.selectedPushFlags,
                        "push_conversation_assignment"
                      ) > -1
                    : _vm.selectedPushFlags
                },
                on: {
                  input: _vm.handlePushInput,
                  change: function($event) {
                    var $$a = _vm.selectedPushFlags,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "push_conversation_assignment",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedPushFlags = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedPushFlags = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedPushFlags = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "conversation_assignment" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT"
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPushFlags,
                    expression: "selectedPushFlags"
                  }
                ],
                staticClass: "notification--checkbox",
                attrs: { type: "checkbox", value: "push_conversation_mention" },
                domProps: {
                  checked: Array.isArray(_vm.selectedPushFlags)
                    ? _vm._i(
                        _vm.selectedPushFlags,
                        "push_conversation_mention"
                      ) > -1
                    : _vm.selectedPushFlags
                },
                on: {
                  input: _vm.handlePushInput,
                  change: function($event) {
                    var $$a = _vm.selectedPushFlags,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "push_conversation_mention",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedPushFlags = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedPushFlags = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedPushFlags = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "conversation_mention" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_MENTION"
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPushFlags,
                    expression: "selectedPushFlags"
                  }
                ],
                staticClass: "notification--checkbox",
                attrs: {
                  type: "checkbox",
                  value: "push_assigned_conversation_new_message"
                },
                domProps: {
                  checked: Array.isArray(_vm.selectedPushFlags)
                    ? _vm._i(
                        _vm.selectedPushFlags,
                        "push_assigned_conversation_new_message"
                      ) > -1
                    : _vm.selectedPushFlags
                },
                on: {
                  input: _vm.handlePushInput,
                  change: function($event) {
                    var $$a = _vm.selectedPushFlags,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "push_assigned_conversation_new_message",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selectedPushFlags = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selectedPushFlags = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selectedPushFlags = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { attrs: { for: "assigned_conversation_new_message" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.ASSIGNED_CONVERSATION_NEW_MESSAGE"
                        )
                      ) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }