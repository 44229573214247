var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    {
      attrs: {
        x: _vm.props.x,
        y: _vm.props.y,
        width: _vm.props.width,
        height: "16"
      }
    },
    [
      _c(
        "rect",
        _vm._g(
          {
            attrs: {
              fill: _vm.$options.fill(_vm.props.available, _vm.props.hovered),
              x: _vm.props.align === "start" ? 0 : _vm.props.width - 10,
              y: "0",
              rx: "5",
              ry: "5",
              cursor: _vm.props.disabled ? "default" : "pointer",
              width: "10",
              height: "10"
            }
          },
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c(
        "text",
        {
          attrs: {
            x: _vm.props.align === "start" ? 12 : _vm.props.width - 12,
            y: "9",
            "font-size": "8pt",
            "text-anchor": _vm.props.align
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.props.title) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }