var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avatar-container",
      style: [_vm.style, _vm.customStyle],
      attrs: { "aria-hidden": "true" }
    },
    [_c("span", [_vm._v(_vm._s(_vm.userInitial))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }