var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "columns small-12" }, [
    _c("aside", { staticClass: "margin_section" }, [
      _c(
        "div",
        { staticClass: "report-bar" },
        [
          _vm.isFetching
            ? _c("woot-loading-state", {
                attrs: { message: _vm.$t("REPORT.LOADING_CHART") }
              })
            : _c(
                "div",
                { staticClass: "chart-container" },
                [
                  _vm.reports.length
                    ? _c("woot-bar", {
                        attrs: { collection: _vm.collection, stacked: true }
                      })
                    : _c("span", { staticClass: "empty-state" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("REPORT.NO_ENOUGH_DATA")) +
                            "\n        "
                        )
                      ])
                ],
                1
              )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "margin_section" }, [
      _c(
        "div",
        { staticClass: "orders-table-wrap" },
        [
          _c("ve-table", {
            staticStyle: { width: "100%" },
            attrs: {
              "fixed-header": true,
              "max-height": "100%",
              columns: _vm.columns,
              "table-data": _vm.tableData,
              "border-around": false,
              "sort-option": _vm.sortOption
            }
          }),
          _vm._v(" "),
          !_vm.isLoading && !_vm.reports.length
            ? _c("empty-state", { attrs: { title: "No Report Available" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "orders--loader" },
                [
                  _c("spinner"),
                  _vm._v(" "),
                  _c("span", [_vm._v("Loading product reports")])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }