var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(option, index) {
      return _c("div", { key: index, staticClass: "radio-item" }, [
        _c("div", [
          _c("label", { staticClass: "radio_wrap", attrs: { for: "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedIndex,
                  expression: "selectedIndex"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: index,
                checked: _vm._q(_vm.selectedIndex, index)
              },
              on: {
                change: [
                  function($event) {
                    _vm.selectedIndex = index
                  },
                  _vm.emitSelection
                ]
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", [_c("b", [_vm._v(_vm._s(option.label))])]),
          _vm._v(" "),
          option.description
            ? _c("p", [_vm._v(_vm._s(option.description))])
            : _vm._e()
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }