var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      on: {
        mouseenter: _vm.mouseEnter,
        mouseleave: _vm.mouseLeave,
        mousedown: function($event) {
          return _vm.$emit("mousedown", $event)
        }
      }
    },
    [
      _c(
        "g",
        [
          _vm._l(_vm.link.breakPoints.length + 1, function(n, index) {
            return _c("path", {
              key: "bg" + String(index),
              style: _vm.largeStrokeStyle,
              attrs: {
                d: _vm.linkCurve(index),
                "stroke-width": "12",
                fill: "none"
              },
              on: {
                click: function($event) {
                  return _vm.addBreakPoint($event, index)
                },
                mouseenter: function($event) {
                  return _vm.mouseEnterPath($event, index)
                },
                mouseleave: _vm.mouseLeavePath
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.link.breakPoints.length + 1, function(n, index) {
            return _c("path", {
              key: index,
              ref: "currentPath",
              refInFor: true,
              style: _vm.strokeStyle,
              attrs: {
                d: _vm.linkCurve(index),
                "stroke-width": "2",
                fill: "none"
              },
              on: {
                click: function($event) {
                  return _vm.addBreakPoint($event, index)
                },
                mouseenter: function($event) {
                  return _vm.mouseEnterPath($event, index)
                },
                mouseleave: _vm.mouseLeavePath
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._l(_vm.link.breakPoints, function(point, index) {
        return _c("circle", {
          key: index,
          attrs: {
            cx: point.x,
            cy: point.y,
            r: "4",
            stroke: "#5D5F6E",
            "stroke-width": "2",
            fill: "#5D5F6E"
          },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              return _vm.onDragStart($event, index)
            },
            mouseup: function($event) {
              $event.preventDefault()
              return _vm.onDragEnd($event)
            },
            contextmenu: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.onRightClick($event, index)
            }
          }
        })
      }),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "del",
          staticStyle: { "background-color": "green" },
          attrs: {
            height: "100",
            width: "100",
            x: _vm.deleteX,
            y: _vm.deleteY,
            visibility: [
              _vm.isHovered && !_vm.isDraggingBreakPoint ? "visible" : "hidden"
            ],
            fill: "#555"
          },
          on: {
            click: function($event) {
              return _vm.$emit("deleteLink", $event)
            },
            mouseenter: function($event) {
              return _vm.mouseEnterPath($event, null)
            },
            mouseleave: _vm.mouseLeavePath
          }
        },
        [
          _c("circle", {
            staticClass: "del",
            attrs: {
              cx: "16",
              cy: "16",
              r: "14",
              stroke: "#555",
              "stroke-width": "1",
              fill: "white"
            }
          }),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "del",
              staticStyle: { "background-color": "green" },
              attrs: {
                height: "100",
                width: "100",
                x: "8",
                y: "8",
                fill: "#555"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("deleteLink", $event)
                }
              }
            },
            [
              _c("path", {
                staticClass: "del",
                attrs: {
                  d:
                    "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                staticClass: "del",
                attrs: {
                  "fill-rule": "evenodd",
                  d:
                    "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                }
              })
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }