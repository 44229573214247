var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          padding: "8px",
          "padding-left": "24px",
          "padding-right": "36px",
          color: "grey",
          "align-items": "center"
        }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.node.data.customName,
              expression: "node.data.customName"
            }
          ],
          staticClass: "inputTitle",
          staticStyle: {
            margin: "0",
            "margin-right": "5px",
            "text-transform": "uppercase"
          },
          attrs: { type: "text", placeholder: _vm.node.title },
          domProps: { value: _vm.node.data.customName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.node.data, "customName", $event.target.value)
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.STRIPE_NODE_FORM.AMOUNT.LABEL")) +
                    "\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.amount,
                      expression: "amount"
                    }
                  ],
                  attrs: {
                    type: "number",
                    placeholder: _vm.$t(
                      "FLOW.STRIPE_NODE_FORM.AMOUNT.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.amount },
                  on: {
                    change: _vm.onAmountChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.amount = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.STRIPE_NODE_FORM.CURRENCY.LABEL")) +
                    "\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currency,
                      expression: "currency"
                    }
                  ],
                  staticClass: "w3-input",
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "FLOW.STRIPE_NODE_FORM.CURRENCY.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.currency },
                  on: {
                    change: _vm.onCurrencyChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.currency = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("FLOW.STRIPE_NODE_FORM.SUCCESS_CALLBACK.LABEL")
                    ) +
                    "\n            "
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.successUrl,
                      expression: "successUrl"
                    }
                  ],
                  staticClass: "w3-input",
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "FLOW.STRIPE_NODE_FORM.SUCCESS_CALLBACK.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.successUrl },
                  on: {
                    change: _vm.onSuccessUrlChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.successUrl = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("FLOW.STRIPE_NODE_FORM.CANCEL_CALLBACK.LABEL")
                    ) +
                    "\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cancelUrl,
                      expression: "cancelUrl"
                    }
                  ],
                  staticClass: "w3-input",
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "FLOW.STRIPE_NODE_FORM.CANCEL_CALLBACK.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.cancelUrl },
                  on: {
                    change: _vm.onCancelUrlChange,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.cancelUrl = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "block",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticClass: "custom-checkbox-container" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.STRIPE_NODE_FORM.DISCOUNT.LABEL")) +
                    "\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.discount,
                      expression: "discount"
                    }
                  ],
                  attrs: { id: "discount", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.discount)
                      ? _vm._i(_vm.discount, null) > -1
                      : _vm.discount
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.discount,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.discount = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.discount = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.discount = $$c
                        }
                      },
                      _vm.onDiscountChange
                    ]
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "woot-button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.checkValidation($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("FLOW.DONE")))]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }