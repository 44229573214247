var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "small-6 medium-4 large-3 columns channel",
      class: {
        inactive: !_vm.isActive,
        disabled_channel: _vm.isChannelDisabled
      },
      on: { click: _vm.onItemClick }
    },
    [
      _vm.channel.key === "facebook"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/messenger.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "twitter"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/twitter.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "telegram"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/telegram.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "api" && !_vm.channel.thumbnail
        ? _c("img", {
            attrs: { src: require("dashboard/assets/images/channels/api.png") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "api" && _vm.channel.thumbnail
        ? _c("img", { attrs: { src: _vm.channel.thumbnail } })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "email"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/email.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "line"
        ? _c("img", {
            attrs: { src: require("dashboard/assets/images/channels/line.png") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "website"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/website.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "twilio sms"
        ? _c("img", {
            attrs: { src: require("dashboard/assets/images/channels/sms.png") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "whatsapp"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/whatsapp.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.channel.key === "whatsapp communal"
        ? _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/whatsapp.png")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h3", { staticClass: "channel__title" }, [
        _vm._v("\n    " + _vm._s(_vm.channel.name) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }