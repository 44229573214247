var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t(
                "CATALOGUE.ADD_EDIT_FORM.UPDATE_HEADER.TITLE"
              ),
              "header-content": _vm.$t(
                "CATALOGUE.ADD_EDIT_FORM.UPDATE_HEADER.CONTENT"
              )
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateCatalogueItem()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c("woot-input", {
                    class: { error: _vm.$v.title.$error },
                    attrs: {
                      label: _vm.$t("CATALOGUE.ADD_EDIT_FORM.TITLE_LABEL"),
                      placeholder: _vm.$t(
                        "CATALOGUE.ADD_EDIT_FORM.TITLE_PLACEHOLDER"
                      ),
                      type: "text",
                      error: _vm.$v.title.$error
                        ? _vm.$t("CATALOGUE.ADD_EDIT_FORM.TITLE_ERROR")
                        : ""
                    },
                    model: {
                      value: _vm.title,
                      callback: function($$v) {
                        _vm.title = $$v
                      },
                      expression: "title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.description.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_LABEL")
                      ) +
                      "\n          "
                  ),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.description,
                        expression: "description",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      rows: "5",
                      type: "text",
                      placeholder: _vm.$t(
                        "CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.description },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.description = $event.target.value.trim()
                        },
                        _vm.$v.description.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm.$v.description.$error
                  ? _c("p", { staticClass: "error_message" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_ERROR")
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns display-inline" }, [
                _c("label", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("CATALOGUE.ADD_EDIT_FORM.IMG_LABEL")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "file",
                    attrs: { id: "file", type: "file", accept: "image/*," },
                    on: { change: _vm.handleImageUpload }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns display-inline" }, [
                _c("div", { staticClass: "medium-6 columns" }, [
                  _c("label", { class: { error: _vm.$v.price.$error } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("CATALOGUE.ADD_EDIT_FORM.PRICE_LABEL"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.price,
                          expression: "price",
                          modifiers: { trim: true }
                        }
                      ],
                      attrs: {
                        type: "number",
                        step: "any",
                        placeholder: _vm.$t(
                          "CATALOGUE.ADD_EDIT_FORM.PRICE_PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.price },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.price = $event.target.value.trim()
                          },
                          _vm.$v.price.$touch
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.$v.price.$error
                    ? _c("p", { staticClass: "error_message" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("CATALOGUE.ADD_EDIT_FORM.PRICE_ERROR")
                            ) +
                            "\n              "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "medium-6 columns" }, [
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("CATALOGUE.ADD_EDIT_FORM.CURRENCY_LABEL"))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currency,
                            expression: "currency"
                          }
                        ],
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.currency = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "MYR" } }, [
                          _vm._v("MYR")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "USD" } }, [
                          _vm._v("USD")
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns display-inline" }, [
                _c("div", { staticClass: "medium-6 columns" }, [
                  _c("label", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("CATALOGUE.ADD_EDIT_FORM.AVAILABILITY_LABEL")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.availability,
                            expression: "availability"
                          }
                        ],
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.availability = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "in stock" } }, [
                          _vm._v("In Stock")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "out stock" } }, [
                          _vm._v("Out Stock")
                        ])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "medium-6 columns" },
                  [
                    _c("woot-input", {
                      class: { error: _vm.$v.inventory.$error },
                      attrs: {
                        label: _vm.$t(
                          "CATALOGUE.ADD_EDIT_FORM.INVENTORY_LABEL"
                        ),
                        placeholder: _vm.$t(
                          "CATALOGUE.ADD_EDIT_FORM.INVENTORY_PLACEHOLDER"
                        ),
                        type: "number",
                        error: _vm.$v.inventory.$error
                          ? _vm.$t("CATALOGUE.ADD_EDIT_FORM.INVENTORY_ERROR")
                          : ""
                      },
                      model: {
                        value: _vm.inventory,
                        callback: function($$v) {
                          _vm.inventory = $$v
                        },
                        expression: "inventory"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c(
                  "label",
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("CATALOGUE.ADD_EDIT_FORM.ASSIGN_CTG_LABEL")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("multiselect", {
                      staticClass: "small-12",
                      attrs: {
                        options: _vm.categories,
                        "track-by": "id",
                        label: "title",
                        multiple: true,
                        "close-on-select": false,
                        "clear-on-select": false,
                        "hide-selected": true,
                        placeholder: _vm.$t(
                          "CATALOGUE.ADD_EDIT_FORM.ASSIGN_CTG_PLACEHOLDER"
                        ),
                        "selected-label": "",
                        "select-label": _vm.$t(
                          "FORMS.MULTISELECT.ENTER_TO_SELECT"
                        ),
                        "deselect-label": _vm.$t(
                          "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                        )
                      },
                      model: {
                        value: _vm.selectedCategories,
                        callback: function($$v) {
                          _vm.selectedCategories = $$v
                        },
                        expression: "selectedCategories"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c("div", { staticClass: "addVariant_label" }, [
                    _c("label", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("CATALOGUE.ADD_EDIT_FORM.VARIANT_LABEL")
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        [
                          _c("woot-button", {
                            attrs: {
                              "color-scheme": "primary",
                              variant: "smooth",
                              size: "small",
                              icon: "add"
                            },
                            on: { click: _vm.addVariant }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.variants, function(variant, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "variant_inputs" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "medium-4 columns",
                            class: { error: _vm.isFieldError("title", index) }
                          },
                          [
                            _c("woot-input", {
                              class: {
                                error: _vm.isFieldError("title", index)
                              },
                              attrs: {
                                label: "Title",
                                placeholder: _vm.$t(
                                  "CATALOGUE.ADD_EDIT_FORM.VARIANT_TITLE_PLACEHOLDER"
                                ),
                                type: "text",
                                error: _vm.isFieldError("title", index)
                                  ? _vm.$t(
                                      "CATALOGUE.ADD_EDIT_FORM.VARIANT_TITLE_REQUIRED_ERR"
                                    )
                                  : ""
                              },
                              model: {
                                value: variant.title,
                                callback: function($$v) {
                                  _vm.$set(variant, "title", $$v)
                                },
                                expression: "variant.title"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "medium-3 columns",
                            class: { error: _vm.isFieldError("price", index) }
                          },
                          [
                            _c("woot-input", {
                              class: {
                                error: _vm.isFieldError("price", index)
                              },
                              attrs: {
                                label: "Price",
                                placeholder: _vm.$t(
                                  "CATALOGUE.ADD_EDIT_FORM.VARIANT_PRICE_PLACEHOLDER"
                                ),
                                type: "text",
                                error: _vm.isFieldError("price", index)
                                  ? _vm.$t(
                                      "CATALOGUE.ADD_EDIT_FORM.VARIANT_PRICE_REQUIRED_ERR"
                                    )
                                  : ""
                              },
                              model: {
                                value: variant.price,
                                callback: function($$v) {
                                  _vm.$set(variant, "price", $$v)
                                },
                                expression: "variant.price"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "medium-3 columns",
                            class: {
                              error: _vm.isFieldError("inventory", index)
                            }
                          },
                          [
                            _c("woot-input", {
                              class: {
                                error: _vm.isFieldError("inventory", index)
                              },
                              attrs: {
                                label: "Inventory",
                                placeholder: _vm.$t(
                                  "CATALOGUE.ADD_EDIT_FORM.VARIANT_INVENTORY_PLACEHOLDER"
                                ),
                                type: "text",
                                error: _vm.isFieldError("inventory", index)
                                  ? _vm.$t(
                                      "CATALOGUE.ADD_EDIT_FORM.VARIANT_INVENTORY_REQUIRED_ERR"
                                    )
                                  : ""
                              },
                              model: {
                                value: variant.inventory,
                                callback: function($$v) {
                                  _vm.$set(variant, "inventory", $$v)
                                },
                                expression: "variant.inventory"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "medium-2 columns dismiss_btn" },
                          [
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeVariant(index)
                                    }
                                  }
                                },
                                [
                                  _c("fluent-icon", {
                                    attrs: { icon: "dismiss" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        "button-text": _vm.$t(
                          "CATALOGUE.ADD_EDIT_FORM.UPDATE_BTN"
                        ),
                        loading: _vm.uiFlags.isUpdating_catalog_items,
                        disabled: _vm.uiFlags.isUpdating_catalog_items
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }