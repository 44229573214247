var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.EDIT.TITLE")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.editWebhook($event)
            }
          }
        },
        [
          _c("div", { staticClass: "medium-12 columns" }, [
            _c("label", { class: { error: _vm.$v.endPoint.$error } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "INTEGRATION_SETTINGS.WEBHOOK.EDIT.FORM.END_POINT.LABEL"
                    )
                  ) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.endPoint,
                    expression: "endPoint",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  name: "endPoint",
                  placeholder: _vm.$t(
                    "INTEGRATION_SETTINGS.WEBHOOK.EDIT.FORM.END_POINT.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.endPoint },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.endPoint = $event.target.value.trim()
                    },
                    _vm.$v.endPoint.$touch
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.endPoint.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "INTEGRATION_SETTINGS.WEBHOOK.EDIT.FORM.END_POINT.ERROR"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "div",
              { staticClass: "medium-12 columns" },
              [
                _c(
                  "woot-button",
                  {
                    attrs: {
                      "is-disabled":
                        _vm.$v.endPoint.$invalid ||
                        _vm.uiFlags.updatingItem ||
                        _vm.endPoint === _vm.url,
                      "is-loading": _vm.uiFlags.updatingItem
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "INTEGRATION_SETTINGS.WEBHOOK.EDIT.FORM.SUBMIT"
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    staticClass: "button clear",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onClose($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.ADD.CANCEL")
                        ) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }