var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter",
      class: {
        error:
          (_vm.v.action_params.$dirty && _vm.v.action_params.$error) ||
          (_vm.value.action_name === "trigger_event" &&
            _vm.v.notNeeded.$dirty &&
            _vm.v.notNeeded.$error)
      }
    },
    [
      _c(
        "div",
        { staticClass: "filter-inputs column" },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.action_name,
                  expression: "action_name"
                }
              ],
              staticClass: "action__question",
              class: { "full-width": !_vm.showActionInput },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.action_name = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.resetAction()
                  }
                ]
              }
            },
            _vm._l(_vm.actionTypes, function(attribute) {
              return _c(
                "option",
                { key: attribute.key, domProps: { value: attribute.key } },
                [_vm._v("\n        " + _vm._s(attribute.label) + "\n      ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.value.action_name === "assign_bot"
            ? _c(
                "div",
                {
                  staticClass:
                    "multiselect-wrap--small column padding-right-small"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bot_id,
                        expression: "bot_id"
                      }
                    ],
                    staticClass: "answer--text-input",
                    attrs: { type: "text", placeholder: "Enter bot id" },
                    domProps: { value: _vm.bot_id },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.bot_id = $event.target.value
                      }
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.value.action_name === "assign_bot"
            ? _c(
                "div",
                { staticClass: "multiselect-wrap--small column" },
                [
                  _c("multiselect", {
                    attrs: {
                      "track-by": "id",
                      label: "name",
                      placeholder: "Select",
                      multiple: true,
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": "",
                      "max-height": 160,
                      options: _vm.inboxes,
                      "allow-empty": false
                    },
                    model: {
                      value: _vm.action_params,
                      callback: function($$v) {
                        _vm.action_params = $$v
                      },
                      expression: "action_params"
                    }
                  })
                ],
                1
              )
            : _vm.value.action_name === "set_contact_attributes"
            ? _c(
                "div",
                { staticClass: "multiselect-wrap--small column" },
                [
                  _c("multiselect", {
                    attrs: {
                      "track-by": "key",
                      multiple: true,
                      label: "name",
                      placeholder: "Select",
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": "",
                      "max-height": 160,
                      options: _vm.customAttributes,
                      "allow-empty": false
                    },
                    model: {
                      value: _vm.action_params,
                      callback: function($$v) {
                        _vm.action_params = $$v
                      },
                      expression: "action_params"
                    }
                  })
                ],
                1
              )
            : _vm.value.action_name === "trigger_event"
            ? _c("div", { staticClass: "multiselect-wrap--small column" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.action_params,
                      expression: "action_params"
                    }
                  ],
                  staticClass: "answer--text-input",
                  attrs: { type: "text", placeholder: "Enter event name" },
                  domProps: { value: _vm.action_params },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.action_params = $event.target.value
                    }
                  }
                })
              ])
            : _vm.value.action_name === "send_message"
            ? _c(
                "div",
                { staticClass: "multiselect-wrap--small column" },
                [
                  _c("multiselect", {
                    attrs: {
                      "track-by": "id",
                      label: "name",
                      placeholder: "Select",
                      multiple: true,
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": "",
                      "max-height": 160,
                      options: _vm.dropdownValues,
                      "allow-empty": false
                    },
                    model: {
                      value: _vm.action_params,
                      callback: function($$v) {
                        _vm.action_params = $$v
                      },
                      expression: "action_params"
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "multiselect-wrap--small column" },
                [
                  _c("multiselect", {
                    attrs: {
                      "track-by": "id",
                      label: "name",
                      placeholder: "Select",
                      multiple: true,
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": "",
                      "max-height": 160,
                      options: _vm.dropdownValues,
                      "allow-empty": false
                    },
                    model: {
                      value: _vm.action_params,
                      callback: function($$v) {
                        _vm.action_params = $$v
                      },
                      expression: "action_params"
                    }
                  })
                ],
                1
              ),
          _vm._v(" "),
          _c("woot-button", {
            attrs: {
              icon: "dismiss",
              variant: "clear",
              "color-scheme": "secondary"
            },
            on: { click: _vm.removeAction }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.value.action_name === "send_message"
        ? _c(
            "div",
            {
              staticClass:
                "multiselect-wrap--small column padding-right-small margin-top"
            },
            [
              _vm.showMentions &&
              _vm.hasSlashCommand &&
              _vm.nonCommunalInboxes.length === 0
                ? _c("canned-response", {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.hideMentions,
                        expression: "hideMentions"
                      }
                    ],
                    attrs: { top: "37", "search-key": _vm.mentionSearchKey },
                    on: { click: _vm.replaceText }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.value.action_params && _vm.value.action_params.length > 0
                ? _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.message_content,
                        expression: "message_content"
                      }
                    ],
                    staticClass: "action-message",
                    attrs: {
                      rows: "4",
                      placeholder: _vm.$t("AUTOMATION.ADD_MESSAGE_CONTENT")
                    },
                    domProps: { value: _vm.message_content },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.message_content = $event.target.value
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.value.action_name === "set_contact_attributes"
        ? _c(
            "div",
            {
              staticClass:
                "multiselect-wrap--small column padding-right-small margin-top"
            },
            [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.content,
                    expression: "content"
                  }
                ],
                staticClass: "action-message",
                attrs: {
                  rows: "1",
                  placeholder: _vm.$t("AUTOMATION.ADD_CONTENT")
                },
                domProps: { value: _vm.content },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.content = $event.target.value
                  }
                }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.v.action_params.$dirty && _vm.v.action_params.$error
        ? _c("p", { staticClass: "filter-error" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("FILTER.EMPTY_VALUE_ERROR")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.value.action_name === "trigger_event" &&
      _vm.v.notNeeded.$dirty &&
      _vm.v.notNeeded.$error
        ? _c("p", { staticClass: "filter-error" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("FILTER.NOT_BE_SAME")) + "\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }