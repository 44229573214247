var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("section", { staticClass: "row" }, [
        _c("div", { staticClass: "small-8 columns with-right-space " }, [
          _c("div", { staticClass: "sub_tabs" }, [
            _c("div", [
              _c(
                "ul",
                { staticClass: "tabs" },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "li",
                    {
                      key: tab.key,
                      staticClass: "tabs-title",
                      class: tab.type === _vm.selectedTab ? "is-active" : "",
                      on: {
                        click: function($event) {
                          return _vm.onSubTabChange(tab)
                        }
                      }
                    },
                    [_c("a", [_vm._v(_vm._s(tab.name))])]
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns with-left-space " }, [
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _vm.selectedTab === "sales"
                ? _c(
                    "woot-button",
                    {
                      attrs: {
                        "color-scheme": "success",
                        "class-names": "button--fixed-right-top",
                        icon: "arrow-download"
                      },
                      on: { click: _vm.downloadSaleReports }
                    },
                    [
                      _vm._v(
                        "\n              Download sales report\n              "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedTab === "product"
                ? _c(
                    "woot-button",
                    {
                      attrs: {
                        "color-scheme": "success",
                        "class-names": "button--fixed-right-top",
                        icon: "arrow-download"
                      },
                      on: { click: _vm.downloadProductReports }
                    },
                    [
                      _vm._v(
                        "\n              Download product report\n              "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("date-range-picker", {
                attrs: {
                  "confirm-text": _vm.$t(
                    "ORDER.LIST_TABLE.DATE_SELECT_CONFIRM_BTN"
                  ),
                  placeholder: _vm.$t(
                    "ORDER.LIST_TABLE.DATE_SELECT_PLACEHOLDER"
                  ),
                  value: _vm.dateRange,
                  disabledDate: _vm.disableFilterDate
                },
                on: { change: _vm.handleDateChange }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      [
        _vm.selectedTab === "sales"
          ? _c("SalesReport", {
              attrs: {
                isLoading: _vm.uiFlags.isFetching,
                reports: _vm.salseReports,
                all_reports: _vm.salseReportsAll,
                currency: _vm.currentUser.default_currency
              },
              on: { "on-sort-change": _vm.onSortChangeSales }
            })
          : _vm.selectedTab === "product"
          ? _c("ProductReport", {
              attrs: {
                isLoading: _vm.uiFlags.isFetching,
                reports: _vm.productReports,
                all_reports: _vm.productReportsAll,
                currency: _vm.currentUser.default_currency
              },
              on: { "on-sort-change": _vm.onSortChangeProduct }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "loadMore_btn" },
          [
            _vm.selectedTab === "sales"
              ? _c(
                  "woot-button",
                  {
                    attrs: {
                      variant: "clear",
                      size: "small",
                      "color-scheme": "primary",
                      "is-loading": _vm.uiFlags.isFetching,
                      "is-disabled": _vm.paginationCompute.isLastPage
                    },
                    on: {
                      click: function($event) {
                        _vm.onPageChangeSales(
                          Number(_vm.salesMeta.currentPage) + 1
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.paginationCompute.btnText) +
                        "\n        "
                    )
                  ]
                )
              : _vm.selectedTab === "product"
              ? _c(
                  "woot-button",
                  {
                    attrs: {
                      variant: "clear",
                      size: "small",
                      "color-scheme": "primary",
                      "is-loading": _vm.uiFlags.isFetching,
                      "is-disabled": _vm.paginationCompute.isLastPage
                    },
                    on: {
                      click: function($event) {
                        _vm.onPageChangeProduct(
                          Number(_vm.productMeta.currentPage) + 1
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.paginationCompute.btnText) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }