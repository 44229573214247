var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "column content-box" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              "padding-left": "32px",
              "padding-right": "32px"
            }
          },
          [
            _c("span"),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                attrs: { "class-names": this.allowCreation ? "" : "disabled" },
                on: { click: _vm.openAddPopup }
              },
              [
                _vm._v(
                  "\n        + " +
                    _vm._s(_vm.$t("FLOW.CREATE_NEW_FLOW")) +
                    "\n      "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchFlowItem,
              expression: "searchFlowItem"
            }
          ],
          staticStyle: {
            width: "70%",
            padding: "24px",
            "align-self": "center",
            margin: "10px auto"
          },
          attrs: { type: "search", placeholder: "Search flow" },
          domProps: { value: _vm.searchFlowItem },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchFlowItem = $event.target.value
              },
              _vm.searchFlow
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              padding: "24px",
              display: "flex",
              "justify-content": "center"
            }
          },
          [
            _vm.gettingBots
              ? _c("woot-loading-state", {
                  attrs: { message: "Fetching Bots" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showFlowList
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      width: "75%",
                      padding: "24px",
                      "align-self": "center"
                    }
                  },
                  [
                    _vm.flowList.length == 0
                      ? _c("h1", { staticClass: "no-flow-msg" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("FLOW.404")) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.flowList.length != 0
                      ? _c(
                          "table",
                          { staticClass: "flow-list-table" },
                          [
                            _c("tr", { staticClass: "flow-header" }, [
                              _c("td", { staticClass: "center" }, [
                                _c("h1", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("FLOW.NAME")) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "center" }, [
                                _c("h1", { staticClass: "name flex" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("FLOW.MODIFIED")) +
                                      "\n\n                "
                                  ),
                                  _vm.modifiedSort
                                    ? _c("img", {
                                        attrs: {
                                          src: require("dashboard/assets/images/arrow-down-short.svg")
                                        },
                                        on: { click: _vm.onModifiedSortChange }
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("dashboard/assets/images/arrow-up-short.svg")
                                        },
                                        on: { click: _vm.onModifiedSortChange }
                                      })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "center" }, [
                                _c("h1", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("FLOW.ACTIONS")) +
                                      "\n              "
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.flowList, function(flowItem, index) {
                              return _c(
                                "tr",
                                {
                                  key: index,
                                  staticClass: "flow-box",
                                  on: {
                                    click: function($event) {
                                      return _vm.itemClick($event, flowItem)
                                    }
                                  }
                                },
                                [
                                  _c("td", { staticClass: "left" }, [
                                    _c(
                                      "h1",
                                      {
                                        staticClass: "name",
                                        on: {
                                          mousedown: function($event) {
                                            return _vm.aboutToSelect(true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(flowItem.name)
                                        ),
                                        flowItem.template
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                  "background-color": "gold",
                                                  "border-radius": "5px",
                                                  padding: "5px",
                                                  "font-size": "10px",
                                                  color: "white"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("FLOW.TEMPLATE")
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "center" }, [
                                    _c("h1", { staticClass: "date" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm
                                              .moment(flowItem.modified)
                                              .local()
                                              .fromNow()
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "right" },
                                    [
                                      _c("woot-button", {
                                        attrs: {
                                          variant: "smooth",
                                          size: "small",
                                          "color-scheme": "secondary",
                                          icon: "delete",
                                          "class-names":
                                            "grey-btn btn-width-auto"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteFlow(flowItem)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _c("create-flow", {
            attrs: { "user-id": this.userId, "account-id": this.accountId },
            on: {
              "on-close": _vm.hideAddPopup,
              "create-flow": _vm.createNewFlow
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showDeleteConfirmation,
            "on-close": _vm.hideDeleteConfirmation
          },
          on: {
            "update:show": function($event) {
              _vm.showDeleteConfirmation = $event
            }
          }
        },
        [
          _c("delete-flow", {
            attrs: { bot: _vm.currentBot },
            on: {
              "on-close": _vm.hideDeleteConfirmation,
              "delete-flow": _vm.deleteFlowConfirmed
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }