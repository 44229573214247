var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _vm.show
      ? _c(
          "div",
          {
            class: _vm.modalClassName,
            attrs: { transition: "modal" },
            on: { click: _vm.onBackDropClick }
          },
          [
            _c(
              "div",
              {
                class: _vm.modalContainerClassName,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "button",
                  { class: _vm.modalCloseClassName, on: { click: _vm.close } },
                  [_c("fluent-icon", { attrs: { icon: "dismiss" } })],
                  1
                ),
                _vm._v(" "),
                _vm._t("default")
              ],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }