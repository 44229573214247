var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.activeStatus,
          expression: "activeStatus"
        }
      ],
      staticClass: "status--filter",
      on: {
        change: [
          function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.activeStatus = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          function($event) {
            return _vm.onTabChange()
          }
        ]
      }
    },
    [
      _vm._l(_vm.$t("CHAT_LIST.CHAT_STATUS_FILTER_ITEMS"), function(
        value,
        status
      ) {
        return _c("option", { key: status, domProps: { value: status } }, [
          _vm._v("\n    " + _vm._s(value["TEXT"]) + "\n  ")
        ])
      }),
      _vm._v(" "),
      _c("option", { attrs: { value: "all" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("CHAT_LIST.FILTER_ALL")) + "\n  ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }