var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "radio-group-label" }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "radio-group" },
      _vm._l(_vm.items, function(item) {
        return _c("div", { key: item.id }, [
          _c("input", {
            attrs: { name: "radio-input", type: "radio" },
            domProps: { checked: item.checked },
            on: {
              change: function($event) {
                return _vm.action(item)
              }
            }
          }),
          _vm._v(" "),
          _c("label", [_vm._v(_vm._s(item.title))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }