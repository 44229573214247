var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    { attrs: { show: _vm.modalValue, "on-close": _vm.closeModal } },
    [
      _c("add-top-up-container", {
        attrs: {
          "modal-value": this.modalValue,
          "registration-fee": this.registrationFee,
          "user-balance": _vm.userBalance,
          "product-id": this.productId,
          "old-plan-id": this.oldPlanId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }