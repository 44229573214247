var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        "close-on-backdrop-click": false
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.ADD.TITLE"),
              "header-content": _vm.useInstallationName(
                _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.ADD.DESC"),
                _vm.globalConfig.installationName
              )
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.addWebhook($event)
                }
              }
            },
            [
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.endPoint.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.END_POINT.LABEL"
                        )
                      ) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.endPoint,
                        expression: "endPoint",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      type: "text",
                      name: "endPoint",
                      placeholder: _vm.$t(
                        "INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.END_POINT.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.endPoint },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.endPoint = $event.target.value.trim()
                        },
                        _vm.$v.endPoint.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.$v.endPoint.$error
                    ? _c("span", { staticClass: "message" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.END_POINT.ERROR"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.ADD.EVENTS.LABEL")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.integrationEvents, function(event) {
                    return _c(
                      "label",
                      { key: event.value, attrs: { for: "" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedEvents,
                              expression: "selectedEvents"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: event.value,
                            checked: Array.isArray(_vm.selectedEvents)
                              ? _vm._i(_vm.selectedEvents, event.value) > -1
                              : _vm.selectedEvents
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.selectedEvents,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = event.value,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedEvents = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedEvents = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedEvents = $$c
                                }
                              },
                              _vm.handleCheckboxChange
                            ]
                          }
                        }),
                        _vm._v(
                          "\n          " + _vm._s(event.label) + "\n        "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          disabled:
                            _vm.$v.endPoint.$invalid ||
                            _vm.addWebHook.showLoading,
                          "is-loading": _vm.addWebHook.showLoading
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "INTEGRATION_SETTINGS.WEBHOOK.ADD.FORM.SUBMIT"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.ADD.CANCEL")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }