var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.emptyClassName },
    [
      _vm.uiFlags.isFetching || _vm.loadingChatList
        ? _c("woot-loading-state", {
            attrs: { message: _vm.loadingIndicatorMessage }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.inboxesList.length && !_vm.uiFlags.isFetching && !_vm.loadingChatList
        ? _c(
            "div",
            { staticClass: "clearfix" },
            [
              _vm.isAdmin
                ? _c("onboarding-view")
                : _c("div", { staticClass: "current-chat" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("dashboard/assets/images/inboxes.svg"),
                          alt: "No Inboxes"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("CONVERSATION.NO_INBOX_AGENT")) +
                            "\n        "
                        )
                      ])
                    ])
                  ])
            ],
            1
          )
        : !_vm.uiFlags.isFetching && !_vm.loadingChatList
        ? _c("div", { staticClass: "current-chat" }, [
            !_vm.allConversations.length
              ? _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/chat.svg"),
                      alt: "No Chat"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CONVERSATION.NO_MESSAGE_1")) +
                        "\n        "
                    ),
                    _c("br")
                  ])
                ])
              : _vm.allConversations.length && !_vm.currentChat.id
              ? _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("dashboard/assets/images/chat.svg"),
                      alt: "No Chat"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("CONVERSATION.404")))])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }