import { render, staticRenderFns } from "./InputRadioGroup.vue?vue&type=template&id=29e7074a&scoped=true&"
import script from "./InputRadioGroup.vue?vue&type=script&lang=js&"
export * from "./InputRadioGroup.vue?vue&type=script&lang=js&"
import style0 from "./InputRadioGroup.vue?vue&type=style&index=0&id=29e7074a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e7074a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('29e7074a')) {
      api.createRecord('29e7074a', component.options)
    } else {
      api.reload('29e7074a', component.options)
    }
    module.hot.accept("./InputRadioGroup.vue?vue&type=template&id=29e7074a&scoped=true&", function () {
      api.rerender('29e7074a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/inbox/components/InputRadioGroup.vue"
export default component.exports