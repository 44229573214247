var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "woot-sidebar" },
    [
      _c("primary-sidebar", {
        attrs: {
          "logo-source": _vm.globalConfig.logoThumbnail,
          "installation-name": _vm.globalConfig.installationName,
          "account-id": _vm.accountId,
          "menu-items": _vm.primaryMenuItems,
          "active-menu-item": _vm.activePrimaryMenu.key
        },
        on: {
          "toggle-accounts": _vm.toggleAccountModal,
          "key-shortcut-modal": _vm.toggleKeyShortcutModal
        }
      }),
      _vm._v(" "),
      _c("secondary-sidebar", {
        attrs: {
          "account-id": _vm.accountId,
          inboxes: _vm.inboxes,
          labels: _vm.labels,
          teams: _vm.teams,
          "custom-views": _vm.customViews,
          "menu-config": _vm.activeSecondaryMenu,
          "current-role": _vm.currentRole,
          subscriptionPlanId: _vm.currentUser.plan_id
        },
        on: { "add-label": _vm.showAddLabelPopup }
      }),
      _vm._v(" "),
      _vm.showShortcutModal
        ? _c("woot-key-shortcut-modal", {
            on: {
              close: _vm.closeKeyShortcutModal,
              clickaway: _vm.closeKeyShortcutModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("account-selector", {
        attrs: { "show-account-modal": _vm.showAccountModal },
        on: {
          "close-account-modal": _vm.toggleAccountModal,
          "show-create-account-modal": _vm.openCreateAccountModal
        }
      }),
      _vm._v(" "),
      _c("add-account-modal", {
        attrs: { show: _vm.showCreateAccountModal },
        on: { "close-account-create-modal": _vm.closeCreateAccountModal }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAddLabelModal,
            "on-close": _vm.hideAddLabelPopup
          },
          on: {
            "update:show": function($event) {
              _vm.showAddLabelModal = $event
            }
          }
        },
        [_c("add-label-modal", { on: { close: _vm.hideAddLabelPopup } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }