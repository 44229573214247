var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sessions-table-wrap" },
    [
      _c("ve-table", {
        attrs: { columns: _vm.columns, "table-data": _vm.tableData }
      }),
      _vm._v(" "),
      !_vm.isLoading && !_vm.sessions.length
        ? _c("empty-state", {
            attrs: { title: _vm.$t("CALENDAR_MGMT.LIST.404") }
          })
        : !_vm.isLoading && !_vm.sessions.length
        ? _c("empty-state", {
            attrs: { title: _vm.$t("CALENDAR_MGMT.LIST.NO_SESSION") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "contacts--loader" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("CALENDAR_MGMT.LIST.LOADING_MESSAGE")) +
                    " "
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }