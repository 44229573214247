var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card note-wrap" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        { staticClass: "meta" },
        [
          _c("thumbnail", {
            attrs: {
              title: _vm.noteAuthorName,
              src: _vm.noteAuthor.thumbnail,
              username: _vm.noteAuthorName,
              size: "20px"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "date-wrap" }, [
            _c("span", { staticClass: "fw-medium" }, [
              _vm._v(" " + _vm._s(_vm.noteAuthorName) + " ")
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.$t("NOTES.LIST.LABEL")) + " ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "fw-medium" }, [
              _vm._v(" " + _vm._s(_vm.readableTime) + " ")
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("woot-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("NOTES.CONTENT_HEADER.DELETE"),
                expression: "$t('NOTES.CONTENT_HEADER.DELETE')"
              }
            ],
            attrs: {
              variant: "smooth",
              size: "tiny",
              icon: "delete",
              "color-scheme": "secondary"
            },
            on: { click: _vm.onDelete }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("p", {
      staticClass: "note__content",
      domProps: { innerHTML: _vm._s(_vm.formatMessage(_vm.note || "")) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }