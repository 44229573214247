var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { id: _vm.node.id } },
    [
      _c(
        "foreignObject",
        {
          staticClass: "diagram-editor__foreign-object",
          attrs: {
            x: _vm.node.x + 2,
            y: _vm.node.y + 20,
            width: _vm.addMenuOpened ? 420 : 220,
            height: 120
          }
        },
        [
          _c(
            "body",
            {
              staticClass: "diagram-editor__node-body-custom",
              style: {
                background:
                  _vm.node.data.type === "action"
                    ? "#B2DBFF"
                    : _vm.node.data.type === "condition"
                    ? "#EDFFC0"
                    : _vm.node.data.type === "call-api"
                    ? "#FFD68A"
                    : _vm.node.data.type === "question"
                    ? "#E8A7D5"
                    : _vm.node.data.type === "stripe"
                    ? "#BFE3B4"
                    : "#FFFFFF",
                cursor: _vm.dragging ? "grabbing" : "pointer",
                height:
                  _vm.node.data.type === "stripe" ||
                  (_vm.node.data.contents && _vm.node.data.contents.length > 1)
                    ? "auto"
                    : "100%"
              },
              attrs: { xmlns: "http://www.w3.org/1999/xhtml" },
              on: { mousedown: _vm.onDragStart, mouseup: _vm.onDragEnd }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "word-wrap": "break-word",
                    width: "100%",
                    height: "100%",
                    overflow: "visible"
                  }
                },
                [
                  _c("div", [
                    _vm.node.data.type != "entry"
                      ? _c(
                          "svg",
                          {
                            staticClass:
                              "diagram-editor__delete-custom to-prevent",
                            attrs: { x: 1, y: "1", width: "14", height: "14" },
                            on: { click: _vm.deleteNode }
                          },
                          [
                            _c("rect", {
                              staticClass: "to-prevent",
                              attrs: {
                                x: "0",
                                y: "0",
                                width: "14",
                                height: "14",
                                rx: "2",
                                ry: "2"
                              }
                            }),
                            _vm._v(" "),
                            _c("line", {
                              staticClass: "to-prevent",
                              attrs: { x1: 1, y1: 1, x2: 10, y2: 10 }
                            }),
                            _vm._v(" "),
                            _c("line", {
                              staticClass: "to-prevent",
                              attrs: { x1: 10, y1: 1, x2: 1, y2: 10 }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.node.data.type == "entry"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "font-size": "16px",
                              "font-weight": "500",
                              "text-transform": "uppercase"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.node.data.customName
                                    ? _vm.node.data.customName
                                    : _vm.node.title
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "font-size": "12px",
                              "font-weight": "400"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.node.data.customName
                                    ? _vm.node.data.customName
                                    : _vm.node.title
                                ) +
                                "\n            "
                            )
                          ]
                        )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.node.data.contents, function(content, index) {
                    return _c("div", { key: index }, [
                      content.type == "text"
                        ? _c("div", { staticClass: "content-wrap" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getContentValue(content.value)) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "card"
                        ? _c("div", { staticClass: "content-wrap-card" }, [
                            content.type == "card"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "background-color": "white",
                                      padding: "12px",
                                      "border-radius": "12px",
                                      color: "black",
                                      "font-weight": "400",
                                      border: "solid 1px #f1f5f8",
                                      "align-items": "center",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    content.title
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "black" } },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "Card | " + content.title
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticStyle: { color: "grey" } },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("FLOW.EMPTY_CARD")
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "carousel"
                        ? _c("div", { staticClass: "content-wrap" }, [
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("FLOW.CAROUSEL")) +
                                  "\n              "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "catalog"
                        ? _c("div", { staticClass: "content-wrap" }, [
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("FLOW.CATALOG") +
                                      " : " +
                                      content.catalog_type.name
                                  ) +
                                  "\n              "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "image" && content.value != ""
                        ? _c(
                            "div",
                            {
                              staticClass: "content-wrap content-wrap-uploads"
                            },
                            [
                              _c("img", {
                                staticClass: "content-image-tag",
                                attrs: { src: _vm.getImgUrl(content.value) }
                              }),
                              _vm._v(" "),
                              content.title != ""
                                ? _c("span", { staticClass: "content-label" }, [
                                    _vm._v(_vm._s(content.title))
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "file" && content.value != ""
                        ? _c(
                            "div",
                            {
                              staticClass: "content-wrap content-wrap-uploads"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: { "font-size": "10px" },
                                  attrs: {
                                    href: _vm.getImgUrl(content.value),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        content.title
                                          ? "File : " + content.title
                                          : "File"
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "video" && content.value != ""
                        ? _c(
                            "div",
                            { staticClass: "content-wrap content-wrap-video" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("FLOW.VIDEO")) +
                                  "\n              | " +
                                  _vm._s(content.value) +
                                  "\n\n              "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-response"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "response",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show: false
                                  },
                                  expression:
                                    "{\n                content: getConditionString('response', content),\n                delay: { show: 2000, hide: 500 },\n                show: false,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString("response", content)
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-api-response"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "api-response",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show: false
                                  },
                                  expression:
                                    "{\n                content: getConditionString('api-response', content),\n                delay: { show: 2000, hide: 500 },\n                show: false,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString(
                                      "api-response",
                                      content
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-event_name"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "label",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show:
                                      _vm.getConditionString(
                                        "event_name",
                                        content
                                      ) > 26
                                  },
                                  expression:
                                    "{\n                content: getConditionString('label', content),\n                delay: { show: 2000, hide: 500 },\n                show: getConditionString('event_name', content) > 26,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString(
                                      "event_name",
                                      content
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-event_params"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "label",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show:
                                      _vm.getConditionString(
                                        "event_param",
                                        content
                                      ) > 26
                                  },
                                  expression:
                                    "{\n                content: getConditionString('label', content),\n                delay: { show: 2000, hide: 500 },\n                show: getConditionString('event_param', content) > 26,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString(
                                      "event_param",
                                      content
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-raw"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "raw",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show: false
                                  },
                                  expression:
                                    "{\n                content: getConditionString('raw', content),\n                delay: { show: 2000, hide: 500 },\n                show: false,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString("raw", content)
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-label"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "label",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show:
                                      _vm.getConditionString("label", content) >
                                      26
                                  },
                                  expression:
                                    "{\n                content: getConditionString('label', content),\n                delay: { show: 2000, hide: 500 },\n                show: getConditionString('label', content) > 26,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString("label", content)
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-variable"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "variable",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show:
                                      _vm.getConditionString(
                                        "variable",
                                        content
                                      ) > 26
                                  },
                                  expression:
                                    "{\n                content: getConditionString('variable', content),\n                delay: { show: 2000, hide: 500 },\n                show: getConditionString('variable', content) > 26,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString("variable", content)
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-contact"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.getConditionString(
                                      "contact",
                                      content
                                    ),
                                    delay: { show: 2000, hide: 500 },
                                    show:
                                      _vm.getConditionString(
                                        "contact",
                                        content
                                      ) > 26
                                  },
                                  expression:
                                    "{\n                content: getConditionString('contact', content),\n                delay: { show: 2000, hide: 500 },\n                show: getConditionString('contact', content) > 26,\n              }"
                                }
                              ],
                              staticClass: "condition-wrapper"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getConditionString("contact", content)
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "condition-group"
                        ? _c("div", { staticClass: "condition-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.GROUP_CONDITION")) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-setVariable"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  content.valueType.id == "response"
                                    ? "⚡ " +
                                        _vm.$t("FLOW.VARIABLE") +
                                        " " +
                                        content.name +
                                        " = " +
                                        _vm.$t("FLOW.RESPONSE")
                                    : "⚡ " +
                                        _vm.$t("FLOW.VARIABLE") +
                                        " " +
                                        content.name +
                                        " = " +
                                        _vm.$t("FLOW.VARIABLE") +
                                        " " +
                                        content.value
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-jsEval"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s("⚡ jsEval : " + content.value) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-setAPIVariable"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  content.valueType.id == "response"
                                    ? "⚡ " +
                                        _vm.$t("FLOW.API_RESPONSE_VARIABLE") +
                                        " " +
                                        content.name +
                                        " = " +
                                        _vm.$t("FLOW.RESPONSE")
                                    : "⚡ " +
                                        _vm.$t("FLOW.API_RESPONSE_VARIABLE") +
                                        " " +
                                        content.name +
                                        " = " +
                                        content.value
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-subscriptionPlans"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  content.valueType.id == "response"
                                    ? "⚡ API Response = " +
                                        _vm.$t("FLOW.RESPONSE")
                                    : "⚡ API Response = " +
                                        _vm.$t("FLOW.VARIABLE") +
                                        " " +
                                        content.value
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-label"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  "⚡ " +
                                    content.action.name +
                                    " : " +
                                    content.name
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-contact"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getActionString(content)) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      content.type == "action-status"
                        ? _c("div", { staticClass: "action-wrapper" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  "⚡ Change Status" +
                                    " : " +
                                    content.value.name
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  }),
                  _vm._v(" "),
                  _vm.node.data.waitForUserMessage == true
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px",
                            "background-color": "white",
                            margin: "5px",
                            "border-radius": "4px",
                            border: "1px solid #D2D2D2",
                            color: "#CECECE"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "16px" },
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/wait-for-user-input-icon.png")
                            }
                          }),
                          _vm._v(
                            "\n\n            " +
                              _vm._s(_vm.$t("FLOW.WAIT_FOR_USER_MESSAGE")) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type == "condition" &&
                  _vm.node.data.contents.length == 1
                    ? _c("div", { staticClass: "condition-wrapper" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("FLOW.ALWAYS")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type == "condition" &&
                  _vm.node.data.contents.length > 1
                    ? _c("div", { staticClass: "condition-wrapper" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("FLOW.OTHERWISE")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type === "call-api"
                    ? _c("div", { staticClass: "call-api-wrapper" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("FLOW.ON_SUCCESS")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type === "call-api"
                    ? _c("div", { staticClass: "call-api-wrapper" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("FLOW.ON_FAILURE")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type === "stripe"
                    ? _c("div", { staticClass: " stripe-wrapper" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("FLOW.ON_SUCCESS")) +
                            "\n\n            "
                        ),
                        _vm.node.data.contents.length > 0
                          ? _c("div", { staticStyle: { color: "black" } }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.node.data.contents[0].successUrl) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type === "question"
                    ? _c(
                        "div",
                        _vm._l(_vm.node.data.subNodes, function(
                          subNode,
                          subNodeIndex
                        ) {
                          return _c(
                            "div",
                            { key: subNodeIndex },
                            _vm._l(subNode.data.contents, function(
                              content,
                              contentIndex
                            ) {
                              return _c("div", { key: contentIndex }, [
                                content.type == "card"
                                  ? _c(
                                      "div",
                                      { staticClass: "content-wrap-card" },
                                      [
                                        content.type == "card"
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "background-color": "white",
                                                  padding: "12px",
                                                  "border-radius": "12px",
                                                  color: "black",
                                                  "font-weight": "400",
                                                  border: "solid 1px #f1f5f8",
                                                  "align-items": "center",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                content.title
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "black"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              "Card | " +
                                                                content.title
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "grey"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "FLOW.EMPTY_CARD"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.endFlowAction == true
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px",
                            "background-color": "white",
                            margin: "5px",
                            "border-radius": "4px",
                            border: "1px solid #D2D2D2",
                            color: "#CECECE"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "16px",
                              filter: "opacity(0.2)"
                            },
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/end-flow-sign.png")
                            }
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("FLOW.END_FLOW")) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.node.data.type == "entry"
                    ? _c("div", { staticClass: "start-placeholder" }, [
                        _c("h6", { staticClass: "text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("FLOW.START_NODE_PLACEHOLDER")) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "float-variant to-prevent",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.addButtonClicked($event)
                    }
                  }
                },
                [
                  _c("img", {
                    class: [
                      _vm.showPopup
                        ? "image-float-small image-small-float-variant to-prevent"
                        : "image-float-small image-small-float to-prevent"
                    ],
                    attrs: {
                      src: require("dashboard/assets/images/flow-new/add-icon.png"),
                      width: "12"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _vm.showPopup
                ? _c(
                    "div",
                    {
                      staticClass: "float-box-small to-prevent",
                      staticStyle: { cursor: "default" }
                    },
                    [
                      _c("h4", { staticClass: "title to-prevent" }, [
                        _vm._v(_vm._s(_vm.$t("FLOW.ADD_NODE")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item to-prevent",
                          staticStyle: {
                            "background-color": "white",
                            "margin-bottom": "5px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.menuItemClick("content")
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "to-prevent",
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/menu-icon-content.svg")
                            }
                          }),
                          _vm._v(" "),
                          _c("h6", { staticClass: "text to-prevent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.NODE_ADD_CONTENT")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item to-prevent",
                          staticStyle: {
                            "background-color": "#EDFFC0",
                            "margin-bottom": "5px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.menuItemClick("condition")
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "to-prevent",
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/menu-icon-condition.svg")
                            }
                          }),
                          _vm._v(" "),
                          _c("h6", { staticClass: "text to-prevent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.NODE_ADD_CONDITION")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item to-prevent",
                          staticStyle: {
                            "background-color": "#B2DBFF",
                            "margin-bottom": "5px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.menuItemClick("action")
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "to-prevent",
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/menu-icon-action.svg")
                            }
                          }),
                          _vm._v(" "),
                          _c("h6", { staticClass: "text to-prevent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.NODE_ADD_ACTION")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item to-prevent",
                          staticStyle: {
                            "background-color": "#FFD68A",
                            "margin-bottom": "5px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.menuItemClick("call-api")
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "to-prevent",
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/api-call.svg")
                            }
                          }),
                          _vm._v(" "),
                          _c("h6", { staticClass: "text to-prevent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.NODE_ADD_CALL_API")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item to-prevent",
                          staticStyle: {
                            "background-color": "#E8A7D5",
                            "margin-bottom": "5px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.menuItemClick("question")
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "to-prevent",
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/api-call.svg")
                            }
                          }),
                          _vm._v(" "),
                          _c("h6", { staticClass: "text to-prevent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.NODE_ADD_QUESTION")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "item to-prevent",
                          staticStyle: { "background-color": "#BFE3B4" },
                          on: {
                            click: function($event) {
                              return _vm.menuItemClick("stripe")
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "to-prevent",
                            attrs: {
                              src: require("dashboard/assets/images/flow-new/api-call.svg")
                            }
                          }),
                          _vm._v(" "),
                          _c("h6", { staticClass: "text to-prevent" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("FLOW.STRIPE")) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span")
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            x: _vm.node.x - 4,
            y: _vm.node.y + 33,
            width: _vm.node.width > 200 ? _vm.node.width + 8 : 220
          }
        },
        [
          _vm._l(_vm.portsIn, function(ref, portIndex) {
            var port = ref[0]
            var portTitle = ref[1]
            return _c("DiagramPort", {
              key: _vm.node.id + "_in_" + port,
              attrs: {
                id: _vm.node.id + "_in_" + port,
                title: portTitle,
                width:
                  portIndex + 1 > _vm.portsOut.length
                    ? _vm.node.width
                    : _vm.node.width / 2,
                x: 22,
                y: portIndex * 30 + 43,
                disabled: _vm.portsInDisabled[port],
                available: _vm.portsInAvailable[port],
                hovered: _vm.isHoveredPort("in", port),
                align: "start"
              },
              on: {
                mousedown: function($event) {
                  return _vm.mouseDownPort("in", port, $event)
                },
                mouseenter: function($event) {
                  return _vm.mouseEnterPort("in", port, $event)
                },
                mouseleave: function($event) {
                  return _vm.mouseLeavePort("in", port, $event)
                }
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.portsOutList, function(ref, portIndex) {
            var port = ref[0]
            var portTitle = ref[1]
            return _c("DiagramPort", {
              key: _vm.node.id + "_out_" + port,
              attrs: {
                id: _vm.node.id + "_out_" + port,
                title: portTitle,
                width: _vm.node.width > 200 ? _vm.node.width + 8 : 210,
                x: 2,
                y: portIndex * 30 + 43,
                disabled: _vm.portsOutDisabled[port],
                available: _vm.portsOutAvailable[port],
                hovered: _vm.isHoveredPort("out", port),
                align: "end"
              },
              on: {
                mousedown: function($event) {
                  return _vm.mouseDownPort("out", port, $event)
                },
                mouseenter: function($event) {
                  return _vm.mouseEnterPort("out", port, $event)
                },
                mouseleave: function($event) {
                  return _vm.mouseLeavePort("out", port, $event)
                }
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }