var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "div",
        { staticClass: "top-right" },
        [
          _c(
            "woot-button",
            {
              attrs: {
                "color-scheme": "success",
                "class-names": "button--template",
                icon: "add-circle",
                disabled: _vm.showAddTemplateWithPreview
              },
              on: {
                click: function($event) {
                  return _vm.openAddTemplateWithPreview()
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CANNED_MGMT.TEMPLATE_BTN_TXT")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              attrs: {
                "color-scheme": "success",
                "class-names": "button--template",
                icon: "add-circle"
              },
              on: {
                click: function($event) {
                  return _vm.openAddPopup()
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CANNED_MGMT.HEADER_BTN_TXT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAddTemplateWithPreview
        ? _c("AddTemplateWithPreview", {
            attrs: { "on-close": _vm.hideAddTemplateWithPreview }
          })
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "small-8 columns with-right-space " },
              [
                _c("div", { staticClass: "sub_tabs" }, [
                  _c("div", [
                    _c(
                      "ul",
                      { staticClass: "tabs" },
                      _vm._l(_vm.tabs, function(tab) {
                        return _c(
                          "li",
                          {
                            key: tab.key,
                            staticClass: "tabs-title",
                            class:
                              tab.code === _vm.selectedTab ? "is-active" : "",
                            on: {
                              click: function($event) {
                                return _vm.onSubTabChange(tab.code)
                              }
                            }
                          },
                          [_c("a", [_vm._v(_vm._s(tab.name))])]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _vm.isInboxShow
                    ? _c(
                        "div",
                        [
                          _c("formulate-input", {
                            attrs: {
                              options: _vm.inboxList,
                              type: "select",
                              name: "inbox"
                            },
                            model: {
                              value: _vm.selectedInboxId,
                              callback: function($$v) {
                                _vm.selectedInboxId = $$v
                              },
                              expression: "selectedInboxId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                !_vm.uiFlags.fetchingList && !_vm.filtered_records.length
                  ? _c("p", { staticClass: "no-items-error-message" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CANNED_MGMT.LIST.404")) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.uiFlags.fetchingList
                  ? _c("woot-loading-state", {
                      attrs: { message: _vm.$t("CANNED_MGMT.LOADING") }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.uiFlags.fetchingList && _vm.filtered_records.length
                  ? _c("table", { staticClass: "woot-table" }, [
                      _vm.isInboxShow
                        ? _c(
                            "thead",
                            _vm._l(
                              _vm.$t("CANNED_MGMT.LIST.TABLE_HEADER_TEMPLATE"),
                              function(thHeader) {
                                return _c("th", { key: thHeader }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(thHeader) +
                                      "\n          "
                                  )
                                ])
                              }
                            ),
                            0
                          )
                        : _c(
                            "thead",
                            _vm._l(
                              _vm.$t("CANNED_MGMT.LIST.TABLE_HEADER"),
                              function(thHeader) {
                                return _c("th", { key: thHeader }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(thHeader) +
                                      "\n          "
                                  )
                                ])
                              }
                            ),
                            0
                          ),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.filtered_records, function(
                          cannedItem,
                          index
                        ) {
                          return _c("tr", { key: cannedItem.short_code }, [
                            _c("td", { staticClass: "short-code" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(cannedItem.short_code) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(cannedItem.content))]),
                            _vm._v(" "),
                            _vm.isInboxShow
                              ? _c("td", { staticClass: "short-code" }, [
                                  _vm._v(_vm._s(cannedItem.inboxName))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "button-wrapper" },
                              [
                                cannedItem.is_template
                                  ? _c("span", {
                                      staticClass: "empty-canned-edit-btn"
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !cannedItem.is_template
                                  ? _c(
                                      "span",
                                      [
                                        !cannedItem.is_template
                                          ? _c("woot-button", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip.top",
                                                  value: _vm.$t(
                                                    "CANNED_MGMT.EDIT.BUTTON_TEXT"
                                                  ),
                                                  expression:
                                                    "$t('CANNED_MGMT.EDIT.BUTTON_TEXT')",
                                                  modifiers: { top: true }
                                                }
                                              ],
                                              attrs: {
                                                variant: "smooth",
                                                size: "small",
                                                "color-scheme": "secondary",
                                                icon: "edit"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openEditPopup(
                                                    cannedItem
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("woot-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t(
                                        "CANNED_MGMT.DELETE.BUTTON_TEXT"
                                      ),
                                      expression:
                                        "$t('CANNED_MGMT.DELETE.BUTTON_TEXT')",
                                      modifiers: { top: true }
                                    }
                                  ],
                                  attrs: {
                                    variant: "smooth",
                                    "color-scheme": "alert",
                                    size: "small",
                                    icon: "dismiss-circle",
                                    "class-names": "grey-btn btn-width-auto",
                                    "is-loading": _vm.loading[cannedItem.id]
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDeletePopup(
                                        cannedItem,
                                        index
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                cannedItem.is_template
                                  ? _c(
                                      "div",
                                      { staticClass: "indicator" },
                                      [
                                        _c("status-indicator", {
                                          attrs: {
                                            status: _vm.cannedItemStatus(
                                              cannedItem.template_status
                                            ),
                                            pulse: ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "small-4 columns" }, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("CANNED_MGMT.SIDEBAR_TXT"))
                }
              })
            ])
          ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [_c("add-canned", { attrs: { "on-close": _vm.hideAddPopup } })],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _vm.showEditPopup
            ? _c("edit-canned", {
                attrs: {
                  id: _vm.selectedResponse.id,
                  "edshort-code": _vm.selectedResponse.short_code,
                  edcontent: _vm.selectedResponse.content,
                  "on-close": _vm.hideEditPopup
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("CANNED_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": _vm.deleteConfirmText,
          "reject-text": _vm.deleteRejectText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }