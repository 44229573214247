var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSecondaryMenu
    ? _c(
        "div",
        { staticClass: "main-nav secondary-menu" },
        [
          _c("account-context"),
          _vm._v(" "),
          _c(
            "transition-group",
            {
              staticClass: "menu vertical",
              attrs: { name: "menu-list", tag: "ul" }
            },
            [
              _vm._l(_vm.accessibleMenuItems, function(menuItem) {
                return _c("secondary-nav-item", {
                  key: menuItem.toState,
                  attrs: { "menu-item": menuItem }
                })
              }),
              _vm._v(" "),
              _vm._l(
                _vm.additionalSecondaryMenuItems[_vm.menuConfig.parentNav],
                function(menuItem) {
                  return _c("secondary-nav-item", {
                    key: menuItem.key,
                    attrs: { "menu-item": menuItem },
                    on: { "add-label": _vm.showAddLabelPopup }
                  })
                }
              )
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }