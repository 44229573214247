var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings-header" }, [
    _c(
      "h1",
      { staticClass: "page-title" },
      [
        _c("woot-sidemenu-icon"),
        _vm._v(" "),
        _vm.showBackButton
          ? _c("back-button", {
              attrs: {
                "button-label": _vm.backButtonLabel,
                "back-url": _vm.backUrl
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.icon
          ? _c("fluent-icon", {
              class: _vm.iconClass,
              attrs: { icon: _vm.icon }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.headerTitle))])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }