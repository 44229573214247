var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mention-box", {
    attrs: { items: _vm.items, "top-rem": _vm.top },
    on: { "mention-select": _vm.handleMentionClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }