export const demoShowList = [
  {
    icon:"filter-lead",
    constant_name:'lead_qualification',
    title:"Lead Qualification",
    description:'Use our chatbot to qualify new incoming leads, before delegating it to your sales team.',
    videoUrl:"https://www.youtube.com/embed/oQgchyAgnHE?autoplay=1&loop=1&autopause=0&muted=1&enable_js=1",
  },
  {
    icon:"calendar-rtl",
    constant_name:'appointment_scheduling',
    title:"Appointment Scheduling",
    description:'Use Peasy to automatically schedule and manage appointments, send reminders.',
    videoUrl:"https://www.youtube.com/embed/GVR1whYGgmc?autoplay=1&loop=1&autopause=0&muted=1&enable_js=1",
  },
  
];

