var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "input-group-wrap" }, [
      _c(
        "div",
        {
          staticClass: "input-group small",
          class: { error: _vm.$v.ccEmailsVal.$error }
        },
        [
          _c("label", { staticClass: "input-group-label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.LABEL")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group-field" },
            [
              _c("woot-input", {
                class: { error: _vm.$v.ccEmailsVal.$error },
                attrs: {
                  type: "email",
                  placeholder: _vm.$t(
                    "CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER"
                  )
                },
                on: { blur: _vm.onBlur },
                model: {
                  value: _vm.$v.ccEmailsVal.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.ccEmailsVal,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.ccEmailsVal.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.showBcc
            ? _c(
                "woot-button",
                {
                  attrs: { variant: "clear", size: "small" },
                  on: { click: _vm.handleAddBcc }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("CONVERSATION.REPLYBOX.EMAIL_HEAD.ADD_BCC")
                      ) +
                      "\n      "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.$v.ccEmailsVal.$error
        ? _c("span", { staticClass: "message" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.ERROR")) +
                "\n    "
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.showBcc
      ? _c("div", { staticClass: "input-group-wrap" }, [
          _c(
            "div",
            {
              staticClass: "input-group small",
              class: { error: _vm.$v.bccEmailsVal.$error }
            },
            [
              _c("label", { staticClass: "input-group-label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.LABEL")
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-group-field" },
                [
                  _c("woot-input", {
                    class: { error: _vm.$v.bccEmailsVal.$error },
                    attrs: {
                      type: "email",
                      placeholder: _vm.$t(
                        "CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.onBlur },
                    model: {
                      value: _vm.$v.bccEmailsVal.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.bccEmailsVal,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.bccEmailsVal.$model"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.$v.bccEmailsVal.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.ERROR")
                    ) +
                    "\n    "
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }