var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button",
      class: _vm.buttonClasses,
      attrs: { disabled: _vm.isDisabled || _vm.isLoading },
      on: { click: _vm.handleClick }
    },
    [
      _vm.isLoading
        ? _c("spinner", { attrs: { size: "small" } })
        : _vm.icon || _vm.emoji
        ? _c("emoji-or-icon", {
            staticClass: "icon",
            attrs: {
              emoji: _vm.emoji,
              icon: _vm.icon,
              "icon-size": _vm.iconSize
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.default
        ? _c("span", { staticClass: "button__content" }, [_vm._t("default")], 2)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }