var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _vm.isIntegrationPeasy
        ? _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("INTEGRATION_APPS.PEASY.HEADER"),
              "header-content": _vm.$t("INTEGRATION_APPS.PEASY.DESCRIPTION")
            }
          })
        : _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.integration.name,
              "header-content": _vm.integration.description
            }
          }),
      _vm._v(" "),
      _c("formulate-form", {
        on: { submit: _vm.submitForm },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hasErrors = ref.hasErrors
              return [
                _vm._l(_vm.formItems, function(item) {
                  return _c(
                    "formulate-input",
                    _vm._b(
                      {
                        key: item.name,
                        attrs: { value: item.value, disabled: item.disable }
                      },
                      "formulate-input",
                      item,
                      false
                    )
                  )
                }),
                _vm._v(" "),
                _vm.integration.id !== "chatgpt" &&
                _vm.integration.id !== "stripe"
                  ? _c(
                      "label",
                      {
                        staticClass: "catalog-label-text",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.$t("FLOW.SELECT_BRAND_PROFILE")) +
                            "\n      "
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedBrandProfile,
                                expression: "selectedBrandProfile"
                              }
                            ],
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedBrandProfile = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.OnBrandProfileChanged()
                                }
                              ]
                            }
                          },
                          _vm._l(_vm.brandProfiles, function(item) {
                            return _c(
                              "option",
                              { key: item.id, domProps: { value: item } },
                              [_vm._v(_vm._s(item.brand_name))]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.integration.id === "stripe"
                  ? _c("formulate-input", {
                      attrs: {
                        type: "text",
                        name: "secret_api_key",
                        placeholder: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.SECRET_API_KEY.PLACEHOLDER"
                        ),
                        label: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.SECRET_API_KEY.LABEL"
                        ),
                        validation: "required"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.integration.id === "stripe"
                  ? _c("formulate-input", {
                      attrs: {
                        type: "text",
                        name: "publishable_key",
                        placeholder: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.PUBLISHABLE_KEY.PLACEHOLDER"
                        ),
                        label: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.PUBLISHABLE_KEY.LABEL"
                        ),
                        validation: "required"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.integration.id === "stripe"
                  ? _c("formulate-input", {
                      attrs: {
                        options: _vm.currencies,
                        type: "select",
                        name: "currency",
                        placeholder: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.CURRENCY.LABEL"
                        ),
                        label: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.CURRENCY.PLACEHOLDER"
                        ),
                        validation: "required",
                        "validation-name": "Currency"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.integration.id === "chatgpt"
                  ? _c("formulate-input", {
                      attrs: {
                        type: "text",
                        name: "api_key",
                        placeholder: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.API_KEY.PLACEHOLDER"
                        ),
                        label: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.API_KEY.LABEL"
                        ),
                        validation: "required"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isIntegrationPeasy
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("INTEGRATION_APPS.PEASY.PEASY_WEBHOOK_URL", {
                            baseUrl: _vm.basePath
                          })
                        )
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          disabled: hasErrors,
                          loading: _vm.uiFlags.isCreatingHook
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("INTEGRATION_APPS.ADD.FORM.SUBMIT"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("INTEGRATION_APPS.ADD.FORM.CANCEL"))
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.values,
          callback: function($$v) {
            _vm.values = $$v
          },
          expression: "values"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }