var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "conversation",
      class: {
        active: _vm.isActiveChat,
        "unread-chat": _vm.hasUnread,
        "has-inbox-name": _vm.showInboxName
      },
      on: {
        click: function($event) {
          return _vm.cardClick(_vm.chat)
        }
      }
    },
    [
      !_vm.hideThumbnail
        ? _c("thumbnail", {
            staticClass: "columns",
            attrs: {
              src: _vm.currentContact.thumbnail,
              badge: _vm.inboxBadge,
              username: _vm.currentContact.name,
              status: _vm.currentContact.availability_status,
              size: "40px"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "conversation--details columns" }, [
        _c(
          "div",
          { staticClass: "conversation--metadata" },
          [
            _vm.showInboxName
              ? _c("inbox-name", { attrs: { inbox: _vm.inbox } })
              : _vm._e(),
            _vm._v(" "),
            _vm.showAssignee && _vm.assignee.name
              ? _c(
                  "span",
                  { staticClass: "label assignee-label text-truncate" },
                  [
                    _c("fluent-icon", {
                      attrs: { icon: "person", size: "12" }
                    }),
                    _vm._v(
                      "\n        " + _vm._s(_vm.assignee.name) + "\n      "
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("h4", { staticClass: "conversation--user" }, [
          _vm._v("\n      " + _vm._s(_vm.currentContact.name) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.lastMessageInChat
          ? _c(
              "p",
              { staticClass: "conversation--message" },
              [
                _vm.isMessagePrivate
                  ? _c("fluent-icon", {
                      staticClass: "message--attachment-icon last-message-icon",
                      attrs: { size: "16", icon: "lock-closed" }
                    })
                  : _vm.messageByAgent
                  ? _c("fluent-icon", {
                      staticClass: "message--attachment-icon last-message-icon",
                      attrs: { size: "16", icon: "arrow-reply" }
                    })
                  : _vm.isMessageAnActivity
                  ? _c("fluent-icon", {
                      staticClass: "message--attachment-icon last-message-icon",
                      attrs: { size: "16", icon: "info" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.lastMessageInChat.content
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.parsedLastMessage) +
                          "\n      "
                      )
                    ])
                  : _vm.lastMessageInChat.attachments
                  ? _c(
                      "span",
                      [
                        _vm.attachmentIcon
                          ? _c("fluent-icon", {
                              staticClass: "message--attachment-icon",
                              attrs: { size: "16", icon: _vm.attachmentIcon }
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n        " +
                            _vm._s(this.$t("" + _vm.attachmentMessageContent)) +
                            "\n      "
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CHAT_LIST.NO_CONTENT")) +
                          "\n      "
                      )
                    ])
              ],
              1
            )
          : _c(
              "p",
              { staticClass: "conversation--message" },
              [
                _c("fluent-icon", {
                  staticClass: "message--attachment-icon",
                  attrs: { size: "16", icon: "info" }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$t("CHAT_LIST.NO_MESSAGES")) +
                      "\n      "
                  )
                ])
              ],
              1
            ),
        _vm._v(" "),
        _c("div", { staticClass: "conversation--meta" }, [
          _c("span", { staticClass: "timestamp" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.dynamicTime(_vm.chat.timestamp)) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "unread" }, [
            _vm._v(_vm._s(_vm.unreadCount > 9 ? "9+" : _vm.unreadCount))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }