var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          padding: "8px",
          "padding-left": "24px",
          "padding-right": "24px",
          color: "grey",
          "align-items": "center"
        }
      },
      [_c("h5", [_vm._v(_vm._s(_vm.$t("FLOW.EDIT_CARD")))])]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.CARD_TITLE")) +
                    "\n\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.card.title,
                      expression: "card.title"
                    }
                  ],
                  staticClass: "w3-input",
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("FLOW.PLACEHOLDERS.TITLE")
                  },
                  domProps: { value: _vm.card.title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.card, "title", $event.target.value)
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "1.6em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.CARD_SUBTITLE")) +
                    "\n\n          "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.card.subtitle,
                      expression: "card.subtitle"
                    }
                  ],
                  staticClass: "w3-input",
                  staticStyle: { margin: "0" },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("FLOW.PLACEHOLDERS.SUBTITLE")
                  },
                  domProps: { value: _vm.card.subtitle },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.card, "subtitle", $event.target.value)
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content-image-input" }, [
            _vm.card.file == ""
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _vm.isUploading
                      ? _c("woot-loading-state", {
                          attrs: { message: "Uploading..." }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isUploading
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              "align-items": "center",
                              "justify-content": "center"
                            }
                          },
                          [
                            _c("div", { staticClass: "upload-btn-wrapper" }, [
                              _c("button", { staticClass: "btn" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("FLOW.UPLOAD_FILE")) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                attrs: {
                                  type: "file",
                                  name: "myfile",
                                  accept: "video/*,image/*,application/pdf"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleFileUpload($event)
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("FLOW.OR")) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "w3-input",
                              staticStyle: { margin: "0" },
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t(
                                  "FLOW.PLACEHOLDERS.PASTE_FILE_URL"
                                )
                              },
                              on: { input: _vm.handleInputChange }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.isUploading
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "12px" } },
                          [
                            _c(
                              "woot-button",
                              {
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.uploadFromUrl()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("FLOW.UPLOAD")) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "5px",
                      "background-color": "#e1e5ea",
                      margin: "5px",
                      "border-radius": "5px",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      "flex-direction": "column"
                    }
                  },
                  [
                    _vm.isImage(_vm.card.file)
                      ? _c("img", {
                          staticStyle: {
                            "object-fit": "contain",
                            "max-height": "120px"
                          },
                          attrs: { src: _vm.getFileUrl(_vm.card.file) }
                        })
                      : _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getFileUrl(_vm.card.file),
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "\n            🔗 " +
                                _vm._s(_vm.$t("FLOW.FILE")) +
                                "\n          "
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "delete",
                        on: {
                          click: function($event) {
                            return _vm.deleteImage()
                          }
                        }
                      },
                      [_c("FluentIcon", { attrs: { icon: "dismiss" } })],
                      1
                    )
                  ]
                )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "0em"
              }
            },
            [
              _c("label", { staticStyle: { width: "100%" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("FLOW.ACTION_BUTTONS")) +
                    "\n\n          "
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#f6f6f6",
                      "min-height": "100px",
                      "border-radius": "10px",
                      "margin-bottom": "12px",
                      padding: "12px"
                    }
                  },
                  _vm._l(_vm.actionButtons, function(
                    singleActionButton,
                    index
                  ) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "margin-bottom": "1.6em",
                            "align-content": "center"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: singleActionButton.buttonTitle,
                                expression: "singleActionButton.buttonTitle"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "FLOW.PLACEHOLDERS.BUTTON_TITLE"
                              )
                            },
                            domProps: { value: singleActionButton.buttonTitle },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  singleActionButton,
                                  "buttonTitle",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: singleActionButton.selectedAction,
                                  expression:
                                    "singleActionButton.selectedAction"
                                }
                              ],
                              staticClass: "operator__select",
                              staticStyle: {
                                "margin-left": "5px",
                                "margin-right": "5px"
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    singleActionButton,
                                    "selectedAction",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.actionButtonOptions, function(item) {
                              return _c(
                                "option",
                                { key: item.id, domProps: { value: item } },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(item.name) +
                                      "\n                  "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: singleActionButton.buttonValue,
                                expression: "singleActionButton.buttonValue"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("FLOW.PLACEHOLDERS.VALUE")
                            },
                            domProps: { value: singleActionButton.buttonValue },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  singleActionButton,
                                  "buttonValue",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.deleteItem(index)
                                }
                              }
                            },
                            [_c("FluentIcon", { attrs: { icon: "dismiss" } })],
                            1
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "5px", "margin-bottom": "10px" } },
            [
              _c("div", { staticClass: "custom-btn-wrapper" }, [
                _c(
                  "button",
                  { staticClass: "btn", on: { click: _vm.addNewActionButton } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("dashboard/assets/images/flow-new/btn-add.png")
                      }
                    }),
                    _vm._v(
                      "\n\n            " +
                        _vm._s(_vm.$t("FLOW.ACTION_BUTTON")) +
                        "\n          "
                    )
                  ]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "woot-button",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.allDone($event)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("FLOW.DONE")) + "\n      ")]
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                attrs: { variant: "clear" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClose($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("FLOW.CANCEL")) + "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }