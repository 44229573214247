var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeDropdownLabel,
          expression: "closeDropdownLabel"
        }
      ],
      staticClass: "label-wrap"
    },
    [
      _c("add-label", { on: { add: _vm.toggleLabels } }),
      _vm._v(" "),
      _vm._l(_vm.savedLabels, function(label) {
        return _c("woot-label", {
          key: label.id,
          attrs: {
            title: label.title,
            description: label.description,
            "show-close": true,
            "bg-color": label.color
          },
          on: { click: _vm.removeItem }
        })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-wrap" }, [
        _c(
          "div",
          {
            staticClass: "dropdown-pane",
            class: { "dropdown-pane--open": _vm.showSearchDropdownLabel }
          },
          [
            _vm.showSearchDropdownLabel
              ? _c("label-dropdown", {
                  attrs: {
                    "account-labels": _vm.allLabels,
                    "selected-labels": _vm.selectedLabels
                  },
                  on: { add: _vm.addItem, remove: _vm.removeItem }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }