var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns onboarding-wrap" }, [
    _c("div", { staticClass: "onboarding" }, [
      _c("div", { staticClass: "scroll-wrap" }, [
        _c("div", { staticClass: "features-item" }, [
          _c("h1", { staticClass: "page-title" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("ONBOARDING.TITLE", {
                    installationName: _vm.globalConfig.installationName
                  })
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "intro-body" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t("ONBOARDING.DESCRIPTION", {
                    installationName: _vm.globalConfig.installationName
                  })
                ) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _vm.globalConfig.installationName === "Chatwoot"
            ? _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "onboarding--link",
                    attrs: {
                      href: "https://www.peasy.ai/changelog",
                      target: "_blank",
                      rel: "noopener nofollow noreferrer"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("ONBOARDING.READ_LATEST_UPDATES")) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("🎉")])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "features-item" }, [
          _c("h2", { staticClass: "block-title" }, [
            _c("span", { staticClass: "emoji" }, [_vm._v("💬")]),
            _vm._v(" "),
            _c("span", { staticClass: "conversation--title" }, [
              _vm._v(_vm._s(_vm.$t("ONBOARDING.ALL_CONVERSATION.TITLE")))
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "intro-body" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("ONBOARDING.ALL_CONVERSATION.DESCRIPTION")) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "features-item" },
          [
            _c("h2", { staticClass: "block-title" }, [
              _c("span", { staticClass: "emoji" }, [_vm._v("👥")]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("ONBOARDING.TEAM_MEMBERS.TITLE")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "intro-body" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("ONBOARDING.TEAM_MEMBERS.DESCRIPTION")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "onboarding--link",
                attrs: { to: _vm.newAgentURL }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("ONBOARDING.TEAM_MEMBERS.NEW_LINK")) +
                    "\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "features-item" },
          [
            _c("h2", { staticClass: "block-title" }, [
              _c("span", { staticClass: "emoji" }, [_vm._v("📥")]),
              _vm._v(_vm._s(_vm.$t("ONBOARDING.INBOXES.TITLE")) + "\n        ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "intro-body" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("ONBOARDING.INBOXES.DESCRIPTION")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "onboarding--link",
                attrs: { to: _vm.newInboxURL }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("ONBOARDING.INBOXES.NEW_LINK")) +
                    "\n        "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "features-item" },
          [
            _c("h2", { staticClass: "block-title" }, [
              _c("span", { staticClass: "emoji" }, [_vm._v("🔖")]),
              _vm._v(_vm._s(_vm.$t("ONBOARDING.LABELS.TITLE")) + "\n        ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "intro-body" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("ONBOARDING.LABELS.DESCRIPTION")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "onboarding--link",
                attrs: { to: _vm.newLabelsURL }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("ONBOARDING.LABELS.NEW_LINK")) +
                    "\n        "
                )
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }