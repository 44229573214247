var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "small-8 columns with-right-space " }, [
          !_vm.teamsList.length
            ? _c(
                "p",
                { staticClass: "no-items-error-message" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("TEAMS_SETTINGS.LIST.404")) +
                      "\n        "
                  ),
                  _vm.isAdmin
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: _vm.addAccountScoping("settings/teams/new")
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("TEAMS_SETTINGS.NEW_TEAM")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.teamsList.length
            ? _c("table", { staticClass: "woot-table" }, [
                _c(
                  "tbody",
                  _vm._l(_vm.teamsList, function(item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [
                        _c("span", { staticClass: "agent-name" }, [
                          _vm._v(_vm._s(item.name))
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(item.description))])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "button-wrapper btn-width-auto" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.addAccountScoping(
                                    "settings/teams/" + item.id + "/edit"
                                  )
                                }
                              },
                              [
                                _vm.isAdmin
                                  ? _c("woot-button", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: _vm.$t(
                                            "TEAMS_SETTINGS.LIST.EDIT_TEAM"
                                          ),
                                          expression:
                                            "$t('TEAMS_SETTINGS.LIST.EDIT_TEAM')",
                                          modifiers: { top: true }
                                        }
                                      ],
                                      attrs: {
                                        variant: "smooth",
                                        size: "small",
                                        "color-scheme": "secondary",
                                        "class-names": "grey-btn",
                                        icon: "settings"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isAdmin
                              ? _c("woot-button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t(
                                        "TEAMS_SETTINGS.DELETE.BUTTON_TEXT"
                                      ),
                                      expression:
                                        "$t('TEAMS_SETTINGS.DELETE.BUTTON_TEXT')",
                                      modifiers: { top: true }
                                    }
                                  ],
                                  attrs: {
                                    variant: "smooth",
                                    "color-scheme": "alert",
                                    size: "small",
                                    icon: "dismiss-circle",
                                    "class-names": "grey-btn",
                                    "is-loading": _vm.loading[item.id]
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDelete(item)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("TEAMS_SETTINGS.SIDEBAR_TXT", {
                  installationName: _vm.globalConfig.installationName
                })
              )
            }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.showDeletePopup
        ? _c("woot-confirm-delete-modal", {
            attrs: {
              show: _vm.showDeletePopup,
              title: _vm.confirmDeleteTitle,
              message: _vm.$t("TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE"),
              "confirm-text": _vm.deleteConfirmText,
              "reject-text": _vm.deleteRejectText,
              "confirm-value": _vm.selectedTeam.name,
              "confirm-place-holder-text": _vm.confirmPlaceHolderText
            },
            on: {
              "update:show": function($event) {
                _vm.showDeletePopup = $event
              },
              "on-confirm": _vm.confirmDeletion,
              "on-close": _vm.closeDelete
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }