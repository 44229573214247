var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("CAMPAIGN.ADD.TITLE"),
          "header-content": _vm.$t("CAMPAIGN.ADD.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("label", { class: { error: _vm.$v.selectedInbox.$error } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "end"
                    },
                    style: _vm.isEmailSelectedInbox ? "margin-bottom: 10px" : ""
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.INBOX.LABEL")) +
                        "\n          "
                    ),
                    _vm.isEmailSelectedInbox
                      ? _c(
                          "woot-button",
                          { on: { click: _vm.openEmailTemplateListModal } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "CAMPAIGN.ADD.SELECT_TEMPLATE_BUTTON_TEXT"
                                )
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedInbox,
                        expression: "selectedInbox"
                      }
                    ],
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedInbox = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChangeInbox($event)
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.inboxes, function(item) {
                    return _c(
                      "option",
                      { key: item.name, domProps: { value: item } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.$v.selectedInbox.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.FORM.INBOX.ERROR")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("woot-input", {
                class: { error: _vm.$v.title.$error },
                attrs: {
                  label: _vm.$t("CAMPAIGN.ADD.FORM.TITLE.LABEL"),
                  type: "text",
                  error: _vm.$v.title.$error
                    ? _vm.$t("CAMPAIGN.ADD.FORM.TITLE.ERROR")
                    : "",
                  placeholder: _vm.$t("CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER")
                },
                on: { blur: _vm.$v.title.$touch },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              }),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c(
                    "label",
                    { staticClass: "editor-wrap" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.LABEL")) +
                          "\n        "
                      ),
                      _c("woot-message-editor", {
                        staticClass: "message-editor",
                        class: { editor_warning: _vm.$v.message.$error },
                        attrs: {
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER"
                          )
                        },
                        on: { blur: _vm.$v.message.$touch },
                        model: {
                          value: _vm.message,
                          callback: function($$v) {
                            _vm.message = $$v
                          },
                          expression: "message"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.message.$error
                        ? _c(
                            "span",
                            { staticClass: "editor-warning__message" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.ERROR")
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "label",
                    { class: { error: _vm.$v.message.$error } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.LABEL")) +
                          "\n        "
                      ),
                      _vm.selectedTemplateHtml
                        ? _c("div", {
                            staticStyle: {
                              height: "300px",
                              "overflow-y": "scroll",
                              "margin-bottom": "10px"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.selectedTemplateHtml)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showMentions &&
                      _vm.hasSlashCommand &&
                      !_vm.isEmailSelectedInbox
                        ? _c("canned-response", {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.hideMentions,
                                expression: "hideMentions"
                              }
                            ],
                            attrs: {
                              top: "37",
                              "search-key": _vm.mentionSearchKey
                            },
                            on: { click: _vm.replaceText }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.selectedTemplateHtml
                        ? _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.message,
                                expression: "message"
                              }
                            ],
                            attrs: {
                              rows: "5",
                              type: "text",
                              placeholder: _vm.$t(
                                "CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER"
                              )
                            },
                            domProps: { value: _vm.message },
                            on: {
                              blur: _vm.$v.message.$touch,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.message = $event.target.value
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.message.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.ERROR"))
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("section", [
                _vm.isOnOffType
                  ? _c(
                      "label",
                      { class: { error: _vm.$v.appliedFilters.$error } },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.AUDIENCE.LABEL"))
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "medium-12 columns filters-wrap" },
                  [
                    _vm._l(_vm.appliedFilters, function(filter, i) {
                      return _c("filter-input-box", {
                        key: i,
                        attrs: {
                          "filter-groups": _vm.filterGroups,
                          "grouped-filters": true,
                          "input-type": _vm.getInputType(
                            _vm.appliedFilters[i].attribute_key
                          ),
                          operators: _vm.getOperators(
                            _vm.appliedFilters[i].attribute_key
                          ),
                          "dropdown-values": _vm.getDropdownValues(
                            _vm.appliedFilters[i].attribute_key
                          ),
                          "show-query-operator":
                            i !== _vm.appliedFilters.length - 1,
                          "show-user-input": _vm.showUserInput(
                            _vm.appliedFilters[i].filter_operator
                          ),
                          v: _vm.$v.appliedFilters.$each[i],
                          reOrderLabels: "false"
                        },
                        on: {
                          resetFilter: function($event) {
                            return _vm.resetFilter(i, _vm.appliedFilters[i])
                          },
                          removeFilter: function($event) {
                            return _vm.removeFilter(i)
                          }
                        },
                        model: {
                          value: _vm.appliedFilters[i],
                          callback: function($$v) {
                            _vm.$set(_vm.appliedFilters, i, $$v)
                          },
                          expression: "appliedFilters[i]"
                        }
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "filter-actions mt-4" },
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              icon: "add",
                              "color-scheme": "success",
                              variant: "smooth",
                              size: "small"
                            },
                            on: { click: _vm.appendNewFilter }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("CONTACTS_FILTER.ADD_NEW_FILTER"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("section", [
                _c("label", { attrs: { for: "" } }, [_vm._v("Type")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.campaign_type,
                        expression: "campaign_type"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.campaign_type = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { domProps: { value: "one_off" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("SIDEBAR.ONE_OFF")) + " ")
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: "running" } }, [
                      _vm._v(_vm._s(_vm.$t("SIDEBAR.ONGOING")))
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.campaign_type == "running"
                ? _c("section", [
                    _vm.isOnOffType
                      ? _c(
                          "label",
                          { class: { error: _vm.$v.appliedFilters.$error } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "CAMPAIGN.ADD.FORM.AUDIENCE.CUSTOM_LABEL"
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "medium-12 columns filters-wrap" },
                      _vm._l([_vm.appliedFilters[0]], function(filter, i) {
                        return _c("filter-input-box", {
                          key: i,
                          attrs: {
                            "filter-groups": _vm.onGoingDateFilterGroups,
                            "grouped-filters": true,
                            "input-type": "",
                            operators: _vm.onGoingOperators,
                            "dropdown-values": _vm.getDropdownValues(
                              _vm.appliedFilters[i].attribute_key
                            ),
                            "show-query-operator": false,
                            "show-user-input": _vm.showUserInput(
                              _vm.appliedFilters[i].filter_operator
                            )
                              ? true
                              : true,
                            v: _vm.$v.appliedFilters.$each[i],
                            reOrderLabels: "true"
                          },
                          model: {
                            value: _vm.campaign_repeat_at,
                            callback: function($$v) {
                              _vm.campaign_repeat_at = $$v
                            },
                            expression: "campaign_repeat_at"
                          }
                        })
                      }),
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c(
                    "label",
                    { class: { error: _vm.$v.selectedSender.$error } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.SENT_BY.LABEL")) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedSender,
                              expression: "selectedSender"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedSender = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.sendersAndBotList, function(sender) {
                          return _c(
                            "option",
                            {
                              key: sender.name,
                              domProps: { value: sender.id }
                            },
                            [_vm._v(_vm._s(sender.name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.$v.selectedSender.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.SENT_BY.ERROR"))
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.campaign_type == "one_off"
                ? _c(
                    "label",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL")
                          ) +
                          "\n        "
                      ),
                      _c("woot-date-time-picker", {
                        attrs: {
                          value: _vm.scheduledAt,
                          "disable-dates": 0,
                          "confirm-text": _vm.$t(
                            "CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM"
                          ),
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER"
                          )
                        },
                        on: { change: _vm.onChange }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.campaign_type == "running"
                ? _c(
                    "label",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL")
                          ) +
                          "\n        "
                      ),
                      _c("woot-date-time-picker", {
                        attrs: {
                          value: _vm.scheduledAt,
                          "disable-dates": 0,
                          "confirm-text": _vm.$t(
                            "CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM"
                          ),
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER"
                          ),
                          isTimeOnly: "true"
                        },
                        on: { change: _vm.onChange }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("woot-input", {
                    class: { error: _vm.$v.endPoint.$error },
                    attrs: {
                      label: _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.LABEL"),
                      type: "text",
                      error: _vm.$v.endPoint.$error
                        ? _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.ERROR")
                        : "",
                      placeholder: _vm.$t(
                        "CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.endPoint.$touch },
                    model: {
                      value: _vm.endPoint,
                      callback: function($$v) {
                        _vm.endPoint = $$v
                      },
                      expression: "endPoint"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("woot-input", {
                    class: { error: _vm.$v.timeOnPage.$error },
                    attrs: {
                      label: _vm.$t("CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL"),
                      type: "text",
                      error: _vm.$v.timeOnPage.$error
                        ? _vm.$t("CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR")
                        : "",
                      placeholder: _vm.$t(
                        "CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.timeOnPage.$touch },
                    model: {
                      value: _vm.timeOnPage,
                      callback: function($$v) {
                        _vm.timeOnPage = $$v
                      },
                      expression: "timeOnPage"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.enabled,
                          expression: "enabled"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        value: "enabled",
                        name: "enabled"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.enabled)
                          ? _vm._i(_vm.enabled, "enabled") > -1
                          : _vm.enabled
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.enabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "enabled",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.enabled = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.enabled = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.ENABLED")) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.triggerOnlyDuringBusinessHours,
                          expression: "triggerOnlyDuringBusinessHours"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        value: "triggerOnlyDuringBusinessHours",
                        name: "triggerOnlyDuringBusinessHours"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.triggerOnlyDuringBusinessHours
                        )
                          ? _vm._i(
                              _vm.triggerOnlyDuringBusinessHours,
                              "triggerOnlyDuringBusinessHours"
                            ) > -1
                          : _vm.triggerOnlyDuringBusinessHours
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.triggerOnlyDuringBusinessHours,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "triggerOnlyDuringBusinessHours",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.triggerOnlyDuringBusinessHours = $$a.concat(
                                  [$$v]
                                ))
                            } else {
                              $$i > -1 &&
                                (_vm.triggerOnlyDuringBusinessHours = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.triggerOnlyDuringBusinessHours = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS"
                          )
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "woot-button",
                {
                  attrs: { "is-loading": _vm.uiFlags.isCreating },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addCampaign($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.CREATE_BUTTON_TEXT")))]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD.CANCEL_BUTTON_TEXT")))]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.isOpenEmailTemplateListModal
        ? _c("email-template-list", {
            on: {
              close: _vm.closeEmailTemplateListModal,
              selectTemplate: _vm.selectTemplate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }