var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.onCloseDropdown,
          expression: "onCloseDropdown"
        }
      ],
      staticClass: "selector-wrap",
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.onCloseDropdown($event)
        }
      }
    },
    [
      _c(
        "woot-button",
        {
          staticClass: "selector-button",
          attrs: { variant: "hollow", "color-scheme": "secondary" },
          on: { click: _vm.toggleDropdown }
        },
        [
          _c(
            "div",
            { staticClass: "selector-user-wrap" },
            [
              _vm.hasValue && _vm.hasThumbnail
                ? _c("Thumbnail", {
                    attrs: {
                      src: _vm.selectedItem.thumbnail,
                      size: "24px",
                      status: _vm.selectedItem.availability_status,
                      username: _vm.selectedItem.name
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "selector-name-wrap" }, [
                !_vm.hasValue
                  ? _c(
                      "h4",
                      {
                        staticClass:
                          "not-selected text-ellipsis text-block-title"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.multiselectorPlaceholder) +
                            "\n        "
                        )
                      ]
                    )
                  : _c(
                      "h4",
                      {
                        staticClass:
                          "selector-name text-truncate text-block-title",
                        attrs: { title: _vm.selectedItem.name }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selectedItem.name) +
                            "\n        "
                        )
                      ]
                    ),
                _vm._v(" "),
                _vm.showSearchDropdown
                  ? _c("i", { staticClass: "icon ion-chevron-up" })
                  : _c("i", { staticClass: "icon ion-chevron-down" })
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-pane",
          class: { "dropdown-pane--open": _vm.showSearchDropdown }
        },
        [
          _c("h4", { staticClass: "text-block-title text-truncate" }, [
            _vm._v("\n      " + _vm._s(_vm.multiselectorTitle) + "\n    ")
          ]),
          _vm._v(" "),
          _vm.showSearchDropdown
            ? _c("multiselect-dropdown-items", {
                attrs: {
                  options: _vm.options,
                  "selected-item": _vm.selectedItem,
                  "has-thumbnail": _vm.hasThumbnail,
                  "input-placeholder": _vm.inputPlaceholder,
                  "no-search-result": _vm.noSearchResult
                },
                on: { click: _vm.onClickSelectItem }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }