var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "div",
        { staticClass: "row", staticStyle: { "align-items": "center" } },
        [
          _c(
            "div",
            {
              staticClass: "medium-6 small-12 columns",
              staticStyle: { display: "flex" }
            },
            [
              _c("woot-input", {
                class: { error: _vm.$v.form.name.$error },
                attrs: {
                  label: _vm.$t("CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.NAME.LABEL"),
                  placeholder: _vm.$t(
                    "CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.NAME.PLACEHOLDER"
                  ),
                  type: "text",
                  error: _vm.$v.form.name.$error
                    ? _vm.$t("CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.NAME.ERROR")
                    : ""
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "medium-3 small-6 columns",
                  staticStyle: { "margin-left": "10px" }
                },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.CATEGORY.LABEL"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.category,
                          expression: "form.category"
                        }
                      ],
                      staticClass: "business_hours_select",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "category",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", selected: "" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.CATEGORY.PLACEHOLDER"
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.categories, function(category, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: category } },
                          [_vm._v(_vm._s(category))]
                        )
                      })
                    ],
                    2
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "medium-6 small-12 columns" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "end",
                  "padding-left": "32px",
                  "padding-right": "32px",
                  "margin-bottom": "20px"
                }
              },
              [
                _c("woot-button", { on: { click: _vm.exportHtml } }, [
                  _vm._v(
                    _vm._s(
                      _vm.isPublishing
                        ? _vm.$t("CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.PUBLISHING")
                        : _vm.$t("CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.PUBLISH")
                    )
                  )
                ])
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("EmailEditor", {
        ref: "emailEditor",
        staticStyle: { height: "100vh" },
        attrs: { "min-height": "100vh" },
        on: { load: _vm.editorLoaded, ready: _vm.editorReady }
      }),
      _vm._v(" "),
      _c("attchment-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowingAttchmentList,
            expression: "isShowingAttchmentList"
          }
        ],
        ref: "imageList",
        on: {
          close: _vm.closeAttachmentListModal,
          selectImage: _vm.selectImage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }