var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "medium-12 columns" },
    [
      _vm.registrationFee &&
      this.currentUser.default_currency === "myr" &&
      this.userBalance > 29
        ? _c("div")
        : _c(
            "div",
            [
              _c("woot-input", {
                class: { error: _vm.$v.topUpAmount.$error },
                attrs: {
                  label: _vm.$t("TOP_UP.MODAL.FORM.ADD.LABEL", {
                    currency: this.currentUser.default_currency.toUpperCase()
                  }),
                  type: "text",
                  error: _vm.$v.topUpAmount.$error
                    ? _vm.$t("TOP_UP.MODAL.FORM.ADD.ERROR")
                    : "",
                  placeholder: _vm.$t("TOP_UP.MODAL.FORM.ADD.PLACEHOLDER")
                },
                on: {
                  input: _vm.onTopUpValueChange,
                  blur: _vm.$v.topUpAmount.$touch
                },
                model: {
                  value: _vm.topUpAmount,
                  callback: function($$v) {
                    _vm.topUpAmount = $$v
                  },
                  expression: "topUpAmount"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-container" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "flex-child text-center" }, [
                  _c("p", [
                    _vm._v(_vm._s(this.topUpAmount ? this.topUpAmount : 0))
                  ])
                ])
              ])
            ],
            1
          ),
      _vm._v(" "),
      _vm.registrationFee && this.currentUser.default_currency === "myr"
        ? _c("div", { staticClass: "flex-container" }, [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2)
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex-container" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "flex-child text-center" }, [
          _c("h6", [_vm._v(_vm._s(this.total.toFixed(2)))])
        ])
      ]),
      _vm._v(" "),
      _c(
        "woot-button",
        {
          attrs: { "color-scheme": "primary", size: "small" },
          on: { click: _vm.submit }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("TOP_UP.MODAL.FORM.SUBMIT")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child" }, [
      _c("label", [_vm._v("Credit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child" }, [
      _c("label", [_vm._v("One time Registration Fee ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child text-center" }, [
      _c("p", [_vm._v("50")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-child" }, [
      _c("h6", [_vm._v("Total")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }