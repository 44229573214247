<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px; padding-left: 24px; padding-right: 24px; color:grey; align-items: center;"
    >
      <h5>{{ $t('FLOW.EDIT_LIST') }}</h5>
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">

        <!-- <div style="display:flex;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.LIST_TITLE') }}
            <input
              v-model="list.title"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            />
          </label>
        </div> -->
         <woot-input
            v-model="list.title"
            :label="$t('FLOW.LIST_TITLE')"
            :error="$v.list.title.$error ? ($v.list.title.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.list.title.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            @blur="$v.list.title.$touch"
          />

        <!-- <div class="field_item">
          <label style="width:100%">
            {{ $t('FLOW.LIST_BODY') }}
            <input
              v-model="list.body"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.BODY')"
            />
          </label>
        </div> -->
         <woot-input
            v-model="list.body"
            :label="$t('FLOW.LIST_BODY')"
            :error="$v.list.body.$error ? ($v.list.body.$model.length < 1 ? 'Body must be at least 1 character.' : 'Body must be at most 4096 characters.') : ''"
            :class="{ error: $v.list.body.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BODY')"
            @blur="$v.list.body.$touch"
          />

        <!-- <div class="field_item">
          <label style="width:100%">
            {{ $t('FLOW.LIST_FOOTER') }}
            <input
              v-model="list.footer"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.FOOTER')"
            />
          </label>
        </div> -->
        <woot-input
            v-model="list.footer"
            :label="$t('FLOW.LIST_FOOTER')"
            :error="$v.list.footer.$error ? ($v.list.footer.$model.length > 60 ? 'Footer must be at most 60 characters.' : '') : ''"
            :class="{ error: $v.list.footer.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.FOOTER')"
            @blur="$v.list.footer.$touch"
          />

        <!-- <div class="field_item">
          <label style="width:100%">
            {{ $t('FLOW.LIST_BTN_LEVEL') }}
            <input
              v-model="list.button"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.BTN_LEVEL')"
            />
          </label>
        </div> -->
          <woot-input
            v-model="list.button"
            :label="$t('FLOW.LIST_BTN_LEVEL')"
            :error="$v.list.button.$error ? ($v.list.button.$model.length < 1 ? 'Button label must be at least 1 character.' : 'Button label must be at most 20 characters.') : ''"
            :class="{ error: $v.list.button.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BTN_LEVEL')"
            @blur="$v.list.button.$touch"
          />

        <section>
          <ul style="margin:0; list-style:none;">
            <li v-for="(section,sectionIdx) in list.action.sections" class="section_item" :key="sectionIdx">
              <woot-input
                v-model="section.title"
                :label="'Section title'"
                :error="$v.list.action.sections.$each[sectionIdx].title.$error ? 'Section title must be between 1 and 24 characters.' : ''"
                :class="{ error: $v.list.action.sections.$each[sectionIdx].title.$error }"
                type="text"
                :placeholder="'Enter item title'"
                @blur="$v.list.action.sections.$each[sectionIdx].title.$touch"
              />
              <div>
                <ul style="margin:0;list-style:none;">
                  <li v-for="(row,rowIdx) in section.rows" class="item" :key="'row'+rowIdx">
                    <div>
                      <woot-input
                        v-model="row.title"
                        :label="'Row title'"
                        :error="$v.list.action.sections.$each[sectionIdx].rows.$each[rowIdx].title.$error ? 'Row title must be between 1 and 24 characters.' : ''"
                        :class="{ error: $v.list.action.sections.$each[sectionIdx].rows.$each[rowIdx].title.$error }"
                        type="text"
                        :placeholder="'Enter row title'"
                        @blur="$v.list.action.sections.$each[sectionIdx].rows.$each[rowIdx].title.$touch"
                        @input="onTitleInput(sectionIdx,rowIdx)"
                      />
                      <woot-input
                        v-model="row.description"
                        :label="'Row description'"
                        :error="$v.list.action.sections.$each[sectionIdx].rows.$each[rowIdx].description.$error ? 'Row description must be between 1 and 72 characters.' : ''"
                        :class="{ error: $v.list.action.sections.$each[sectionIdx].rows.$each[rowIdx].description.$error }"
                        type="text"
                        :placeholder="'Enter row description'"
                        @blur="$v.list.action.sections.$each[sectionIdx].rows.$each[rowIdx].description.$touch"
                      />
                    </div>
                    <div class="action_btn">
                      <div @click="deleteRowItem(sectionIdx, rowIdx)">
                        <FluentIcon icon="dismiss" />
                      </div>
                    </div>
                  </li>
                </ul>
                <div style="text-align:center;">
                  <span @click="addRowItem(sectionIdx,)">
                    <DashboardIcon icon="add" />
                  </span>
                </div>
              </div>
              <div class="action_btn">
                <div @click="deleteSectionItem(sectionIdx)">
                  <FluentIcon icon="dismiss" />
                </div>
              </div>
            </li>
          </ul>
          <div style="text-align:center; margin-top: 1em;">
            <!-- <span @click="addSection">
              <DashboardIcon icon="add" />
              Add New Section
            </span> -->
            <woot-button
              v-tooltip.top-end="'Add New Section'"
              size="small"
              variant="smooth"
              color-scheme="secondary"
              icon="add"
              @click="addSection"
            />
          </div>
        </section>
      </div>


      <div class="modal-footer">
        <woot-button @click.prevent="allDone">
          {{ $t('FLOW.DONE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>

    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';
import DashboardIcon from '../../../../../../../shared/components/FluentIcon/DashboardIcon.vue';
import FlowApi from '../../FlowApi';
import { required, minValue, maxValue,  minLength, maxLength, sameAs } from 'vuelidate/lib/validators';

export default {
  components: {
    FluentIcon,
    DashboardIcon,
  },

  mixins: [alertMixin],
  props: {
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    list: {
      required: true,
    }
  },
  data() {
    return {};
  },
  mounted(){
    console.log("list props",this.list);
  },
  validations: {
    botid: {
      required,
    },
    list: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(60),
      },
      body: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(4096),
      },
      footer: {
        required: false,
        maxLength: maxLength(60),
      },
      button: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      },
      action: {
        sections: {
          $each: {
            title: {
              required,
              minLength: minLength(1),
              maxLength: maxLength(24)
            },
            rows: {
              $each: {
                title: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(24),
                },
                description: {
                  required,
                  minLength: minLength(1),
                  maxLength: maxLength(72),
                },
                id: {
                  required,
                },
              },
            },
          },
        },
      },
    },
  },
  methods: {
    deleteSectionItem(sectionIdx) {
      this.list.action.sections.splice(sectionIdx, 1);
    },
    deleteRowItem(sectionIdx, rowIdx) {
      this.list.action.sections[sectionIdx].rows.splice(rowIdx, 1);
    },
    addRowItem(sectionIdx) {
      this.list.action.sections[sectionIdx].rows.push({ id: "", title: "New Item", description: "Description" });
    },
    addSection() {
      if (!this.list.action.sections) {
        this.list.action.sections = [];
      }
      this.list.action.sections.push({
        title: '',
        rows: [{ id: '', title: 'New Item', description: 'New Description' }]
      });
    },

    onTitleInput(sectionIndex, index) {
      this.generateIdForItem(sectionIndex, index);
    },
    generateIdForItem(sectionIndex, index) {
      const id = this.list.action.sections[sectionIndex].rows[index].title.trim().toLowerCase().replace(/\s+/g, '_');
      this.list.action.sections[sectionIndex].rows[index].id = id;
    },
    validateUniqueIds() {
      const seenIds = new Set();
      for (const section of this.list.action.sections) {
        for (const row of section.rows) {
          if (seenIds.has(row.id)) {

            return false;
          }
          seenIds.add(row.id);
        }
      }
      return true;
    },

    async allDone() {
      console.log(this.list);

      // Generate IDs for items
      this.list.action.sections.forEach((section, sectionIndex) => {
        section.rows.forEach((el, idx) => {
          if (!el.id) {
            this.generateIdForItem(sectionIndex, idx);
          }
        });
      });

      // Validate that all row IDs are unique
      if (!this.validateUniqueIds()) {
        this.showAlert("Row title must be unique.");
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert("Please fill in all required fields correctly.");
        return;
      }

      // await this.$nextTick();

      // const seenIds = new Set();
      // for (const section of this.list.action.sections) {
      //   for (const item of section.rows) {
      //     if (seenIds.has(item.id)) {
      //       this.showAlert("Title cannot be duplicate");
      //       return;
      //     }
      //     seenIds.add(item.id);
      //   }
      // }

      this.$emit('list-edit-done', this.list);
    },

    onClose() {
      this.$emit('on-close');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.bottom-margin {
  margin-bottom: var(--space-small) !important;
}

.field_item {
  display: flex;
  align-items: center;
  margin-bottom: 1.6em;
}

.section_item{
  position: relative;
  border: 1px solid var(--s-75);
  border-radius: var(--border-radius-normal);
  padding: 20px 10px;
  margin: 40px 0;
}
.item {
  position: relative;
  border: 1px solid var(--s-75);
  border-radius: var(--border-radius-normal);
  padding: 12px;
  margin-top: 8px;
}

.action_btn {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>

