var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns bg-light" },
    [
      _c("session-header", {
        attrs: {
          "button-route": "new",
          icon: _vm.icon,
          "header-title": _vm.headerTitle,
          "show-back-button": _vm.showBackButton,
          "back-url": _vm.backUrl
        }
      }),
      _vm._v(" "),
      _vm.keepAlive
        ? _c("keep-alive", [_c("router-view")], 1)
        : _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }