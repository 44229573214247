var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        { staticClass: "event_wrapper" },
        [
          _c(
            "modal",
            {
              attrs: {
                show: _vm.isOpen,
                "on-close": _vm.closeModal,
                closeBtnClass: "event_close_btn",
                modalMainContainerClass: ""
              },
              on: {
                "update:show": function($event) {
                  _vm.isOpen = $event
                }
              }
            },
            [
              _c("div", { staticClass: "event" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.contest.landing_page_url,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.contest.poster_url, alt: "" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "join_btn enent_btn_wrap",
                    attrs: {
                      href: _vm.contest.landing_page_url,
                      target: "_blank"
                    }
                  },
                  [
                    _c("woot-button", { staticClass: "event_join_btn" }, [
                      _vm._v("Join Now ")
                    ])
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }