var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "conversation--form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _vm.showNoInboxAlert
        ? _c("div", { staticClass: "callout warning" }, [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("NEW_CONVERSATION.NO_INBOX")) +
                  "\n    "
              )
            ])
          ])
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "columns" }, [
                _c("label", { class: { error: _vm.$v.targetInbox.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("NEW_CONVERSATION.FORM.INBOX.LABEL")) +
                      "\n          "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.targetInbox,
                          expression: "targetInbox"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.targetInbox = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.inboxes, function(contactableInbox) {
                      return _c(
                        "option",
                        {
                          key: contactableInbox.inbox.id,
                          domProps: { value: contactableInbox }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(contactableInbox.inbox.name) +
                              "\n            "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.$v.targetInbox.$error
                    ? _c("span", { staticClass: "message" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("NEW_CONVERSATION.FORM.INBOX.ERROR")
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "columns" }, [
                _c("label", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("NEW_CONVERSATION.FORM.TO.LABEL")) +
                      "\n          "
                  ),
                  _c(
                    "div",
                    { staticClass: "contact-input" },
                    [
                      _c("thumbnail", {
                        attrs: {
                          src: _vm.contact.thumbnail,
                          size: "24px",
                          username: _vm.contact.name,
                          status: _vm.contact.availability_status
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "h4",
                        { staticClass: "text-block-title contact-name" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.contact.name) +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.isAnEmailInbox
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "columns" }, [
                    _c("label", { class: { error: _vm.$v.subject.$error } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("NEW_CONVERSATION.FORM.SUBJECT.LABEL")
                          ) +
                          "\n          "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.subject,
                            expression: "subject"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "NEW_CONVERSATION.FORM.SUBJECT.PLACEHOLDER"
                          )
                        },
                        domProps: { value: _vm.subject },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.subject = $event.target.value
                            },
                            _vm.$v.subject.$touch
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.subject.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("NEW_CONVERSATION.FORM.SUBJECT.ERROR")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "button",
            {
              staticClass: "button clear",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onCancel($event)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("NEW_CONVERSATION.FORM.CANCEL")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              attrs: {
                type: "submit",
                "is-loading": _vm.conversationsUiFlags.isCreating
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("NEW_CONVERSATION.FORM.SUBMIT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }