var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.labelClass,
      style: _vm.labelStyle,
      attrs: { title: _vm.description }
    },
    [
      _vm.icon
        ? _c(
            "button",
            { staticClass: "label-action--button", on: { click: _vm.onClick } },
            [
              _c("fluent-icon", {
                staticClass: "label--icon",
                attrs: { icon: _vm.icon, size: "12" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.href
        ? _c("span", [_vm._v(_vm._s(_vm.title))])
        : _c("a", { style: _vm.anchorStyle, attrs: { href: _vm.href } }, [
            _vm._v(_vm._s(_vm.title))
          ]),
      _vm._v(" "),
      _vm.showClose
        ? _c(
            "button",
            {
              staticClass: "label-action--button",
              style: { color: _vm.textColor },
              on: { click: _vm.onClick }
            },
            [
              _c("fluent-icon", {
                staticClass: "close--icon",
                attrs: { icon: "dismiss", size: "12" }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }