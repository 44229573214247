var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column padding-top" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top",
            icon: "arrow-download",
            disabled: !_vm.usagesOrderReport
          },
          on: { click: _vm.downloadReport }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("FLOW.DOWNLOAD_REPORT")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "report-bar", staticStyle: { width: "100%" } },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("h4", [_vm._v("Usages of Order")]),
                  _vm._v(" "),
                  _c("OrderUsageTable", {
                    attrs: {
                      "is-loading": _vm.isFetching,
                      usagesOrderReport: _vm.usagesOrderReport
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }