var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("CALENDAR_MGMT.ADD.CREATE_MEETING_TITLE")
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row modal-content" }, [
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.meetingTitle.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.MEETING.LABEL")) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.calendar_form.meetingTitle,
                    expression: "calendar_form.meetingTitle",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "CALENDAR_MGMT.ADD.FORM.MEETING.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.calendar_form.meetingTitle },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.calendar_form,
                        "meetingTitle",
                        $event.target.value.trim()
                      )
                    },
                    _vm.$v.calendar_form.meetingTitle.$touch
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.calendar_form.meetingTitle.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.MEETING.ERROR")) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            {
              class: { error: _vm.$v.calendar_form.meetingDescription.$error }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.MEETING_DESCIPTION.LABEL")
                  ) +
                  "\n        "
              ),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.calendar_form.meetingDescription,
                    expression: "calendar_form.meetingDescription",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "CALENDAR_MGMT.ADD.FORM.MEETING_DESCIPTION.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.calendar_form.meetingDescription },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.calendar_form,
                        "meetingDescription",
                        $event.target.value.trim()
                      )
                    },
                    _vm.$v.calendar_form.meetingDescription.$touch
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.calendar_form.meetingDescription.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "CALENDAR_MGMT.ADD.FORM.MEETING_DESCIPTION.ERROR"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.meetingLink.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.MEETING_LINK.LABEL")) +
                  "\n        "
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("span", { staticClass: "input-group-label" }, [
                  _vm._v(_vm._s(_vm.prefix + _vm.currentUserId + "/"))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.calendar_form.meetingLink,
                      expression: "calendar_form.meetingLink",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input-group-field",
                  attrs: { type: "text", placeholder: "sales" },
                  domProps: { value: _vm.calendar_form.meetingLink },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.calendar_form,
                          "meetingLink",
                          $event.target.value.trim()
                        )
                      },
                      _vm.$v.calendar_form.meetingLink.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _vm.$v.calendar_form.meetingLink.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.MEETING_LINK.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-6 columns pr-1" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.maximumBufferTime.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.MAXIMUM_BUFFER_TIME.LABEL")
                  ) +
                  "\n        "
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.calendar_form.maximumBufferTime,
                      expression: "calendar_form.maximumBufferTime",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input-group-field",
                  attrs: { type: "number" },
                  domProps: { value: _vm.calendar_form.maximumBufferTime },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.calendar_form,
                          "maximumBufferTime",
                          $event.target.value.trim()
                        )
                      },
                      _vm.$v.calendar_form.maximumBufferTime.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-label" }, [
                  _vm._v("Minutes")
                ])
              ]),
              _vm._v(" "),
              _vm.$v.calendar_form.maximumBufferTime.$error &&
              !_vm.$v.calendar_form.maximumBufferTime.between
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "CALENDAR_MGMT.ADD.FORM.MAXIMUM_BUFFER_TIME.ERROR_BETWEEN"
                          )
                        ) +
                        "\n          " +
                        _vm._s(
                          _vm.$v.calendar_form.maximumBufferTime.$params.between
                            .min
                        ) +
                        " &\n          " +
                        _vm._s(
                          _vm.$v.calendar_form.maximumBufferTime.$params.between
                            .max
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-6 columns " }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.duration.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.MAXIMUM_DURATION.LABEL")
                  ) +
                  "\n        "
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.calendar_form.duration,
                      expression: "calendar_form.duration",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input-group-field",
                  attrs: { type: "number" },
                  domProps: { value: _vm.calendar_form.duration },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.calendar_form,
                          "duration",
                          $event.target.value.trim()
                        )
                      },
                      _vm.$v.calendar_form.duration.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-label" }, [
                  _vm._v("Minutes")
                ])
              ]),
              _vm._v(" "),
              _vm.$v.calendar_form.duration.$error &&
              !_vm.$v.calendar_form.duration.between
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "CALENDAR_MGMT.ADD.FORM.MAXIMUM_DURATION.ERROR_BETWEEN"
                          )
                        ) +
                        "\n          " +
                        _vm._s(
                          _vm.$v.calendar_form.duration.$params.between.min
                        ) +
                        " &\n          " +
                        _vm._s(
                          _vm.$v.calendar_form.duration.$params.between.max
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-6 columns pr-1" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.maximumMeeting.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.LABEL")
                  ) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.calendar_form.maximumMeeting,
                    expression: "calendar_form.maximumMeeting",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "number",
                  placeholder: _vm.$t(
                    "CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.calendar_form.maximumMeeting },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.calendar_form,
                        "maximumMeeting",
                        $event.target.value.trim()
                      )
                    },
                    _vm.$v.calendar_form.maximumMeeting.$touch
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.calendar_form.maximumMeeting.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.calendar_form.maximumMeeting.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.ERROR_INVALID_MAX"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-6 columns" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.notice_time.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.NOTICE_TIME.LABEL")) +
                  "\n        "
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.calendar_form.notice_time,
                      expression: "calendar_form.notice_time",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input-group-field",
                  attrs: { type: "number", min: "0" },
                  domProps: { value: _vm.calendar_form.notice_time },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.calendar_form,
                          "notice_time",
                          $event.target.value.trim()
                        )
                      },
                      _vm.$v.calendar_form.notice_time.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-label" }, [
                  _vm._v("Minutes")
                ])
              ]),
              _vm._v(" "),
              _vm.$v.calendar_form.notice_time.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.NOTICE_TIME.ERROR_MIN")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-6 columns pr-1" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.reminder_time.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.REMINDER_TIME.LABEL")) +
                  "\n        "
              ),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.calendar_form.reminder_time,
                      expression: "calendar_form.reminder_time",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input-group-field",
                  attrs: {
                    type: "number",
                    placeholder: _vm.$t(
                      "CALENDAR_MGMT.ADD.FORM.REMINDER_TIME.PLACEHOLDER"
                    ),
                    min: "0"
                  },
                  domProps: { value: _vm.calendar_form.reminder_time },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.calendar_form,
                          "reminder_time",
                          $event.target.value.trim()
                        )
                      },
                      _vm.$v.calendar_form.reminder_time.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-label" }, [
                  _vm._v("Minutes")
                ])
              ]),
              _vm._v(" "),
              _vm.$v.calendar_form.reminder_time.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "CALENDAR_MGMT.ADD.FORM.REMINDER_TIME.ERROR_MIN"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-6 columns" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.inbox_id.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.INBOX_ID.PLACEHOLDER")
                  ) +
                  "\n        "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.calendar_form.inbox_id,
                      expression: "calendar_form.inbox_id"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.calendar_form,
                        "inbox_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.inboxes, function(inbox) {
                  return _c(
                    "option",
                    { key: inbox.id, domProps: { value: inbox.id } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(inbox.label) + "\n          "
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.$v.calendar_form.inbox_id.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.INBOX_ID.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            {
              class: {
                error: _vm.$v.calendar_form.confirmation_msg.message.$error
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.CONFIRMATION_MSG.LABEL")
                  ) +
                  "\n        "
              ),
              _vm._v(" "),
              _vm.showMentions &&
              _vm.hasSlashCommand &&
              _vm.nonCommunalInboxes.length === 0
                ? _c("canned-response", {
                    attrs: { top: "37", "search-key": _vm.mentionSearchKey },
                    on: { click: _vm.replaceText }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.calendar_form.confirmation_msg
                ? _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.message_content,
                        expression: "message_content"
                      }
                    ],
                    staticClass: "action-message",
                    attrs: {
                      rows: "4",
                      placeholder: _vm.$t("AUTOMATION.ADD_MESSAGE_CONTENT")
                    },
                    domProps: { value: _vm.message_content },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.message_content = $event.target.value
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.calendar_form.confirmation_msg.message.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "CALENDAR_MGMT.ADD.FORM.CONFIRMATION_MSG.ERROR"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            {
              class: { error: _vm.$v.calendar_form.reminder_msg.message.$error }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.REMINDER_MSG.LABEL")) +
                  "\n        "
              ),
              _vm._v(" "),
              _vm.reminder_msg_showMentions &&
              _vm.reminder_msg_hasSlashCommand &&
              _vm.reminder_msg_nonCommunalInboxes.length === 0
                ? _c("canned-response", {
                    attrs: {
                      top: "37",
                      "search-key": _vm.reminder_msg_mentionSearchKey
                    },
                    on: { click: _vm.reminder_msg_replaceText }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.calendar_form.reminder_msg
                ? _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reminder_msg_content,
                        expression: "reminder_msg_content"
                      }
                    ],
                    staticClass: "action-message",
                    attrs: {
                      rows: "4",
                      placeholder: _vm.$t("AUTOMATION.ADD_MESSAGE_CONTENT")
                    },
                    domProps: { value: _vm.reminder_msg_content },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.reminder_msg_content = $event.target.value
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.calendar_form.reminder_msg.message.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.REMINDER_MSG.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.timezone.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.TIMEZONE.LABEL")) +
                  "\n        "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.calendar_form.timezone,
                      expression: "calendar_form.timezone"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.calendar_form,
                        "timezone",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.timezoneNames, function(timezone) {
                  return _c(
                    "option",
                    { key: timezone, domProps: { value: timezone } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(timezone) + "\n          "
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.$v.calendar_form.timezone.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.TIMEZONE.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "label",
            { class: { error: _vm.$v.calendar_form.location.type.$error } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.LABEL")
                  ) +
                  "\n        "
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.calendar_form.location.type,
                      expression: "calendar_form.location.type"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.calendar_form.location,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.ConferenceType, function(conference_type) {
                  return _c(
                    "option",
                    {
                      key: conference_type.value,
                      domProps: { value: conference_type.value }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(conference_type.label) +
                          "\n          "
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.$v.calendar_form.location.type.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.calendar_form.location.type == "custom",
                expression: "calendar_form.location.type == 'custom'"
              }
            ],
            staticClass: "medium-12 columns"
          },
          [
            _c(
              "label",
              { class: { error: _vm.$v.calendar_form.location.custom.$error } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.CUSTOM_LABEL"
                      )
                    ) +
                    "\n        "
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.calendar_form.location.custom,
                      expression: "calendar_form.location.custom",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.CUSTOME_PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.calendar_form.location.custom },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.calendar_form.location,
                          "custom",
                          $event.target.value.trim()
                        )
                      },
                      _vm.$v.calendar_form.location.custom.$touch
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.calendar_form.location.custom.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.ERROR"
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c("section", [
            _c("label", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("CALENDAR_MGMT.ADD.FORM.AVAILABILITY.LABEL")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "medium-12 columns filters-wrap" },
              [
                _vm._l(_vm.calendar_form.business_hours, function(
                  business_hour,
                  i
                ) {
                  return _c("businessHours", {
                    key: i,
                    attrs: { value: _vm.$v.calendar_form.business_hours[i] },
                    on: {
                      removeAction: function($event) {
                        return _vm.removeAction(i)
                      }
                    },
                    model: {
                      value: _vm.calendar_form.business_hours[i],
                      callback: function($$v) {
                        _vm.$set(_vm.calendar_form.business_hours, i, $$v)
                      },
                      expression: "calendar_form.business_hours[i]"
                    }
                  })
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "filter-actions" },
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          icon: "add",
                          "color-scheme": "success",
                          variant: "smooth",
                          size: "small"
                        },
                        on: { click: _vm.addMoreBusinessHours }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "CALENDAR_MGMT.ADD.FORM.AVAILABILITY.ACTION_BUTTON_LABEL"
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "medium-12 columns" }, [
          _c(
            "div",
            { staticClass: "modal-footer justify-content-end w-full" },
            [
              _vm.isLoading
                ? _c("spinner", { attrs: { size: "large" } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  staticClass: "button clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CALENDAR_MGMT.BTN_CANCEL")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("woot-button", { on: { click: _vm.onSubmit } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      this.prefilledForm
                        ? _vm.$t("CALENDAR_MGMT.BTN_UPDATE")
                        : _vm.$t("CALENDAR_MGMT.BTN_CREATE")
                    ) +
                    "\n        "
                )
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }