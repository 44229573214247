var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c(
        "ul",
        {
          staticClass: "vertical dropdown menu mention--box",
          style: {
            top: _vm.getTopPadding() + "rem",
            position: _vm.getPosition()
          }
        },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            {
              key: item.key,
              class: { active: index === _vm.selectedIndex },
              attrs: { id: "mention-item-" + index },
              on: {
                click: function($event) {
                  return _vm.onListItemSelection(index)
                },
                mouseover: function($event) {
                  return _vm.onHover(index)
                }
              }
            },
            [
              _c("a", { staticClass: "text-truncate" }, [
                _c("strong", [_vm._v(_vm._s(item.label))]),
                _vm._v(" - " + _vm._s(item.description) + "\n    ")
              ])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }