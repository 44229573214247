import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=5c2478ee&scoped=true&"
import script from "./Settings.vue?vue&type=script&lang=js&"
export * from "./Settings.vue?vue&type=script&lang=js&"
import style0 from "./Settings.vue?vue&type=style&index=0&id=5c2478ee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2478ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/chatwoot/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c2478ee')) {
      api.createRecord('5c2478ee', component.options)
    } else {
      api.reload('5c2478ee', component.options)
    }
    module.hot.accept("./Settings.vue?vue&type=template&id=5c2478ee&scoped=true&", function () {
      api.rerender('5c2478ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/inbox/Settings.vue"
export default component.exports