var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeSearch,
          expression: "closeSearch"
        }
      ],
      staticClass: "search-wrap"
    },
    [
      _c(
        "div",
        { staticClass: "search", class: { "is-active": _vm.showSearchBox } },
        [
          _c("woot-sidemenu-icon"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "icon" },
            [
              _c("fluent-icon", {
                staticClass: "search--icon",
                attrs: { icon: "search", size: "28" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm"
              }
            ],
            staticClass: "search--input",
            attrs: { placeholder: _vm.$t("CONVERSATION.SEARCH_MESSAGES") },
            domProps: { value: _vm.searchTerm },
            on: {
              focus: _vm.onSearch,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchTerm = $event.target.value
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSearchBox
        ? _c("div", { staticClass: "results-wrap" }, [
            _c("div", { staticClass: "show-results" }, [
              _c("div", [
                _c("div", { staticClass: "result-view" }, [
                  _c("div", { staticClass: "result" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("CONVERSATION.SEARCH.RESULT_TITLE")) +
                        "\n            "
                    ),
                    _vm.resultsCount
                      ? _c("span", { staticClass: "message-counter" }, [
                          _vm._v(
                            "\n              (" +
                              _vm._s(_vm.resultsCount) +
                              ")\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.uiFlags.isFetching
                    ? _c(
                        "div",
                        { staticClass: "search--activity-message" },
                        [
                          _c("woot-spinner", { attrs: { size: "" } }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("CONVERSATION.SEARCH.LOADING_MESSAGE")
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.showSearchResult
                  ? _c(
                      "div",
                      { staticClass: "search-results--container" },
                      _vm._l(_vm.conversations, function(conversation) {
                        return _c("result-item", {
                          key: conversation.messageId,
                          attrs: {
                            "conversation-id": conversation.id,
                            "user-name": conversation.contact.name,
                            timestamp: conversation.created_at,
                            messages: conversation.messages,
                            "search-term": _vm.searchTerm,
                            "inbox-name": conversation.inbox.name
                          }
                        })
                      }),
                      1
                    )
                  : _vm.showEmptyResult
                  ? _c("div", { staticClass: "search--activity-no-message" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("CONVERSATION.SEARCH.NO_MATCHING_RESULTS")
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }