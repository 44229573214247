var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.title, "header-content": _vm.message }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer delete-item" }, [
        _c(
          "button",
          { staticClass: "alert button nice", on: { click: _vm.onConfirm } },
          [_vm._v("\n      " + _vm._s(_vm.confirmText) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button clear", on: { click: _vm.onClose } },
          [_vm._v("\n      " + _vm._s(_vm.rejectText) + "\n    ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }