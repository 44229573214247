var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "margin-bottom": "10px"
          }
        },
        [
          _c("div", { staticClass: "columns medium-6 small-12" }, [
            _c(
              "div",
              { staticClass: "search-wrap" },
              [
                _c("fluent-icon", {
                  staticClass: "search-icon",
                  attrs: { icon: "search" }
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "contact-search",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "CAMPAIGN.EMAIL_TEMPLATE.SEARCH_INPUT_PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onSearchSubmit($event)
                    },
                    input: _vm.onInputSearch
                  }
                }),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    staticClass: "clear",
                    attrs: {
                      "is-loading": false,
                      "class-names": this.searchQuery !== "" ? "show" : ""
                    },
                    on: { click: _vm.onSearchSubmit }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.SEARCH_BUTTON_TEXT")
                      )
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "columns medium-6 small-12",
              staticStyle: { "justify-content": "end", display: "flex" }
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "email_template_add" } } },
                [
                  _c("woot-button", [
                    _vm._v(
                      _vm._s(_vm.$t("CAMPAIGN.EMAIL_TEMPLATE.ADD_BUTTON_TEXT"))
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "woot-tabs",
            {
              attrs: { index: _vm.activeCategoryTab },
              on: { change: _vm.onTabChange }
            },
            _vm._l(_vm.categories, function(item, index) {
              return _c("woot-tabs-item", {
                key: index,
                attrs: { name: item, "show-badge": false }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: { "justify-content": "space-between" }
        },
        [
          _vm.isFetchinigEmailTemplate
            ? _c("spinner", {
                staticClass: "margin:auto",
                attrs: { size: "large" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.emailTemplates.length === 0
            ? _c("empty-state", {
                attrs: {
                  title: _vm.$t("CAMPAIGN.EMAIL_TEMPLATES.INBOXES_NOT_FOUND")
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.emailTemplates, function(template) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.isFetchinigEmailTemplate &&
                      _vm.emailTemplates.length > 0,
                    expression:
                      "!isFetchinigEmailTemplate && emailTemplates.length > 0"
                  }
                ],
                key: template.id,
                staticClass: "medium-5 small-12 columns",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c("div", { staticClass: "medium-8" }, [
                        _c("span", { staticClass: "fs-small" }, [
                          _vm._v(_vm._s(template.name))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "medium-4",
                          staticStyle: {
                            "justify-content": "end",
                            display: "flex"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "button-wrapper" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to:
                                      "/app/accounts/" +
                                      _vm.accountId +
                                      "/campaigns/email_templates/edit/" +
                                      template.id
                                  }
                                },
                                [
                                  _c("woot-button", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.$t(
                                          "CAMPAIGN.EMAIL_TEMPLATE.EDIT_BUTTON_TEXT"
                                        ),
                                        expression:
                                          "$t('CAMPAIGN.EMAIL_TEMPLATE.EDIT_BUTTON_TEXT')",
                                        modifiers: { top: true }
                                      }
                                    ],
                                    attrs: {
                                      variant: "smooth",
                                      size: "small",
                                      "color-scheme": "secondary",
                                      "class-names": "grey-btn",
                                      icon: "edit"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("woot-button", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: _vm.$t(
                                      "CAMPAIGN.EMAIL_TEMPLATE.DELETE_BUTTON_TEXT"
                                    ),
                                    expression:
                                      "$t('CAMPAIGN.EMAIL_TEMPLATE.DELETE_BUTTON_TEXT')",
                                    modifiers: { top: true }
                                  }
                                ],
                                attrs: {
                                  variant: "smooth",
                                  "color-scheme": "alert",
                                  size: "small",
                                  icon: "dismiss-circle",
                                  "class-names": "grey-btn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openDeletePopup(template.id)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: {
                      "max-height": "200px",
                      "overflow-y": "scroll"
                    },
                    domProps: { innerHTML: _vm._s(template.templateBody.html) }
                  })
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": _vm.deleteConfirmText,
          "reject-text": _vm.deleteRejectText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }