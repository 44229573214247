var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile--settings--row row" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "columns small-9 medium-5" }, [
      _vm.primaryCalendar && !_vm.primaryCalendar.access_token
        ? _c(
            "div",
            { staticClass: "calendar-primary-div" },
            [
              _c("div", { staticClass: "calendar-primary-div--left" }, [
                _c(
                  "div",
                  [
                    _c("thumbnail", {
                      attrs: {
                        src: "/dashboard/images/calendar/google-logo.svg"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(1)
              ]),
              _vm._v(" "),
              !_vm.isLoading
                ? _c(
                    "div",
                    {
                      staticClass: "calendar-primary-div--disconnect-button",
                      on: { click: _vm.googleOauth }
                    },
                    [_vm._v("\n        Connect\n      ")]
                  )
                : _c("spinner", { attrs: { size: "large" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.primaryCalendar && _vm.primaryCalendar.access_token
        ? _c(
            "div",
            { staticClass: "calendar-primary-div" },
            [
              _c("div", { staticClass: "calendar-primary-div--left" }, [
                _c(
                  "div",
                  [
                    _c("thumbnail", {
                      attrs: {
                        src: "/dashboard/images/calendar/google-logo.svg"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "display-primary-email-div" }, [
                  _c("h6", [_vm._v("Connected to Google Calendar account")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.primaryCalendar.calendar_user_id))
                  ])
                ])
              ]),
              _vm._v(" "),
              !_vm.isLoading
                ? _c(
                    "div",
                    {
                      staticClass: "calendar-primary-div--disconnect-button",
                      on: { click: _vm.handleDisconnect }
                    },
                    [_vm._v("\n        Disconnect\n      ")]
                  )
                : _c("spinner", { attrs: { size: "large" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.primaryCalendar &&
      _vm.primaryCalendar.access_token &&
      _vm.conflictingCalendars &&
      _vm.conflictingCalendars.length > 0
        ? _c(
            "div",
            [
              _c("label", [_vm._v(" Select Conflicting Calendars ")]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  "track-by": "id",
                  label: "name",
                  placeholder: "Select Conflicting Calendars",
                  multiple: true,
                  "selected-label": "",
                  "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                  "deselect-label": "",
                  "max-height": 160,
                  options: _vm.conflictingCalendars
                },
                model: {
                  value: _vm.selectedCalendars,
                  callback: function($$v) {
                    _vm.selectedCalendars = $$v
                  },
                  expression: "selectedCalendars"
                }
              }),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: {
                    "color-scheme": "primary",
                    disabled: _vm.isUpdatingCalendars
                  },
                  on: { click: _vm.updateConflictingCalendars }
                },
                [_vm._v("\n        Save\n      ")]
              ),
              _vm._v(" "),
              _vm.isUpdatingCalendars
                ? _c("spinner", { attrs: { size: "large" } })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns small-3" }, [
      _c("h4", { staticClass: "block-title" }, [_vm._v("Calendar Setting")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "display-primary-email-div" }, [
      _c("h6", [_vm._v("Connect to Google Calendar account")]),
      _vm._v(" "),
      _c("p", [_vm._v("Account not connected")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }