var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "modal",
        {
          attrs: {
            modalMainContainerClass: "instruction_popup",
            show: _vm.isStepOneOpen,
            "on-close": _vm.temporaryCloseModal
          },
          on: {
            "update:show": function($event) {
              _vm.isStepOneOpen = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t(
                "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.TITLE"
              ),
              "header-content": _vm.$t(
                "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.HEADING"
              )
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "whatsapp-question" }, [
            _c("div", [
              _c(
                "ol",
                {},
                _vm._l(_vm.whatsAppCompulsoryList, function(el) {
                  return _c("li", { key: el.text }, [
                    el.url
                      ? _c("span", [
                          _c(
                            "a",
                            { attrs: { href: el.url, target: "_blank" } },
                            [_vm._v(_vm._s(el.text))]
                          )
                        ])
                      : _c("span", [_vm._v(_vm._s(el.text))])
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t(
                        "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.FOOTER.PARA_1ST_PART"
                      )
                    ) +
                    "\n                "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://peasy.ai/docs/whatsapp-cloud/",
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.FOOTER.LINK"
                        )
                      )
                    )
                  ]
                ),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t(
                        "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.FOOTER.PARA_2ND_PART"
                      )
                    ) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "btns" }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "inbox_create_btn " },
                    [
                      _c(
                        "woot-button",
                        {
                          attrs: { classNames: "modal_btn" },
                          on: { click: _vm.onSkipFn }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.SKIP.TEXT"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "tiny_text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.SKIP.HINT"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "inbox_create_btn" },
                    [
                      _c(
                        "woot-button",
                        {
                          attrs: { classNames: "modal_btn" },
                          on: { click: _vm.onCloseStepOneFn }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.OK.TEXT"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "tiny_text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "INSTRUCT_POPUP.SCHEDULING.CREATE_INBOX.BUTTONS.OK.HINT"
                        )
                      )
                    )
                  ])
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            show: _vm.isStepTwoOpen,
            "on-close": _vm.temporaryCloseModal
          },
          on: {
            "update:show": function($event) {
              _vm.isStepTwoOpen = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": "Caution",
              "header-content":
                "You’ll need a new phone number that’s not associated with any existing WhatsApp account to register a new WhatsApp API account. Do you have a new phone number ready?"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "whatsapp-body" },
            [
              _c(
                "select",
                {
                  attrs: { name: "", id: "" },
                  domProps: { value: _vm.selectedCaution },
                  on: {
                    change: function($event) {
                      return _vm.onchangeCautionOption($event)
                    }
                  }
                },
                _vm._l(_vm.cautionDropdown, function(item) {
                  return _c(
                    "option",
                    { key: item.value, domProps: { value: item.value } },
                    [_vm._v(_vm._s(item.value))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { "is-loading": _vm.isStepLoading },
                  on: { click: _vm.cautionHandler }
                },
                [_vm._v("Continue")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            show: _vm.isStepThreeOpen,
            "on-close": _vm.temporaryCloseModal
          },
          on: {
            "update:show": function($event) {
              _vm.isStepThreeOpen = $event
            }
          }
        },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": "FREE Samsung Galaxy A14 5G smartphone*",
              "header-content": ""
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "whatsapp-body" }, [
            _c("img", { attrs: { src: _vm.GiftImgUrl, alt: "gift image" } }),
            _vm._v(" "),
            _c("p", { staticStyle: { "margin-top": "8px" } }, [
              _vm._v(
                "* Get a new 5G line from Maxis for only RM 69 a month and get a free Samsung Galaxy A14 5G smartphone when you apply."
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "8px" } },
              [
                _c(
                  "woot-button",
                  {
                    attrs: { "is-loading": _vm.isStepLoading },
                    on: { click: _vm.moreGiftHandler }
                  },
                  [_vm._v("Find Out More")]
                ),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    attrs: { "is-loading": _vm.isStepLoading },
                    on: { click: _vm.giftHandler }
                  },
                  [_vm._v("Continue")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }