var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker" },
    [
      _c("date-picker", {
        attrs: {
          range: true,
          confirm: true,
          clearable: false,
          editable: false,
          "confirm-text": _vm.confirmText,
          placeholder: _vm.placeholder,
          value: _vm.value,
          "disabled-date": _vm.disabledDate
        },
        on: { change: _vm.handleChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }