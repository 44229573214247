var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _vm.msgDetail
        ? _c("woot-modal-header", {
            attrs: { "header-title": _vm.msgDetail.header }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.msgDetail
        ? _c(
            "div",
            { staticClass: "list-wrapper" },
            _vm._l(_vm.msgDetail.action.sections, function(
              section,
              sectionIndex
            ) {
              return _c(
                "div",
                { key: sectionIndex, staticClass: "row list" },
                [
                  _c("h6", [_vm._v(_vm._s(section.title))]),
                  _vm._v(" "),
                  _vm._l(section.product_items, function(
                    product,
                    productIndex
                  ) {
                    return _c(
                      "div",
                      {
                        key: productIndex,
                        staticClass: "column small-12 odr-content"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "column small-2 img-card card" },
                          [
                            _c("bubble-image", {
                              staticClass: "food-img",
                              attrs: {
                                url:
                                  "https://www.pngmart.com/files/23/Food-PNG-Photos.png"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "odr-detail column small-8" },
                          [
                            _c("h6", [
                              _vm._v(_vm._s(product.product_retailer_id))
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("CONVERSATION.MYR")) +
                                  " " +
                                  _vm._s(product.price)
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column small-1 action" },
                          [
                            product.count > 0 && _vm.selctedItems.length > 0
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      variant: "smooth",
                                      "color-scheme": "secondary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeFromOrder(product)
                                      }
                                    }
                                  },
                                  [_vm._v("-")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            product.count > 0 && _vm.selctedItems.length > 0
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      product.count > 0 ? product.count : ""
                                    )
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column small-1 add-item" },
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  size: "small",
                                  variant: "smooth",
                                  "color-scheme": "secondary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addToOrder(product)
                                  }
                                }
                              },
                              [_vm._v("+")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selctedItems.length > 0
        ? _c(
            "div",
            { staticClass: "modal-footer w-full" },
            [
              _c("woot-button", { on: { click: _vm.placeOrder } }, [
                _vm._v(_vm._s(_vm.$t("CONVERSATION.PLACE_ORDER")))
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }