var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-up" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: function() {
                return _vm.$emit("clickaway")
              },
              expression: "() => $emit('clickaway')"
            }
          ],
          staticClass: "modal-container"
        },
        [
          _c("div", { staticClass: "header-wrap" }, [
            _c("div", { staticClass: "title-shortcut-key__wrap" }, [
              _c("h2", { staticClass: "page-title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "shortcut-key__wrap" }, [
                _c("p", { staticClass: "shortcut-key" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.$t(
                          "KEYBOARD_SHORTCUTS.KEYS.WINDOWS_KEY_AND_COMMAND_KEY"
                        )
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "shortcut-key key" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.$t("KEYBOARD_SHORTCUTS.KEYS.FORWARD_SLASH_KEY")
                      ) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "cursor-pointer",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("fluent-icon", { attrs: { icon: "dismiss" } })],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "shortcut__wrap" },
            [
              _c("div", { staticClass: "title-key__wrap" }, [
                _c("span", { staticClass: "sub-block-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("KEYBOARD_SHORTCUTS.TITLE.OPEN_CONVERSATION")
                      ) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "shortcut-key__wrap" }, [
                  _c("div", { staticClass: "open-conversation__key" }, [
                    _c("span", { staticClass: "shortcut-key" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t("KEYBOARD_SHORTCUTS.KEYS.ALT_OR_OPTION_KEY")
                          ) +
                          "\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "shortcut-key" }, [
                      _vm._v("\n                J\n              ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "forward-slash sub-block-title" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "KEYBOARD_SHORTCUTS.KEYS.FORWARD_SLASH_KEY"
                              )
                            ) +
                            "\n              "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$t("KEYBOARD_SHORTCUTS.KEYS.ALT_OR_OPTION_KEY")
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key key" }, [
                    _vm._v("\n              K\n            ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title-key__wrap" }, [
                _c("span", { staticClass: "sub-block-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("KEYBOARD_SHORTCUTS.TITLE.RESOLVE_AND_NEXT")
                      ) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "shortcut-key__wrap" }, [
                  _c("span", { staticClass: "shortcut-key" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$t(
                            "KEYBOARD_SHORTCUTS.KEYS.WINDOWS_KEY_AND_COMMAND_KEY"
                          )
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$t("KEYBOARD_SHORTCUTS.KEYS.ALT_OR_OPTION_KEY")
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key key" }, [
                    _vm._v("\n              E\n            ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.shortcutKeys, function(shortcutKey) {
                return _c(
                  "div",
                  { key: shortcutKey.id, staticClass: "title-key__wrap" },
                  [
                    _c("span", { staticClass: "sub-block-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.title(shortcutKey)) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "shortcut-key__wrap" }, [
                      _c("span", { staticClass: "shortcut-key" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(shortcutKey.firstkey) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "shortcut-key key" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(shortcutKey.secondKey) +
                            "\n            "
                        )
                      ])
                    ])
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }