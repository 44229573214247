var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings columns container" },
    [
      _c(
        "setting-intro-banner",
        {
          attrs: {
            "header-image": _vm.inbox.avatarUrl,
            "header-title": _vm.inboxName
          }
        },
        [
          _c(
            "woot-tabs",
            {
              attrs: { index: _vm.selectedTabIndex },
              on: { change: _vm.onTabChange }
            },
            _vm._l(_vm.tabs, function(tab) {
              return _c("woot-tabs-item", {
                key: tab.key,
                attrs: { name: tab.name, "show-badge": false }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedTabKey === "inbox_settings"
        ? _c(
            "div",
            { staticClass: "settings--content" },
            [
              _c(
                "settings-section",
                {
                  attrs: {
                    title: _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE"
                    ),
                    "sub-title": _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT"
                    )
                  }
                },
                [
                  _c("woot-avatar-uploader", {
                    staticClass: "settings-item",
                    attrs: {
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL"
                      ),
                      src: _vm.avatarUrl,
                      "delete-avatar": ""
                    },
                    on: {
                      change: _vm.handleImageUpload,
                      onAvatarDelete: _vm.handleAvatarDelete
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-9 columns settings-item",
                    attrs: {
                      label: _vm.inboxNameLabel,
                      placeholder: _vm.inboxNamePlaceHolder
                    },
                    model: {
                      value: _vm.selectedInboxName,
                      callback: function($$v) {
                        _vm.selectedInboxName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "selectedInboxName"
                    }
                  }),
                  _vm._v(" "),
                  _vm.isATwitterInbox
                    ? _c(
                        "label",
                        {
                          staticClass: "toggle-input-wrap",
                          attrs: { for: "toggle-business-hours" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tweetsEnabled,
                                expression: "tweetsEnabled"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              name: "toggle-business-hours"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.tweetsEnabled)
                                ? _vm._i(_vm.tweetsEnabled, null) > -1
                                : _vm.tweetsEnabled
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.tweetsEnabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.tweetsEnabled = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.tweetsEnabled = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.tweetsEnabled = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("INBOX_MGMT.ADD.TWITTER.TWEETS.ENABLE")
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAPIInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        class: { error: _vm.$v.webhookUrl.$error },
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER"
                          ),
                          error: _vm.$v.webhookUrl.$error
                            ? _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR"
                              )
                            : ""
                        },
                        on: { blur: _vm.$v.webhookUrl.$touch },
                        model: {
                          value: _vm.webhookUrl,
                          callback: function($$v) {
                            _vm.webhookUrl =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "webhookUrl"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER"
                          )
                        },
                        model: {
                          value: _vm.channelWebsiteUrl,
                          callback: function($$v) {
                            _vm.channelWebsiteUrl =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "channelWebsiteUrl"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER"
                          )
                        },
                        model: {
                          value: _vm.channelWelcomeTitle,
                          callback: function($$v) {
                            _vm.channelWelcomeTitle =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "channelWelcomeTitle"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER"
                          )
                        },
                        model: {
                          value: _vm.channelWelcomeTagline,
                          callback: function($$v) {
                            _vm.channelWelcomeTagline =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "channelWelcomeTagline"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL"
                                )
                              ) +
                              "\n        "
                          ),
                          _c("woot-color-picker", {
                            model: {
                              value: _vm.inbox.widget_color,
                              callback: function($$v) {
                                _vm.$set(_vm.inbox, "widget_color", $$v)
                              },
                              expression: "inbox.widget_color"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "medium-9 columns settings-item" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL"
                            )
                          ) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.greetingEnabled,
                              expression: "greetingEnabled"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.greetingEnabled = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED"
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "help-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.greetingEnabled
                    ? _c(
                        "div",
                        { staticClass: "settings-item" },
                        [
                          _c("greetings-editor", {
                            attrs: {
                              label: _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL"
                              ),
                              placeholder: _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER"
                              ),
                              richtext: !_vm.textAreaChannels
                            },
                            model: {
                              value: _vm.greetingMessage,
                              callback: function($$v) {
                                _vm.greetingMessage =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "greetingMessage"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.replyTime,
                                  expression: "replyTime"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.replyTime = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  key: "in_a_few_minutes",
                                  attrs: { value: "in_a_few_minutes" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  key: "in_a_few_hours",
                                  attrs: { value: "in_a_few_hours" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  key: "in_a_day",
                                  attrs: { value: "in_a_day" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.emailCollectEnabled,
                                  expression: "emailCollectEnabled"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.emailCollectEnabled = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "medium-9 columns settings-item" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT")
                          ) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.autoAssignment,
                              expression: "autoAssignment"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.autoAssignment = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED"
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "help-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT"
                            )
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "medium-9 columns settings-item" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT")
                          ) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.csatSurveyEnabled,
                              expression: "csatSurveyEnabled"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.csatSurveyEnabled = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED")
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "help-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT_SUB_TEXT"
                            )
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.allowMessagesAfterResolved,
                                  expression: "allowMessagesAfterResolved"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.allowMessagesAfterResolved = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.ENABLED"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.DISABLED"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.continuityViaEmail,
                                  expression: "continuityViaEmail"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.continuityViaEmail = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.ENABLED"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.DISABLED"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("label", [
                        _vm._v(_vm._s(_vm.$t("INBOX_MGMT.FEATURES.LABEL")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "div",
                        { staticClass: "widget--feature-flag settings-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFeatureFlags,
                                expression: "selectedFeatureFlags"
                              }
                            ],
                            attrs: { type: "checkbox", value: "attachments" },
                            domProps: {
                              checked: Array.isArray(_vm.selectedFeatureFlags)
                                ? _vm._i(
                                    _vm.selectedFeatureFlags,
                                    "attachments"
                                  ) > -1
                                : _vm.selectedFeatureFlags
                            },
                            on: {
                              input: _vm.handleFeatureFlag,
                              change: function($event) {
                                var $$a = _vm.selectedFeatureFlags,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "attachments",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedFeatureFlags = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedFeatureFlags = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedFeatureFlags = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "attachments" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "div",
                        { staticClass: "settings-item settings-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFeatureFlags,
                                expression: "selectedFeatureFlags"
                              }
                            ],
                            attrs: { type: "checkbox", value: "emoji_picker" },
                            domProps: {
                              checked: Array.isArray(_vm.selectedFeatureFlags)
                                ? _vm._i(
                                    _vm.selectedFeatureFlags,
                                    "emoji_picker"
                                  ) > -1
                                : _vm.selectedFeatureFlags
                            },
                            on: {
                              input: _vm.handleFeatureFlag,
                              change: function($event) {
                                var $$a = _vm.selectedFeatureFlags,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "emoji_picker",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedFeatureFlags = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedFeatureFlags = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedFeatureFlags = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "emoji_picker" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAPIInbox
                    ? _c("woot-submit-button", {
                        attrs: {
                          type: "submit",
                          disabled: _vm.$v.webhookUrl.$invalid,
                          "button-text": _vm.$t(
                            "INBOX_MGMT.SETTINGS_POPUP.UPDATE"
                          ),
                          loading: _vm.uiFlags.isUpdatingInbox
                        },
                        on: { click: _vm.updateInbox }
                      })
                    : _c("woot-submit-button", {
                        attrs: {
                          type: "submit",
                          "button-text": _vm.$t(
                            "INBOX_MGMT.SETTINGS_POPUP.UPDATE"
                          ),
                          loading: _vm.uiFlags.isUpdatingInbox
                        },
                        on: { click: _vm.updateInbox }
                      }),
                  _vm._v(" "),
                  _vm.isVonageInbox
                    ? _c(
                        "div",
                        { staticStyle: { display: "inline-block" } },
                        [
                          _vm.register_whatsapp_api_url
                            ? _c("woot-submit-button", {
                                attrs: {
                                  type: "button",
                                  "button-text": _vm.$t(
                                    "INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.BTNS.REGISTER"
                                  ),
                                  loading: false
                                },
                                on: { click: _vm.whatsAppRegPopupHandler }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isAllowedToActiveWaba
                            ? _c("woot-submit-button", {
                                attrs: {
                                  type: "button",
                                  "button-text": _vm.$t(
                                    "INBOX_MGMT.ADD.WHATSAPP.REGISTER_ACTIVE.BTNS.ACTIVE"
                                  ),
                                  loading: _vm.isActiveWabaLoading
                                },
                                on: { click: _vm.activeWabaFinalizeHandler }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAFacebookInbox
                ? _c("facebook-reauthorize", {
                    attrs: { "inbox-id": _vm.inbox.id }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "collaborators"
        ? _c(
            "div",
            { staticClass: "settings--content" },
            [
              _c(
                "settings-section",
                {
                  attrs: {
                    title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS"),
                    "sub-title": _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT"
                    )
                  }
                },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.agentList,
                      "track-by": "id",
                      label: "name",
                      multiple: true,
                      "close-on-select": false,
                      "clear-on-select": false,
                      "hide-selected": true,
                      placeholder: _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    on: { select: _vm.$v.selectedAgents.$touch },
                    model: {
                      value: _vm.selectedAgents,
                      callback: function($$v) {
                        _vm.selectedAgents = $$v
                      },
                      expression: "selectedAgents"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-submit-button", {
                    attrs: {
                      "button-text": _vm.$t("INBOX_MGMT.SETTINGS_POPUP.UPDATE"),
                      loading: _vm.isAgentListUpdating
                    },
                    on: { click: _vm.updateAgents }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "catalogues"
        ? _c("div", { staticClass: "settings--content" }, [
            _c("div", { staticClass: "row catalouges-section" }, [
              _c("div", { staticClass: "columns medium-2" }, [
                _c("h6", { staticClass: "catalouges-header" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORIES")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "columns medium-10" },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.categories,
                      "track-by": "id",
                      label: "title",
                      multiple: true,
                      "close-on-select": false,
                      "clear-on-select": false,
                      "hide-selected": true,
                      "tag-placeholder": _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES"
                      ),
                      taggable: true,
                      placeholder: _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    on: {
                      tag: _vm.createCatalogueItems,
                      remove: _vm.deleteCatalogueSet
                    },
                    model: {
                      value: _vm.selectedCategories,
                      callback: function($$v) {
                        _vm.selectedCategories = $$v
                      },
                      expression: "selectedCategories"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "columns medium-2" }, [
                _c("h6", { staticClass: "catalouges-header" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("INBOX_MGMT.SETTINGS_POPUP.CATALOUGUES_SUB_TEXT")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "columns medium-10" }, [
                _c(
                  "div",
                  { staticClass: "row item-header" },
                  [
                    _c(
                      "woot-button",
                      { on: { click: _vm.refreshCatalogueItems } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.REFRESH"
                            )
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        on: { click: _vm.updateCatalogueItems }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.UPDATE"
                            )
                          )
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.catalogueItems, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "item-wrap column medium-4"
                      },
                      [
                        _c("div", { staticClass: "image-wrap" }, [
                          _c("img", {
                            attrs: { src: item.image_link, alt: item.title }
                          })
                        ]),
                        _vm._v(" "),
                        _c("h6", [_vm._v(_vm._s(item.title))]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(item.description))]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.PRICE"
                              )
                            ) +
                              ": " +
                              _vm._s(item.price) +
                              ","
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("CONVERSATION.QUANTITY")) +
                              ": " +
                              _vm._s(item.quantity_to_sell_on_fb)
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "margin-top": "10px" }
                          },
                          [
                            _c("label", { staticClass: "column small-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("multiselect", {
                              staticClass: "column small-10",
                              attrs: {
                                options: _vm.categories,
                                "track-by": "id",
                                label: "title",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "hide-selected": true,
                                placeholder: "Pick categories",
                                "selected-label": "",
                                "select-label": _vm.$t(
                                  "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                ),
                                "deselect-label": _vm.$t(
                                  "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                )
                              },
                              model: {
                                value: item.categories,
                                callback: function($$v) {
                                  _vm.$set(item, "categories", $$v)
                                },
                                expression: "item.categories"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "configuration"
        ? _c("div", [
            _vm.isATwilioChannel
              ? _c(
                  "div",
                  { staticClass: "settings--content" },
                  [
                    _c(
                      "settings-section",
                      {
                        attrs: {
                          title: _vm.$t(
                            "INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE"
                          ),
                          "sub-title": _vm.$t(
                            "INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE"
                          )
                        }
                      },
                      [
                        _c("woot-code", {
                          attrs: {
                            script: _vm.inbox.callback_webhook_url,
                            lang: "html"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm.isALineChannel
              ? _c(
                  "div",
                  { staticClass: "settings--content" },
                  [
                    _c(
                      "settings-section",
                      {
                        attrs: {
                          title: _vm.$t(
                            "INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE"
                          ),
                          "sub-title": _vm.$t(
                            "INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE"
                          )
                        }
                      },
                      [
                        _c("woot-code", {
                          attrs: {
                            script: _vm.inbox.callback_webhook_url,
                            lang: "html"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm.isAWebWidgetInbox
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "settings--content" },
                    [
                      _c(
                        "settings-section",
                        {
                          attrs: {
                            title: _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING"
                            ),
                            "sub-title": _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD"
                            )
                          }
                        },
                        [
                          _c("woot-code", {
                            attrs: { script: _vm.inbox.web_widget_script }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "settings-section",
                        {
                          attrs: {
                            title: _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION"
                            ),
                            "sub-title": _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION"
                            )
                          }
                        },
                        [
                          _c("woot-code", {
                            attrs: { script: _vm.inbox.hmac_token }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "settings-section",
                        {
                          attrs: {
                            title: _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION"
                            ),
                            "sub-title": _vm.$t(
                              "INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION"
                            )
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "enter-to-send--checkbox" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.hmacMandatory,
                                    expression: "hmacMandatory"
                                  }
                                ],
                                attrs: {
                                  id: "hmacMandatory",
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.hmacMandatory)
                                    ? _vm._i(_vm.hmacMandatory, null) > -1
                                    : _vm.hmacMandatory
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.hmacMandatory,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.hmacMandatory = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.hmacMandatory = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.hmacMandatory = $$c
                                      }
                                    },
                                    _vm.handleHmacFlag
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "hmacMandatory" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL")
                                  )
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm.isAPIInbox
              ? _c(
                  "div",
                  { staticClass: "settings--content" },
                  [
                    _c(
                      "settings-section",
                      {
                        attrs: {
                          title: _vm.$t(
                            "INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER"
                          ),
                          "sub-title": _vm.$t(
                            "INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT"
                          )
                        }
                      },
                      [
                        _c("woot-code", {
                          attrs: { script: _vm.inbox.inbox_identifier }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm.isAnEmailChannel
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "settings--content" },
                      [
                        _c(
                          "settings-section",
                          {
                            attrs: {
                              title: _vm.$t(
                                "INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE"
                              ),
                              "sub-title": _vm.$t(
                                "INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT"
                              )
                            }
                          },
                          [
                            _c("woot-code", {
                              attrs: { script: _vm.inbox.forward_to_email }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("imap-settings", { attrs: { inbox: _vm.inbox } }),
                    _vm._v(" "),
                    _c("smtp-settings", { attrs: { inbox: _vm.inbox } })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "preChatForm"
        ? _c(
            "div",
            [_c("pre-chat-form-settings", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "businesshours"
        ? _c(
            "div",
            [_c("weekly-availability", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "connect_phone"
        ? _c(
            "div",
            { staticClass: "settings--content" },
            [
              _c(
                "settings-section",
                {
                  attrs: {
                    title: "Connect Phone",
                    "sub-title": "Connect your phone number with WABA inbox"
                  }
                },
                [
                  _c("verify-waba-code", {
                    attrs: {
                      isRedirectToAddAgent: false,
                      isHardReloadOnSuccess: true
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }