var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "orders-table-wrap" },
    [
      _c("ve-table", {
        attrs: {
          "fixed-header": true,
          "max-height": "calc(100vh - 11.4rem)",
          "scroll-width": "187rem",
          columns: _vm.columns,
          "table-data": _vm.tableData,
          "border-around": false,
          "sort-option": _vm.sortOption
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }