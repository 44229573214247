var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "row",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createChannel()
        }
      }
    },
    [
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.inboxName.$error } }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL")) +
              "\n        "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.inboxName,
                expression: "inboxName",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.inboxName },
            on: {
              blur: [
                _vm.$v.inboxName.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inboxName = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.inboxName.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR")) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("div", [
          _c("label", [_vm._v(_vm._s(_vm.$t("REGISTER.COUNTRY_CODE.LABEL")))]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selected_country_code,
                  expression: "selected_country_code"
                }
              ],
              on: {
                input: _vm.onCountryCodeChange,
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selected_country_code = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.phone_code_list, function(code) {
              return _c(
                "option",
                { key: code.name, domProps: { value: code.code } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(code.emoji) +
                      " " +
                      _vm._s(code.dial_code) +
                      "\n          "
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { class: { error: _vm.$v.phoneNumber.$error } }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL")) +
                "\n          "
            ),
            _c("input", {
              attrs: {
                type: "text",
                placeholder: _vm.$t(
                  "INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER"
                )
              },
              on: {
                input: _vm.onPhoneNumberChange,
                blur: _vm.$v.phoneNumber.$touch
              }
            }),
            _vm._v(" "),
            _vm.$v.phoneNumber.$error
              ? _c("span", { staticClass: "message" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR")
                      ) +
                      "\n          "
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns" },
        [
          _c("woot-submit-button", {
            attrs: {
              disabled: _vm.isCreateLoading,
              loading: _vm.isCreateLoading,
              "button-text": "Create Inbox",
              type: "button"
            },
            on: { click: _vm.launchWhatsAppSignup }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }