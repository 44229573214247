var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        modalMainContainerClass: "instruction_popup",
        show: _vm.isOpen,
        "on-close": _vm.closeModal
      },
      on: {
        "update:show": function($event) {
          _vm.isOpen = $event
        }
      }
    },
    [_c("div", [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }