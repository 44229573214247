var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showShowCurrentAccountContext
    ? _c("div", { staticClass: "account-context--group" }, [
        _vm._v(
          "\n  " + _vm._s(_vm.$t("SIDEBAR.CURRENTLY_VIEWING_ACCOUNT")) + "\n  "
        ),
        _c("p", { staticClass: "account-context--name text-ellipsis" }, [
          _vm._v("\n    " + _vm._s(_vm.account.name) + "\n  ")
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }