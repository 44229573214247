var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.$t("FLOW.DELETE_PROMPT_MESSAGE") }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "medium-12 columns" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    "color-scheme": "alert",
                    disabled: _vm.isDeleting,
                    "is-loading": _vm.isDeleting
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.allDone($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("FLOW.DELETE")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear", "color-scheme": "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("FLOW.CANCEL")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }