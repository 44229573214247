var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-thumbnail-box",
      style: { height: _vm.size, width: _vm.size }
    },
    [
      !_vm.imgError && Boolean(_vm.src)
        ? _c("img", {
            class: _vm.thumbnailClass,
            attrs: { id: "image", src: _vm.src },
            on: {
              error: function($event) {
                return _vm.onImgError()
              }
            }
          })
        : _c("Avatar", {
            class: _vm.thumbnailClass,
            attrs: { username: _vm.username, size: _vm.avatarSize }
          }),
      _vm._v(" "),
      _vm.badge === "instagram_direct_message"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/instagram-dm.png"
            }
          })
        : _vm.badge === "facebook"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/messenger.png"
            }
          })
        : _vm.badge === "twitter-tweet"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/twitter-tweet.png"
            }
          })
        : _vm.badge === "twitter-dm"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/twitter-dm.png"
            }
          })
        : _vm.badge === "whatsapp"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/whatsapp.png"
            }
          })
        : _vm.badge === "sms"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: { id: "badge", src: "/integrations/channels/badges/sms.png" }
          })
        : _vm.badge === "Channel::Line"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/line.png"
            }
          })
        : _vm.badge === "Channel::Telegram"
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              id: "badge",
              src: "/integrations/channels/badges/telegram.png"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showStatusIndicator
        ? _c("div", {
            class:
              "source-badge user-online-status user-online-status--" +
              _vm.status,
            style: _vm.statusStyle
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }