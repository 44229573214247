var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-body columns content-box small-9" }, [
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.connectPhonePin()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("page-header", {
              attrs: {
                "header-title": _vm.$t(
                  "INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.TITLE"
                ),
                "header-content": _vm.pageHeader
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "medium-7 columns" }, [
          _c("div", { staticClass: "medium-12 columns" }, [
            _c("label", { class: { error: _vm.$v.pin.$error } }, [
              _vm._v(
                "\n              " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.PIN_LABEL"
                    )
                  ) +
                  "\n              "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.pin,
                    expression: "pin",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  disabled: _vm.isPINInputDisabled,
                  type: "text",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.PIN_INPUT"
                  )
                },
                domProps: { value: _vm.pin },
                on: {
                  blur: [
                    _vm.$v.pin.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.pin = $event.target.value.trim()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.pin.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(
                          _vm.$t(
                            "INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.PIN_ERR"
                          )
                        ) +
                        "\n              "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-submit-button", {
                attrs: {
                  "button-text": _vm.$t(
                    "INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.BTN"
                  ),
                  loading: _vm.isCreating,
                  disabled: _vm.isPINSubmitBtnDisabled
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }