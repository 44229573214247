var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      ref: "diagramRoot",
      staticClass: "diagram-editor__svg-content",
      attrs: {
        id: "diagram-root-" + _vm.uniqId,
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 " + _vm.width / 1.2 + " " + _vm.height / 1.2,
        width: _vm.width,
        height: _vm.height,
        preserveAspectRatio: "xMinYMin meet"
      },
      on: { mousedown: _vm.clearSelection }
    },
    [
      _c(
        "g",
        {
          ref: "viewPort",
          staticClass: "svg-pan-zoom_viewport",
          attrs: { x: "50", y: "50" }
        },
        [
          _c("defs", [
            _c(
              "pattern",
              {
                attrs: {
                  id: "diagram-small-grid-" + _vm.uniqId,
                  width: "16",
                  height: "16",
                  patternUnits: "userSpaceOnUse"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d: "M 16 0 L 0 0 0 16",
                    fill: "none",
                    stroke: "#ccc",
                    "stroke-width": "0.5"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "pattern",
              {
                attrs: {
                  id: "diagram-grid-" + _vm.uniqId,
                  width: "80",
                  height: "80",
                  patternUnits: "userSpaceOnUse"
                }
              },
              [
                _c("rect", {
                  attrs: {
                    width: "80",
                    height: "80",
                    fill: "url(#diagram-small-grid-" + _vm.uniqId + ")"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M 80 0 L 0 0 0 80",
                    fill: "none",
                    stroke: "#ccc",
                    "stroke-width": "1"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.links, function(link) {
            return _c("DiagramLink", {
              key: link.id,
              attrs: {
                id: link.id,
                link: link,
                "node-start": _vm.nodes[link.start_id],
                "node-end": _vm.nodes[link.end_id],
                selected: _vm.selectedLink === link.id
              },
              on: {
                mousedown: function($event) {
                  return _vm.selectLink(link.id)
                },
                deleteLink: function($event) {
                  return _vm.deleteLink(link.id)
                },
                mouseenter: function($event) {
                  return _vm.hoverLink(link.id)
                },
                "drag-start": _vm.onMiscDragStart,
                "add-break-point": _vm.onAddBreakpoint
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.nodes, function(node) {
            return _c("DiagramNode", {
              key: node.id,
              attrs: {
                node: node,
                color: _vm.nodeColor,
                "pulse-color": _vm.nodePulseColor,
                pulsable: _vm.nodePulsable,
                deletable: _vm.nodeDeletable,
                dragging: _vm.draggedNode === node.id,
                selected: _vm.selectedNode === node.id,
                "port-disabled": _vm.portDisabled,
                "port-available": _vm.portAvailable,
                "active-port": _vm.activePort,
                "hovered-port": _vm.hoveredPort,
                botid: _vm.botid
              },
              on: {
                delete: _vm.deleteNode,
                "drag-start": _vm.onNodeDragStart,
                "resize-start": _vm.onNodeResizeStart,
                "hovered-port": _vm.setHoveredPort,
                "active-port": _vm.setActivePort,
                "clicked-node": function($event) {
                  return _vm.$emit("clicked-node", $event)
                },
                "add-btn-clicked": function($event) {
                  return _vm.$emit("add-btn-clicked", $event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scopedParams) {
                      return [
                        _vm._t("default", null, { node: node }, scopedParams)
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._v(" "),
          _vm.newLink
            ? _c("line", {
                staticStyle: { stroke: "#5D5F6E", "stroke-width": "2" },
                attrs: {
                  x1: _vm.newLink.x1,
                  y1: _vm.newLink.y1,
                  x2: _vm.newLink.x2,
                  y2: _vm.newLink.y2,
                  "pointer-events": "none"
                }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }