var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header header_container" },
    [
      _c("woot-sidemenu-icon"),
      _vm._v(" "),
      _c("div", { staticClass: "table-actions-wrap" }, [
        _c("div", { staticClass: "left-aligned-wrap" }, [
          _c("h1", { staticClass: "page-title" }, [
            _vm._v("\n        " + _vm._s(_vm.headerTitle) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-aligned-wrap" },
          [
            _c(
              "div",
              { staticClass: "search-wrap" },
              [
                _c("fluent-icon", {
                  staticClass: "search-icon",
                  attrs: { icon: "search" }
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "contact-search",
                  attrs: {
                    type: "text",
                    placeholder: "Search for credit balance"
                  },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onSearchSubmit($event)
                    },
                    input: _vm.onInputSearch
                  }
                }),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    staticClass: "clear",
                    attrs: {
                      "is-loading": false,
                      "class-names": _vm.searchButtonClass
                    },
                    on: { click: _vm.onSearchSubmit }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CONTACTS_PAGE.SEARCH_BUTTON")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filters__button-wrap" },
              [
                _c(
                  "woot-button",
                  {
                    staticClass: "margin-right-small clear",
                    attrs: {
                      "color-scheme": "secondary",
                      "data-testid": "create-new-contact",
                      icon: "filter"
                    },
                    on: { click: _vm.onToggleColumnSelector }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("CONTACTS_PAGE.SELECT_COLUMNS")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                staticClass: "margin-right-small clear",
                attrs: {
                  "color-scheme": "success",
                  icon: "person-add",
                  "data-testid": "create-new-contact"
                },
                on: { click: _vm.onToggleCreate }
              },
              [_vm._v("\n        New Credit\n      ")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }