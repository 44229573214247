var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-item" }, [
    _c("div", { staticClass: "search-message" }, [
      _c("div", { staticClass: "user-wrap" }, [
        _c("div", { staticClass: "name-wrap" }, [
          _c("span", { staticClass: "text-block-title" }, [
            _vm._v(_vm._s(_vm.userName))
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.isOutgoingMessage
                ? _c("fluent-icon", {
                    staticClass: "icon-outgoing",
                    attrs: { icon: "arrow-reply" }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "timestamp" }, [
          _vm._v(_vm._s(_vm.readableTime) + " ")
        ])
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "message-content",
        domProps: { innerHTML: _vm._s(_vm.prepareContent(_vm.content)) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }