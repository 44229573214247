var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-button",
    {
      staticClass: "label--add",
      attrs: { variant: "link" },
      on: { click: _vm.addLabel }
    },
    [
      _c("woot-label", {
        attrs: {
          "color-scheme": "secondary",
          title: _vm.$t("CONTACT_PANEL.LABELS.CONVERSATION.ADD_BUTTON"),
          icon: "add-circle"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }